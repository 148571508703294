.formWrapper {
  margin-top: 50px;
}

.formHeader {
  text-align: center;
  font-size: 40px;
  font-family: 'Outfit', sans-serif;
  margin-bottom: 40px;
}

.formTopWrapper {
  width: 45%;
  margin: auto;
}

.formBody {
  width: 100%;
  padding: 60px 115px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.mainColumn > div,
.mainColumn > input,
.mainColumn > span {
  flex: 1 0 45%;
  width: 45%;
  margin: 1% 1% 0 1%;
  max-width: 48%;
}

.mainColumn {
  width: 100%;
  flex-direction: row;
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;
  flex: 0 1 100%;
}

.centerColumn {
  width: 45%;
  margin: auto;
  border: 2px solid grey;
}

.centerColumn > a {
  text-decoration: underline;
}

.rightColumn {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  flex: 0 1 48%;
}

.sectionHeader {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-align: center;
  font-family: 'Outfit', sans-serif;
  padding-bottom: 10px;
  font-size: 15px;
}
.dateHeader {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  text-align: left;
  font-family: 'Outfit', sans-serif;
  padding-bottom: 10px;
  font-size: 15px;
  color: #333;
}

.communitySelectWrapper {
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}
.residentRadioButtonsWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.radioButton {
  flex: 0 1 auto;
  font-family: 'Outfit', sans-serif;
  font-size: 17px;
  font-weight: 100;
  line-height: 40px;
  padding: 0 10px;
  cursor: pointer;
  transition: all 125ms;
  text-align: initial;
}

.radioButton:focus {
  outline: 1px solid #333;
}

.radioButton:hover {
  opacity: 0.8;
}

.radioText {
  font-weight: 400;
  word-wrap: normal;
}

.radioText:focus {
  outline: 1px solid #333;
}

/* The following is needed for overriding the default styles for the cookie settings button */
.otsdkshowsettings {
  font-weight: 400;
  word-wrap: normal;
  background-color: transparent !important;
  border: none !important;
  font-size: 17px !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #333 !important;
}

.radioButton input {
  margin-right: 15px;
  -webkit-appearance: none;
  border: 2px solid grey;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: translateY(5px);
  cursor: pointer;
}

.radioButton input:checked {
  background-image: radial-gradient(#ecd925 40%, transparent 20%);
  background-color: white;
}

.textInputHalf {
  border: none;
  border-bottom: 1px solid #333;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: 'Outfit', sans-serif;
  font-size: 12px;
  font-weight: 900;
  padding-bottom: 20px;
  height: 60px;
  border-radius: 0;
  opacity: 0.6;
  transition: opacity 125ms;
}

.textInputHalf:focus {
  opacity: 1;
}

.datepickerWrapper {
  width: 30%;
  height: 50px;
  border: 1px solid #333;
  display: flex;
  justify-content: center;
  align-items: center;
}
.datepicker:hover {
  cursor: pointer;
}
.datepickerWrapper,
.datepicker,
.datepicker > div,
.datepicker > div > div {
  cursor: pointer !important;
}
.datepicker {
  z-index: 1 !important;
}
.datepicker > div:first-child {
  border: none;
}

.nonResidentWrapper {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.nonResidentHeader {
  flex: 0 1 auto;
  text-align: left;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 50px 0 0;
}

.nonResidentWrapper > div {
  flex: 1 0 auto !important;
}

.doubleDate {
  display: flex;
  align-items: flex-end;
}
.doubleDate > .pickerContainer > .datepickerWrapper {
  width: 90%;
}
.pickerContainer {
  width: 50%;
}
.hearAboutOrder {
  order: 3;
}
.aptSizeOrder {
  order: 1;
}
.moveInDateOrder {
  order: 2;
}
.messageContainer {
  width: 98%;
  margin: auto;
  margin-top: 60px;
}

.message {
  width: 100%;
  margin: auto;
  border: none;
  border-bottom: 1px solid #333;
  text-transform: uppercase;
  font-family: 'Outfit', sans-serif;
  font-size: 12px;
  font-weight: 900;
  padding-bottom: 0px;
  border-radius: 0;
  height: auto;
  line-height: 20px;
  opacity: 0.6;
  transition: opacity 125ms;
}

.message:focus {
  opacity: 1;
}

.submit {
  background-color: #ecd925;
  position: relative;
  margin-top: 60px;
  padding: 15px 42px;
  font-size: 16px;
  font-family: 'Outfit', sans-serif;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  margin-right: 1%;
  margin-left: auto;
  transition: all 125ms;
}

.submit:hover, .submit:focus {
  background-color: #333;
  color: white;
}

.submittingButton {
  background-color: transparent !important;
}

.submit:hover {
  opacity: 0.8;
}

@media (max-width: 855px) {
  .formBody {
    flex-direction: column;
    padding: 60px 40px;
  }
  .formTopWrapper {
    width: 60%;
  }
  .textInputHalf {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 479px) {
  .datepicker > div > div > input {
    color: black !important;
    font-size: 16px;
  }
  .nonResidentWrapper {
    flex-direction: column;
    width: 100%;
  }
  .nonResidentWrapper > h5,
  .nonResidentWrapper > div {
    padding: 0;
    width: 100%;
  }

  .residentRadioButtonsWrapper {
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    height: auto;
  }
  .formTopWrapper,
  .formBody {
    width: 100%;
    padding: 0 10px;
  }
  .submit {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 1005px) {
  .mainColumn {
    flex-direction: column;
  }
  .mainColumn > div,
  .mainColumn > input,
  .mainColumn > span {
    flex: 1 0 100%;
    width: 100%;
    margin: 10px 0 10px 0;
    max-width: 100%;
  }
  .spacer {
    display: none;
  }
  .dateHeader {
    text-align: center;
    color: #333;
    font-size: 15px;
  }
  .datepickerWrapper {
    margin: auto;
  }
  .doubleDate {
    max-width: 100% !important;
  }
  .doubleDate > .datepickerWrapper {
    width: 30%;
  }
  .aptSizeOrder,
  .hearAboutOrder,
  .moveInDateOrder {
    order: initial;
  }
}

@media only screen and (max-width: 1021px) and (-ms-high-contrast: none) {
  .mainColumn,
  .communitySelectWrapper,
  .rightColumn,
  .mainColumn > span {
    flex-basis: auto !important;
  }
}

.fullWidth {
  width: 100%;
}
