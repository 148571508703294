.dropdown__control:hover {
  border: 1px solid #4c698f !important;
  border-color: #4c698f !important;
  box-shadow: none !important;
}
.dropdown__control:has(input:focus) {
  border: 2px solid #4c698f !important;
  border-color: #4c698f !important;
  box-shadow: none !important;
}
.af-class-blog--search-bar
.dropdown__indicators {
  margin-bottom: -4px;
}
.af-class-blog-banner
.dropdown__control {
  border-radius: 20px!important;
  line-height: 0px;
  vertical-align: center;
}
.af-class-blog-banner
.dropdown__single-value {
  overflow: visible;
}
.af-view .af-class-blog--search-bar.af-class-blur{
  background-color: rgba(241, 245, 251, 0.9);
  border-bottom: 1px solid #eaeaea;
}

.af-view .af-class-blog-card--tag.af-class-yellow {
  background-color: transparent !important;
}
.af-view .af-class-div-block-289 {
  border: 0 !important;
}

@media (max-width: 991px) and (-ms-high-contrast: none) {
  .af-view .af-class-blog-posts-container {
    display: block;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .af-view .af-class-div-block-288.af-class-margin-right {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}
@media (max-width: 479px) {
  .af-view .af-class-blog--search-bar.af-class-blur {
    background-image: linear-gradient(rgb(184, 183, 183) 1%, white 4%)
  }
  .af-class-blog-card--featured
  .af-class-blog-card--publish-info  {
    flex-direction: row !important;
    margin-bottom: 10px;
    width: 100%;
  }
  .af-class-text-block-89 {
    min-width: 130px;
    margin-right: 0px !important;
    padding-right: 0px !important;
  }
  .af-class-blog-card--date {
    text-align: center;
    margin-top: 0!important;
    padding-top: 0!important;
    border-top: none!important;
    border-left: 1px solid #d2d2d2;
  }
  .af-class-author {
    padding-left: 1px;
  }
  .af-class-blog-banner  {
    height: auto !important;
  }
}
