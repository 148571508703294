.af-view {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

.af-view .af-class-wrapper {
  width: 100%;
}

.af-view .af-class-wrapper.af-class-home--about {
  margin-top: 15%;
}

.af-view .af-class-wrapper.af-class-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5%;
  padding-bottom: 5%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3b3b3b), to(#333));
  background-image: linear-gradient(180deg, #3b3b3b, #333);
}

.af-view .af-class-wrapper.af-class-content_intro_image {
  padding: 50px;
}

.af-view .af-class-wrapper.af-class-slider_points_of_interest {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.af-view .af-class-wrapper.af-class-cards_news {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  min-height: 700px;
  padding-top: 50px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('../images/cards_news_background-p-1600.jpeg');
  background-position: 0px 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-wrapper.af-class-list_related_areas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 50px 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: url('../images/list-related-areas-bg-p-500.png');
  background-position: 0px 0px;
  background-size: cover;
}

.af-view .af-class-wrapper.af-class-content_intro_billboard {
  padding-top: 40px;
  padding-right: 40px;
  padding-left: 40px;
  background-color: #fff;
}

.af-view .af-class-wrapper.af-class-content_collage_point_of_interest {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.af-view .af-class-container {
  display: block;
  width: 100%;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 0px;
}

.af-view .af-class-container.af-class-home-intro {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  margin-bottom: 10%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-container.af-class-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-container.af-class-flex.af-class-home--about {
  max-width: 1920px;
}

.af-view .af-class-container.af-class-image-background.af-class-full-width {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1920px;
  padding-top: 100px;
  padding-right: 5%;
  padding-bottom: 100px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-image: url('../images/home--careers-background.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-container.af-class-image-background.af-class-full-width.af-class-min-height {
  height: 500px;
  padding-right: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-container.af-class-footer {
  max-width: 1920px;
}

.af-view .af-class-container.af-class-footer.af-class-flex {
  display: block;
  width: 90%;
  max-width: 1200px;
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-home-intro-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 75px 7% 10% 4%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  background-color: #f9f7f5;
}

.af-view .af-class-home-intro-images {
  position: relative;
  height: 45vh;
  min-height: 470px;
  -webkit-flex-basis: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
  background-image: url('../images/home--lifestyle-property.jpg');
  background-position: 0px 0px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.af-view .af-class-paragraph-text {
  font-family: 'Outfit', sans-serif;
  font-size: 15px;
  line-height: 27px;
}

.af-view .af-class-list-blocks-b-section-label {
  position: relative;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid #ecd925;
  color: #4c698f;
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.af-view .af-class-list-blocks-b-section-label.af-class-light {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 300;
  letter-spacing: 1px;
}

.af-view .af-class-amli-tag-thin {
  font-family: 'Outfit', sans-serif;
  font-size: 62px;
  line-height: 62px;
  font-weight: 100;
}

.af-view .af-class-amli-tag-thick {
  font-family: 'Outfit', sans-serif;
  font-size: 62px;
  line-height: 32px;
}

.af-view .af-class-home-amli-tagline {
  margin-top: 0px;
  margin-bottom: 20px;
}

.af-view .af-class-button {
  color: #383838;
  text-decoration: none;
}

.af-view .af-class-button.af-class-boss.af-class-yellow {
  position: relative;
  margin-top: 30px;
  padding: 12px 30px;
  background-color: #ecd925;
  font-weight: 800;
}

.af-view .af-class-button.af-class-boss.af-class-yellow.af-class-no-margin {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
}

.af-view .af-class-button.af-class-boss.af-class-yellow.af-class-no-margin.af-class-hide-on-mobile {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-button.af-class-boss.af-class-yellow.af-class-career-callout-band {
  margin-top: 0px;
}

.af-view .af-class-boss-right-arrow {
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translate(15px, -50%);
  -ms-transform: translate(15px, -50%);
  transform: translate(15px, -50%);
}

.af-view .af-class-boos-button-label {
  position: relative;
  top: -2px;
  font-family: 'Outfit', sans-serif;
  line-height: 20px;
}

.af-view .af-class-home-intro-image.af-class-left-offset {
  position: absolute;
  bottom: 0px;
  width: 50%;
  max-width: 600px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(-20%, 75%);
  -ms-transform: translate(-20%, 75%);
  transform: translate(-20%, 75%);
}

.af-view .af-class-home-intro-image.af-class-right-offset {
  position: absolute;
  left: 0px;
  right: auto;
  bottom: 0px;
  width: 50%;
  max-width: 600px;
  box-shadow: 7px 7px 40px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(100%, 75%);
  -ms-transform: translate(100%, 75%);
  transform: translate(100%, 75%);
}

.af-view .af-class-section-title {
  margin-bottom: -5px;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-section-title.af-class-loft {
  color: #d76859;
}

.af-view .af-class-section-title.af-class-white {
  color: #fdfdfd;
  font-weight: 500;
}

.af-view .af-class-section-title.af-class-underlined {
  display: inline-block;
  margin-bottom: 0px;
  padding-bottom: 10px;
  border-bottom: 3px solid #ecd925;
}

.af-view .af-class-section-tagline {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
}

.af-view .af-class-section-tagline.af-class-white {
  width: 100%;
  padding-top: 10px;
  padding-right: 0px;
  color: #fff;
  font-size: 36px;
  line-height: 46px;
}

.af-view .af-class-section-tagline.af-class-light {
  color: #fff;
  font-size: 36px;
  line-height: 46px;
}

.af-view .af-class-section-tagline.af-class-loft {
  color: #018094;
}

.af-view .af-class-section-tagline.af-class-loft.af-class-max-width {
  width: 100%;
  max-width: 450px;
}

.af-view .af-class-section-tagline.af-class-large {
  font-size: 46px;
  line-height: 62px;
}

.af-view .af-class-section-tagline.af-class-amli-blue {
  color: #7ba4da;
}

.af-view .af-class-div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 5%;
  padding-left: 5%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
}

.af-view .af-class-two-thirds-column {
  -webkit-flex-basis: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
}

.af-view .af-class-inner-content-container {
  max-width: 500px;
}

.af-view .af-class-inner-content-container.af-class-home-careers {
  padding: 5%;
  background-color: rgba(0, 0, 0, 0.82);
}

.af-view .af-class-heading {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 26px;
  line-height: 34px;
}

.af-view .af-class-footer-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 5%;
  padding-bottom: 5%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
}

.af-view .af-class-footer-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-footer-text-link {
  margin-left: 20px;
  padding: 5px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  text-decoration: none;
}

.af-view .af-class-social-icon {
  max-height: 30px;
  margin-left: 50px;
}

.af-view .af-class-footer-text {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 300;
}

.af-view .af-class-footer-equal-housing-logo {
  max-height: 30px;
}

.af-view .af-class-div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-real-estate-column-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  padding-top: 20px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  background-color: #3f3f3f;
}

.af-view .af-class-map-container {
  position: relative;
  display: block;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
  background-image: url('../images/google-map3x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-map-container.af-class-sub-region {
  display: none;
}

.af-view .af-class-toggle-active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 57px;
  max-width: 200px;
  padding: 5px 5px 5px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #ecd925;
  border-radius: 6px;
  background-color: #fff;
  -webkit-transform: translate(-5px, -5px);
  -ms-transform: translate(-5px, -5px);
  transform: translate(-5px, -5px);
}

.af-view .af-class-property-icon {
  max-height: 35px;
}

.af-view .af-class-toggle-active-label {
  margin-top: -10px;
  margin-right: 15px;
  padding-top: 0px;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.af-view .af-class-toggle-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  max-width: 440px;
  margin-bottom: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  background-color: #595959;
}

.af-view .af-class-toggle-inactive-icon {
  max-height: 35px;
  opacity: 0.6;
}

.af-view .af-class-toggle-inactive {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 31px 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-toggle-inactive-label {
  margin-right: 15px;
  padding-top: 5px;
  font-family: 'Outfit', sans-serif;
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 20px;
  font-weight: 500;
}

.af-view .af-class-filters-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid #eaeaea;
}

.af-view .af-class-price-filter-container {
  width: 100%;
  padding: 20px;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  background-color: #fff;
}

.af-view .af-class-border {
  width: 1px;
  background-color: #eaeaea;
}

.af-view .af-class-circle--blue {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #7ba4da;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.af-view .af-class-circle--blue.af-class-left {
  -webkit-transform: translate(6px, 0px);
  -ms-transform: translate(6px, 0px);
  transform: translate(6px, 0px);
}

.af-view .af-class-circle--blue.af-class-right {
  -webkit-transform: translate(-9px, 0px);
  -ms-transform: translate(-9px, 0px);
  transform: translate(-9px, 0px);
}

.af-view .af-class-filter-drag-line {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 5px;
  z-index: 1;
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background-color: #d2d2d2;
}

.af-view .af-class-filter-content-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-filter-right-label {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: right;
}

.af-view .af-class-filter-left-label {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-filter-label {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #4c698f;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.af-view .af-class-filter-option {
  padding-bottom: 5px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 500;
}

.af-view .af-class-property-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #eaeaea;
}

.af-view .af-class-property-card.af-class-icon-wrapper {
  margin-top: 12px;
  padding-right: 5px;
  padding-bottom: 13px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #fff;
}

.af-view .af-class-div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-property-card-left-border {
  width: 12px;
  background-color: #7ba4da;
}

.af-view .af-class-property-card-slide-default {
  position: relative;
  -webkit-box-flex: 60%;
  -webkit-flex: 60%;
  -ms-flex: 60%;
  flex: 60%;
  background-image: url('../images/property-card-slide-default2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-property-card-info-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  background-color: #fff;
}

.af-view .af-class-property-card-title {
  width: 100%;
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.af-view .af-class-property-card-title-wrapper {
  padding: 20px 20px 20px 10px;
  border-bottom: 1px solid #eaeaea;
  background-color: #fff;
}

.af-view .af-class-property-card-address {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  color: #727272;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
}

.af-view .af-class-property-card-phone {
  width: 100%;
  height: 100%;
  font-family: 'Outfit', sans-serif;
  color: #666667;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-property-card-bedroom {
  margin-top: 10px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 18px;
  font-weight: 500;
}

.af-view .af-class-property-card-cta {
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
}

.af-view .af-class-property-card-cta-label {
  display: inline;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}

.af-view .af-class-property-card-cta-arrow {
  padding-left: 10px;
}

.af-view .af-class-property-card-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.af-view .af-class-property-card-info-wtrapper {
  padding-left: 10px;
}

.af-view .af-class-real-estate-column {
  overflow: scroll;
  height: 90vh;
  max-height: 100vh;
  -webkit-flex-shrink: 3;
  -ms-flex-negative: 3;
  flex-shrink: 3;
  -webkit-flex-basis: 600px;
  -ms-flex-preferred-size: 600px;
  flex-basis: 600px;
}

.af-view .af-class-image-2 {
  position: absolute;
  left: 50%;
  bottom: 10px;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.af-view .af-class-image-3 {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 25px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-image-3.af-class-slide-right {
  position: absolute;
  left: auto;
  right: 10px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-lozenge--specials {
  position: absolute;
  top: 5px;
  right: 5px;
  display: inline-block;
  padding: 2px 10px 5px;
  border-radius: 30px;
  background-color: #06845e;
  font-family: 'Outfit', sans-serif;
  color: #fff;
}

.af-view .af-class-lozenge--specials.af-class-quote-summary {
  left: 12px;
  top: 0px;
  right: auto;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-text-block {
  display: inline;
}

.af-view .af-class-icon-fire {
  margin-left: 8px;
}

.af-view .af-class-image-5 {
  display: inline;
  width: 50px;
}

.af-view .af-class-text-block-2 {
  font-family: 'Outfit', sans-serif;
  color: #727272;
  font-size: 12px;
  text-transform: none;
}

.af-view .af-class-text-block-3 {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 18px;
  font-weight: 700;
}

.af-view .af-class-lozenge--neighborhood {
  position: absolute;
  left: 50%;
  top: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  padding-right: 25px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 3px solid #fff;
  border-radius: 39px;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
}

.af-view .af-class-map--neighborhood-label-wrapper {
  display: inline-block;
  padding-top: 2px;
  padding-left: 13px;
}

.af-view .af-class-heading-2 {
  position: absolute;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  font-family: 'Outfit', sans-serif;
  font-size: 64px;
  line-height: 67px;
}

.af-view .af-class-map {
  width: 60px;
}

.af-view .af-class-map-zoom-controls-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  margin-bottom: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-content_intro_image_title {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 70px;
  line-height: 80px;
  text-align: center;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.26);
}

.af-view .af-class-slide_info_wrapper {
  position: relative;
  padding: 50px 90px 50px 10%;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(239, 233, 229, 0.5)), to(#efe9e5));
  background-image: linear-gradient(90deg, rgba(239, 233, 229, 0.5), #efe9e5);
}

.af-view .af-class-point-title {
  display: inline-block;
  padding-bottom: 15px;
  font-family: 'Outfit', sans-serif;
  font-size: 19px;
  line-height: 30px;
  font-weight: 700;
}

.af-view .af-class-poi_slide_title_border {
  width: 50px;
  height: 1px;
  background-color: #d8d8d8;
}

.af-view .af-class-point_fact {
  font-family: 'Outfit', sans-serif;
  color: #727272;
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  text-decoration: none;
}

.af-view .af-class-poi_slide_distance_property {
  padding-left: 5px;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 700;
}

.af-view .af-class-poi_slide_next_arrow {
  position: absolute;
  right: 0px;
  bottom: 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.af-view .af-class-poi_slide_prev_arrow {
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.af-view .af-class-poi_slider_pagination_wrapper {
  position: absolute;
  right: 0px;
  bottom: -25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-slider_pagination_dot {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  border-radius: 10px;
  background-color: #d2d2d2;
}

.af-view .af-class-slider_pagination_dot.af-class-active {
  width: 12px;
  height: 12px;
  background-color: #3f3f3f;
}

.af-view .af-class-point_blurb {
  padding-top: 15px;
  font-family: 'Outfit', sans-serif;
  font-size: 15px;
  line-height: 27px;
}

.af-view .af-class-slider-poi-content-wrapper {
  padding-right: 30px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
}

.af-view .af-class-slider_poi_image_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.af-view .af-class-slider_poi_image {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('../images/slider_poi_next_image.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.af-view .af-class-slider_poi_image.af-class-current {
  min-height: 600px;
  margin-right: 30px;
  padding-right: 0px;
  -webkit-box-flex: 50%;
  -webkit-flex: 50%;
  -ms-flex: 50%;
  flex: 50%;
  background-image: url('../images/slider_poi_current_image.jpg');
}

.af-view .af-class-slider_poi_headers {
  padding-bottom: 30px;
  padding-left: 5%;
}

.af-view .af-class-content-image-offset-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1920px;
  padding: 50px 100px 15% 5%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: #3f3f3f;
}

.af-view .af-class-content-image-offset-paragraph {
  width: 100%;
  max-width: 460px;
  margin-bottom: 0px;
  padding-top: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 15px;
  line-height: 27px;
}

.af-view .af-class-cards-poi-detail-headers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -10px;
  padding-right: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.af-view .af-class-content_image_offset_cards {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 5%;
  padding-left: 5%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translate(0px, -20%);
  -ms-transform: translate(0px, -20%);
  transform: translate(0px, -20%);
}

.af-view .af-class-poi_card {
  width: 30%;
}

.af-view .af-class-poi_card_image {
  position: relative;
  min-height: 300px;
  background-image: url('../images/lunch-sammich.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.af-view .af-class-poi_card_image.af-class-restaurant {
  min-height: 270px;
}

.af-view .af-class-poi_card_image.af-class-brunch {
  min-height: 270px;
  background-image: url('../images/brunch-toast.jpg');
}

.af-view .af-class-poi_card_image.af-class-drinks {
  min-height: 270px;
  background-image: url('../images/tasty-drink.jpg');
}

.af-view .af-class-heading-3 {
  font-family: 'Outfit', sans-serif;
  font-size: 19px;
  line-height: 30px;
}

.af-view .af-class-poi_card_tag {
  position: absolute;
  left: 15px;
  top: 15px;
  display: inline-block;
  padding: 10px 18px;
  background-color: rgba(0, 0, 0, 0.75);
}

.af-view .af-class-poi_card_label {
  display: inline;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-poi_card_description {
  margin-top: 12px;
  font-family: 'Outfit', sans-serif;
  font-size: 15px;
  line-height: 27px;
}

.af-view .af-class-div-block-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1100px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-poi_card_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1440px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-cards_news_author_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-author_image {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #7ba4da;
}

.af-view .af-class-news-card-image {
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  background-image: url('../images/example-bg.png');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-news_card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 95%;
  max-width: 800px;
  margin-bottom: 50px;
  border-radius: 6px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
}

.af-view .af-class-heading-4 {
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 26px;
}

.af-view .af-class-paragraph {
  width: 100%;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
}

.af-view .af-class-paragraph.af-class-light {
  position: relative;
  z-index: 2;
  max-width: 400px;
  margin-top: 20px;
  color: #fff;
}

.af-view .af-class-paragraph.af-class-light.af-class-margin-top {
  width: 100%;
}

.af-view .af-class-paragraph.af-class-light.af-class-no-max-width {
  max-width: 90%;
}

.af-view .af-class-paragraph.af-class-dark {
  color: #3f3f3f;
}

.af-view .af-class-paragraph.af-class-cards-testimonial {
  margin-bottom: 0px;
  padding: 20px 25px 50px;
  background-color: #fff;
}

.af-view .af-class-paragraph.af-class-centered {
  text-align: center;
}

.af-view .af-class-paragraph.af-class-centered.af-class-large {
  font-size: 17px;
  line-height: 32px;
}

.af-view .af-class-paragraph.af-class-centered.af-class-light {
  width: 100%;
  max-width: 100%;
}

.af-view .af-class-paragraph.af-class-faq-answer {
  margin-bottom: 20px;
}

.af-view .af-class-paragraph.af-class-small {
  font-size: 14px;
  line-height: 25px;
}

.af-view .af-class-paragraph.af-class-small.af-class-light.af-class-margin-top {
  margin-top: 30px;
}

.af-view .af-class-paragraph.af-class-small.af-class-fine-print {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-paragraph.af-class-margin-top {
  margin-top: 30px;
}

.af-view .af-class-paragraph.af-class-sustainable-feature {
  font-size: 14px;
  line-height: 24px;
}

.af-view .af-class-paragraph.af-class-author-bio {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.af-view .af-class-paragraph.af-class-max-width {
  max-width: 750px;
}

.af-view .af-class-paragraph.af-class-white {
  width: 100%;
  color: #fff;
}

.af-view .af-class-div-block-14 {
  padding-left: 15px;
}

.af-view .af-class-news-card-content {
  padding: 20px 25px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
  background-color: #fff;
}

.af-view .af-class-cards-news-header {
  padding: 17px 20px;
  -webkit-flex-basis: 30%;
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
  background-color: rgba(0, 0, 0, 0.75);
}

.af-view .af-class-div-block-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 70%;
  -ms-flex-preferred-size: 70%;
  flex-basis: 70%;
}

.af-view .af-class-related-areas-description {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
}

.af-view .af-class-related-areas-locations-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 80%;
  -ms-flex: 0 80%;
  flex: 0 80%;
}

.af-view .af-class-related-areas-location-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33%;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.af-view .af-class-related-areas-location-column.af-class-zipcodes {
  width: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-related-areas-location-column.af-class-hoods {
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-right: 1px solid hsla(0, 0%, 100%, 0.15);
  border-left: 1px solid hsla(0, 0%, 100%, 0.15);
}

.af-view .af-class-related-areas-location-title {
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-city-text-link {
  display: inline-block;
  margin-bottom: 25px;
  padding: 7px 20px 14px;
  border: 1px solid #979797;
  border-radius: 3px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-city-text-link:hover {
  background-color: #fff;
  color: #3f3f3f;
}

.af-view .af-class-hood-text-link {
  display: inline-block;
  margin-bottom: 15px;
  padding: 6px 20px 8px;
  border: 1px solid #979797;
  border-radius: 3px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-hood-text-link:hover {
  background-color: #fff;
  color: #3f3f3f;
}

.af-view .af-class-zipcode-text-link {
  display: inline-block;
  max-width: 100px;
  margin-bottom: 15px;
  padding: 0px 20px 3px;
  border: 1px solid #979797;
  border-radius: 3px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: 700;
  letter-spacing: 1px;
  text-decoration: none;
}

.af-view .af-class-zipcode-text-link:hover {
  background-color: #fff;
  color: #3f3f3f;
}

.af-view .af-class-div-block-16 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 3 40%;
  -ms-flex: 0 3 40%;
  flex: 0 3 40%;
}

.af-view .af-class-div-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1920px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-html-embed {
  display: none;
}

.af-view .af-class-content_intro_billboard_title {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 48px;
  line-height: 60px;
}

.af-view .af-class-content_intro_billboard_blur_box {
  position: relative;
  left: 40px;
  top: 40px;
  display: inline-block;
  width: 100%;
  max-width: 520px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.6);
}

.af-view .af-class-div-block-18 {
  padding-bottom: 15%;
  background-image: url('../images/Content_Intro_Billboard2x.jpg');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-collage-poi-image-bleed {
  position: relative;
  display: block;
  min-height: 300px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background-image: url('../images/content-collage-poi-turtle2x.jpg');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-content-collage-intro {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #3f3f3f;
}

.af-view .af-class-collage-color-band {
  height: 60px;
  background-color: #3f3f3f;
}

.af-view .af-class-content_collage_right_column {
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.af-view .af-class-content_collage_left_column {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.af-view .af-class-collage-poi-framed-image {
  position: relative;
  margin: 40px 40px 20px;
  padding-top: 150px;
  padding-bottom: 150px;
  background-image: url('../images/content-collage-poi-bear2x.jpg');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-div-block-25 {
  padding-right: 40px;
  padding-left: 40px;
}

.af-view .af-class-collage-paragraph {
  font-family: 'Outfit', sans-serif;
  font-size: 15px;
  line-height: 27px;
}

.af-view .af-class-collage-paragraph.af-class-light {
  width: 100%;
  max-width: 460px;
  color: #fff;
}

.af-view .af-class-collage-paragraph.af-class-light.af-class-padding-top {
  margin-top: 30px;
}

.af-view .af-class-content-collage-label {
  width: 100%;
  max-width: 460px;
  margin-bottom: -5px;
  font-family: 'Outfit', sans-serif;
  color: white;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-point_fact-2 {
  font-family: 'Outfit', sans-serif;
  color: #727272;
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  text-decoration: none;
}

.af-view .af-class-poi_slide_distance_property-2 {
  padding-left: 5px;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 700;
}

.af-view .af-class-cards_points_of_interest {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-spotlight_billboard_descriptive {
  position: relative;
  z-index: 1;
  padding: 50px;
}

.af-view .af-class-spotlight-bb-descriptive-background {
  position: relative;
  z-index: 1;
  padding: 50px;
  background-image: url('../images/spotlight_billboard_descriptive.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-spotlight-bb-background-offset {
  position: absolute;
  right: 0px;
  bottom: -20%;
  z-index: 0;
  width: 40%;
  height: 80%;
  background-color: #3f3f3f;
}

.af-view .af-class-spotlight-billboard-descriptive-blur {
  position: relative;
  width: 50%;
  max-width: 450px;
  padding: 50px 50px 75px;
  background-color: rgba(0, 0, 0, 0.75);
}

.af-view .af-class-spotlight-billboard-impact-statement {
  margin-bottom: 30px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 19px;
  line-height: 30px;
  font-weight: 600;
}

.af-view .af-class-spotlight-bb-description {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
}

.af-view .af-class-related-areas-band {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.af-view .af-class-related-area-band-column {
  position: relative;
  width: 33.33333%;
  height: 400px;
  max-height: 50vh;
  min-height: 30vh;
  background-image: url('../images/related-area-band-column-background-22x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  text-decoration: none;
}

.af-view .af-class-related-area-band-column.af-class-evanston {
  background-image: url('../images/related-area-band-column-background2x.jpg');
  background-size: cover;
}

.af-view .af-class-related-area-band-column.af-class-deerfield {
  background-image: url('../images/related-area-band-column-background-32x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-text-block-4 {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  text-decoration: none;
}

.af-view .af-class-related-area-band-label {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 20px 40px;
  background-color: hsla(0, 0%, 100%, 0.75);
}

.af-view .af-class-related-area-column-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.af-view .af-class-div-block-26 {
  width: 100%;
  max-width: 1920px;
  padding-bottom: 10px;
  padding-left: 50px;
}

.af-view .af-class-about-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 75vh;
  max-height: 600px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: url('../images/about-overview-header2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-about-header-blur-wrapper {
  position: relative;
  left: 5%;
  bottom: 10%;
  display: inline-block;
  padding: 0px 40px 16px;
  border-radius: 3px;
  background-color: hsla(0, 0%, 100%, 0.85);
}

.af-view .af-class-about-header-title {
  position: relative;
  z-index: 1;
  display: inline-block;
  font-size: 72px;
  line-height: 82px;
}

.af-view .af-class-list_detailed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.af-view .af-class-value-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 90%;
  margin-bottom: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-29 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 600px;
  padding: 40px 40px 125px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #f1f5fb;
}

.af-view .af-class-div-block-30 {
  position: relative;
  width: 90%;
  height: 300px;
  background-image: url('../images/about-mission2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  -webkit-transform: translate(-12%, -30%);
  -ms-transform: translate(-12%, -30%);
  transform: translate(-12%, -30%);
}

.af-view .af-class-list-detailed-image-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-list-detailed-image-content {
  max-width: 450px;
}

.af-view .af-class-image-6 {
  width: 50px;
  height: auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.af-view .af-class-value-list-title {
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
}

.af-view .af-class-div-block-33 {
  margin-left: 20px;
}

.af-view .af-class-list-detailed-lists-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  padding-left: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.af-view .af-class-heading-7 {
  margin-bottom: 30px;
  font-family: 'Outfit', sans-serif;
  font-size: 36px;
  line-height: 48px;
}

.af-view .af-class-list-detailed-list-content {
  max-width: 90%;
  padding-left: 20px;
}

.af-view .af-class-spotlight-3-points {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 74px;
  padding-bottom: 74px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.af-view .af-class-spotlight-3-first-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36%;
  padding-right: 2%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-spotlight-3-point-column {
  width: 30%;
  padding-right: 2%;
  padding-left: 2%;
}

.af-view .af-class-spotlight-3-point-full-bleed-img {
  width: 100%;
  height: 150px;
  margin-top: 30px;
  background-image: url('../images/about-amli-edge2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-_3-spotlight-description {
  width: 100%;
  margin-top: 30px;
  font-family: 'Outfit', sans-serif;
  font-size: 15px;
  line-height: 27px;
}

.af-view .af-class-_3-spotlight-bold-description {
  font-size: 24px;
  font-weight: 700;
}

.af-view .af-class-spotlight-3-first-column-container {
  width: 80%;
}

.af-view .af-class-spotlight-3-points-image {
  margin-top: 20px;
  margin-bottom: 20px;
}

.af-view .af-class-brand-value-description {
  margin-top: 5px;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.af-view .af-class-content_image_blurb_cta {
  width: 100%;
  padding: 50px;
}

.af-view .af-class-list-blocks-with-text-blurb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
}

.af-view .af-class-list-blocks-blurb-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 6%;
  padding-right: 5%;
  padding-bottom: 6%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  background-color: #f1f5fb;
}

.af-view .af-class-list-blocks-blurb-logos {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 50px;
  padding-left: 50px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-flex-basis: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
}

.af-view .af-class-list-blocks-blurb-column {
  margin-bottom: 15px;
  padding: 20px;
  -webkit-flex-basis: 33%;
  -ms-flex-preferred-size: 33%;
  flex-basis: 33%;
  text-align: center;
}

.af-view .af-class-list-blocks-b-content-container {
  -webkit-flex-basis: 300px;
  -ms-flex-preferred-size: 300px;
  flex-basis: 300px;
}

.af-view .af-class-slider_story {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-cards-news-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1700px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-real-estate-sticky-menu {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-div-block-34 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-slider-story-header {
  width: 75%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  background-color: #f1f5fb;
}

.af-view .af-class-slider-story-image-track {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  width: 100vw;
  background-color: transparent;
}

.af-view .af-class-slider-story-slide {
  overflow: visible;
  width: 40vw;
  height: 50vh;
  min-width: 40vw;
  margin-right: 2%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.af-view .af-class-slider-story-slide.af-class-first-slide {
  min-width: 35vw;
  background-image: url('../images/19802x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-slider-story-slide.af-class-_2nd-slide {
  min-width: 35vw;
  background-image: url('../images/19832x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-slider-story-slide.af-class-_3rd-slide {
  min-width: 35vw;
  background-image: url('../images/20082x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-slider-story-slide.af-class-last-slide {
  min-width: 35vw;
  background-image: url('../images/example-bg.png');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-slider-story-slide-info {
  position: relative;
  width: 75%;
  padding-top: 20px;
  padding-bottom: 75px;
  padding-left: 0px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  background-color: #f1f5fb;
}

.af-view .af-class-slider-story-slide-year {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  font-family: 'Outfit', sans-serif;
  font-size: 50px;
  line-height: 64px;
  font-weight: 700;
}

.af-view .af-class-slider-story-slide-description {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 15px;
  line-height: 27px;
}

.af-view .af-class-slider-story-slide-info-container {
  width: 85%;
  max-width: 700px;
  padding-top: 10px;
  padding-left: 50px;
}

.af-view .af-class-slider-story-header-container {
  width: 85%;
  max-width: 700px;
}

.af-view .af-class-slider-story-controls-wrapper {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-transform: translate(-100%, -100%);
  -ms-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}

.af-view .af-class-slider-story-pagination {
  position: relative;
  left: -20px;
  top: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-slider-story-arrow {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  padding: 7px;
  background-color: #f6f6f6;
}

.af-view .af-class-page-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 75vh;
  max-height: 75vh;
  min-height: 500px;
  padding-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: url('../images/example-bg.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-page-header.af-class-career-overview {
  max-height: 600px;
  background-image: url('../images/page-header2x.jpg');
}

.af-view .af-class-page-header.af-class-culture {
  height: 75vh;
  max-height: 600px;
  background-image: url('../images/culture-page-hero2x.jpg');
}

.af-view .af-class-page-header.af-class-benefits {
  position: relative;
  height: 75vh;
  max-height: 600px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/benefits-page-hero2x.jpg');
}

.af-view .af-class-page-header.af-class-pro-dev {
  height: 75vh;
  max-height: 600px;
  background-image: url('../images/pro-dev-banner2x.jpg');
}

.af-view .af-class-page-header-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.af-view .af-class-page-header-content.af-class-light {
  width: 95%;
  height: auto;
  max-width: 1600px;
  margin-top: auto;
  padding: 20px 35px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: hsla(0, 0%, 100%, 0.85);
}

.af-view .af-class-page-header-content.af-class-light.af-class-vertical {
  height: auto;
}

.af-view .af-class-page-header-content.af-class-dark {
  width: 90%;
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
}

.af-view .af-class-page-header-content.af-class-benefits {
  width: 95%;
  height: auto;
  padding: 20px 35px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: hsla(0, 0%, 100%, 0.85);
}

.af-view .af-class-page-header-content.af-class-benefits.af-class-vertical {
  height: auto;
}

.af-view .af-class-html-embed-2 {
  display: none;
}

.af-view .af-class-career-page-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Outfit', sans-serif;
  font-size: 66px;
  line-height: 60px;
}

.af-view .af-class-career-page-heading.af-class-pro-dev {
  font-size: 48px;
}

.af-view .af-class-page-header-title-highlight {
  position: relative;
  z-index: 1;
  line-height: 63px;
}

.af-view .af-class-content-intro-descriptive {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5% 6%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  color: #3f3f3f;
}

.af-view .af-class-content-intro-descriptive.af-class-centered {
  padding-top: 2%;
  padding-right: 0%;
  padding-left: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-36 {
  width: 90%;
  max-width: 900px;
}

.af-view .af-class-content-intro-descriptive-title {
  position: relative;
  margin-top: 0px;
  margin-bottom: 30px;
  font-family: 'Outfit', sans-serif;
  font-size: 36px;
  line-height: 48px;
}

.af-view .af-class-content-intro-descriptive-title.af-class-centered {
  width: 100%;
  font-size: 32px;
  text-align: center;
}

.af-view .af-class-content-intro-descriptive-highlight {
  position: relative;
  width: 200px;
  height: 5px;
}

.af-view .af-class-spotlight-text-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-spotlight-text-image.af-class-no-cta {
  margin-top: 51px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.af-view .af-class-div-block-37 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.af-view .af-class-spotlight-text-image-content-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 40px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
  background-color: #3f3f3f;
}

.af-view .af-class-spotlight-text-image-content-column.af-class-no-cta {
  padding-right: 75px;
}

.af-view .af-class-spotlight-text-image-border-heading {
  position: relative;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 36px;
  line-height: 46px;
}

.af-view .af-class-spotlight-text-image-content {
  position: relative;
  width: 80%;
  max-width: 450px;
  padding-top: 60px;
  padding-right: 5px;
  padding-bottom: 60px;
}

.af-view .af-class-list-compact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f1f5fb;
}

.af-view .af-class-div-block-40 {
  position: relative;
  -webkit-flex-basis: 450px;
  -ms-flex-preferred-size: 450px;
  flex-basis: 450px;
}

.af-view .af-class-list-compact-list-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin-bottom: 0px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-list-compact-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.af-view .af-class-div-block-41 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1920px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: transparent;
}

.af-view .af-class-div-block-42 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 45px;
  padding-bottom: 45px;
  padding-left: 50px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-div-block-43 {
  position: absolute;
  left: 0px;
  top: -15px;
  right: 0px;
  bottom: -15px;
  background-image: url('../images/list-compact2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-list--compact-description-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-list-compact-list-item-icon {
  position: relative;
  top: 4px;
  width: 25px;
  height: 25px;
  max-height: 25px;
  max-width: 25px;
  min-height: 25px;
  min-width: 25px;
  margin-right: 20px;
}

.af-view .af-class-list-compact-paragraph {
  width: 100%;
  padding-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 15px;
  line-height: 27px;
}

.af-view .af-class-spotlight-billboard-list {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/spotlight-billboard-list-background2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-spotlight-billlboard-list-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding: 30px 60px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.9);
  text-align: center;
}

.af-view .af-class-spotlight-bb-list-description {
  width: 100%;
  margin-top: 10px;
  font-family: 'Outfit', sans-serif;
  font-size: 15px;
  line-height: 27px;
}

.af-view .af-class-spotlight-bb-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-spotlight-bb-list-icon {
  width: 15px;
  height: 15px;
  margin-right: 15px;
}

.af-view .af-class-spotlight-bb-list-benefit-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 700px;
  margin-top: 20px;
  padding-left: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-cards-testimonial {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-cards-testimonial.af-class-heading {
  display: block;
  margin-top: 50px;
  padding-bottom: 30px;
  color: #3f3f3f;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
}

.af-view .af-class-cards-testimonial-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-cards-testimonial-column {
  width: 100%;
  padding-right: 25px;
  padding-bottom: 100px;
  padding-left: 25px;
}

.af-view .af-class-cards-testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.af-view .af-class-cards-testimonial-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #7ba4da;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.af-view .af-class-cards-testimonial-employee-info {
  position: absolute;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-transform: translate(20px, 60px);
  -ms-transform: translate(20px, 60px);
  transform: translate(20px, 60px);
}

.af-view .af-class-cards-testimonial-employee {
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.af-view .af-class-cards-testimonial-position {
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
}

.af-view .af-class-cards-testimonial-employee-details {
  padding-bottom: 12px;
  padding-left: 12px;
}

.af-view .af-class-cards-testimonial-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-spotlight-career {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 450px;
  min-height: 30vh;
  padding-left: 5%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/spotlight-careers-bg2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-spotlight-career-blur-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
}

.af-view .af-class-about-page-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 70vh;
  max-height: 600px;
  padding-bottom: 5%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: url('../images/Development-banner2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 72px;
  line-height: 100px;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 5px 15px rgba(0, 0, 0, 0.36);
}

.af-view .af-class-about-page-header.af-class-development {
  max-height: 600px;
}

.af-view .af-class-about-page-header.af-class-retail {
  max-height: 600px;
  background-image: url('../images/retail-page-header2x.jpg');
}

.af-view .af-class-about-page-header.af-class-leadership {
  max-height: 600px;
  background-image: url('../images/leadership-banner2x.jpg');
}

.af-view .af-class-about-page-header.af-class-corporate-gov {
  max-height: 600px;
  background-image: url('../images/corp-gov-banner2x.jpg');
}

.af-view .af-class-about-page-header.af-class-sustainability {
  background-image: url('../images/sustainability-header2x.jpg');
}

.af-view .af-class-heading-13 {
  font-size: 72px;
  line-height: 120px;
}

.af-view .af-class-slider-property-showcase {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 100px;
}

.af-view .af-class-slider_property_content_wrapper {
  padding-right: 0px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.af-view .af-class-slider-property-image {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('../images/slider_poi_next_image.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.af-view .af-class-slider-property-image.af-class-current {
  margin-right: 30px;
  padding-right: 0px;
  -webkit-box-flex: 2;
  -webkit-flex: 2 100%;
  -ms-flex: 2 100%;
  flex: 2 100%;
  background-image: url('../images/slider_poi_current_image.jpg');
}

.af-view .af-class-slider-property-image.af-class-next-slide {
  -webkit-flex-basis: 10%;
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%;
}

.af-view .af-class-slider-property-image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1 2 60%;
  -ms-flex: 1 2 60%;
  flex: 1 2 60%;
}

.af-view .af-class-slider-property-headers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  padding-top: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: #f1f5fb;
}

.af-view .af-class-slider-property-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 36px;
  line-height: 52px;
  font-weight: 700;
}

.af-view .af-class-slider-property-list-label {
  margin-bottom: -5px;
  font-family: 'Outfit', sans-serif;
  color: #5d5d5d;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.af-view .af-class-slider-property-list-title {
  margin-top: 8px;
  font-family: 'Outfit', sans-serif;
  font-size: 22px;
  line-height: 34px;
  font-weight: 300;
}

.af-view .af-class-slider-property-list-items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.af-view .af-class-slider-property-list-item {
  margin-bottom: 27px;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
}

.af-view .af-class-slider-property-controls-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: #fff;
}

.af-view .af-class-div-block-45 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-property_slide_prev_arrow {
  position: relative;
  width: 60px;
  height: 60px;
  margin-top: 12px;
}

.af-view .af-class-property_slide_next_arrow {
  position: relative;
  width: 60px;
  height: 60px;
}

.af-view .af-class-property_slider_pagination_wrapper {
  position: relative;
  bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-46 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 450px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-content-collage-retail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0px;
}

.af-view .af-class-certification-block-cert-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-content-collage-split-images {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5%;
  margin-right: 0px;
  margin-left: 0%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-cert-block-collage-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-basis: 55%;
  -ms-flex-preferred-size: 55%;
  flex-basis: 55%;
}

.af-view .af-class-certification-block-title {
  font-size: 36px;
  line-height: 46px;
}

.af-view .af-class-content-collage-retail-content-column {
  padding: 5%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 45%;
  -ms-flex: 1 0 45%;
  flex: 1 0 45%;
}

.af-view .af-class-cert-collage-large-image {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('../images/retail-couple-eating2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  -webkit-perspective: -2px;
  perspective: -2px;
}

.af-view .af-class-certification-block-number {
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  font-size: 66px;
  line-height: 72px;
  font-weight: 700;
}

.af-view .af-class-certification-block-number-label {
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.af-view .af-class-certification-block-logo {
  width: 85px;
  height: auto;
  margin-right: 40px;
}

.af-view .af-class-certifications-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
}

.af-view .af-class-content-retail-collage-column {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  -webkit-perspective: 1px;
  perspective: 1px;
}

.af-view .af-class-content-collage-retail-split-image {
  -webkit-box-flex: 0;
  -webkit-flex: 0 47%;
  -ms-flex: 0 47%;
  flex: 0 47%;
  background-image: url('../images/Development-better-choice-small2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-content-collage-retail-split-image.af-class-left {
  background-image: url('../images/retail-numbers-property2x.jpg');
}

.af-view .af-class-content-collage-retail-split-image.af-class-right {
  background-image: url('../images/retail-numbers-exercise2x-p-300.jpg');
}

.af-view .af-class-retail-collage-block-number {
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}

.af-view .af-class-retail-collage-number-description {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 14px;
  line-height: 22px;
}

.af-view .af-class-retail-collage-number-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 350px;
  margin-top: 40px;
  padding-right: 0px;
  padding-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-retail-collage-content-container {
  max-width: 500px;
  margin-top: 75px;
}

.af-view .af-class-div-block-54 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-perspective: -1px;
  perspective: -1px;
  -webkit-transform: translate(5%, 0px);
  -ms-transform: translate(5%, 0px);
  transform: translate(5%, 0px);
  -webkit-transition: opacity 200ms ease, -webkit-transform 508ms ease-in-out;
  transition: opacity 200ms ease, -webkit-transform 508ms ease-in-out;
  transition: opacity 200ms ease, transform 508ms ease-in-out;
  transition: opacity 200ms ease, transform 508ms ease-in-out, -webkit-transform 508ms ease-in-out;
}

.af-view .af-class-retail-collage-highlight {
  position: relative;
  padding-top: 30px;
}

.af-view .af-class-div-block-55 {
  position: absolute;
  left: -30px;
  top: 20px;
  right: 0px;
  bottom: 20px;
  background-image: url('../images/amli-famli2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-list-blocks-header {
  margin-top: 50px;
  margin-bottom: 50px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
}

.af-view .af-class-list-blocks {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-list-block-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 180px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.af-view .af-class-list-block-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 15px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
}

.af-view .af-class-list-blocks-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1300px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-list-block-icon {
  width: 90px;
  max-height: 70px;
}

.af-view .af-class-list-block-label {
  padding-top: 20px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1.18px;
  text-transform: uppercase;
}

.af-view .af-class-content-intro-descriptive-section-highlight {
  position: relative;
  width: 200px;
  height: 5px;
  background-color: #ecd925;
  -webkit-transform: translate(0px, -20px);
  -ms-transform: translate(0px, -20px);
  transform: translate(0px, -20px);
}

.af-view .af-class-slider-employee-awards-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: #f1f5fb;
}

.af-view .af-class-slider-employee-awards-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 450px;
  padding-right: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-slider-employee-award-image {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('../images/slider_poi_next_image.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.af-view .af-class-slider-employee-award-image.af-class-current {
  margin-right: 30px;
  padding-right: 0px;
  -webkit-box-flex: 2;
  -webkit-flex: 2 100%;
  -ms-flex: 2 100%;
  flex: 2 100%;
  background-image: url('../images/culture-Spirt-of-AMLI-JasonH2x.jpg');
  background-position: 50% 50%;
}

.af-view .af-class-slider-employee-awards-image {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('../images/slider_poi_next_image.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.af-view .af-class-slider-employee-awards-image.af-class-next-slide {
  -webkit-flex-basis: 10%;
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%;
  background-image: url('../images/culture-MIllion-Dollar-Phillip-Brucks-Cathy-Dalipi-Amanda-Hohf2x.jpg');
}

.af-view .af-class-slider-employee-controls-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: #fff;
}

.af-view .af-class-content-intro-billboard-benefits {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50vh;
  min-height: 425px;
  padding-right: 5%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/adult-affection-beach2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-div-block-56 {
  padding-right: 30px;
  padding-left: 30px;
  -webkit-flex-basis: 550px;
  -ms-flex-preferred-size: 550px;
  flex-basis: 550px;
}

.af-view .af-class-career-benefits-section-label {
  display: inline-block;
  padding: 6px 15px 12px;
  border-radius: 3px;
  background-color: #3f3f3f;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}

.af-view .af-class-career-benefits-section-label.af-class-no-margin {
  margin-top: 0px;
}

.af-view .af-class-career-benefit-section-tag {
  position: relative;
  z-index: 1;
  max-width: 500px;
  padding-left: 15px;
  font-family: 'Outfit', sans-serif;
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
}

.af-view .af-class-career-benefit-section-tag.af-class-extends-large {
  margin-top: 0px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.af-view .af-class-career-benefits-header-icon {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  width: 120px;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.af-view .af-class-career-benefits-header-icon.af-class-extend-large {
  position: relative;
  width: auto;
  height: auto;
  max-height: 120px;
  max-width: 120px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.af-view .af-class-career-benefits-header-icon.af-class-offset-100 {
  -webkit-transform: translate(100%, -65%);
  -ms-transform: translate(100%, -65%);
  transform: translate(100%, -65%);
}

.af-view .af-class-career-benefits-header-icon.af-class-flex {
  -webkit-transform: translate(20%, -70%);
  -ms-transform: translate(20%, -70%);
  transform: translate(20%, -70%);
}

.af-view .af-class-cards-career-benefit-blocks {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.af-view .af-class-cards-career-benefit-blocks.af-class-reversed {
  height: 100%;
  min-height: 800px;
  margin-top: 50px;
  margin-bottom: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.af-view .af-class-career-benefit-image-column {
  -webkit-box-flex: 2;
  -webkit-flex: 2 50%;
  -ms-flex: 2 50%;
  flex: 2 50%;
  background-image: url('../images/benefits-woman-running2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-career-benefit-image-column.af-class-reversed {
  width: 100%;
  background-image: url('../images/benefits-family-culture2x.jpg');
  background-position: 0px 50%;
}

.af-view .af-class-career-benefit-cards {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-width: none;
  margin-top: 30px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.af-view .af-class-career-benefit-cards.af-class-reversed {
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.af-view .af-class-career-benefit-blocks-content-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-left: 20px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 50%;
  -webkit-flex: 50%;
  -ms-flex: 50%;
  flex: 50%;
}

.af-view .af-class-career-benefit-blocks-content-column.af-class-reversed {
  position: relative;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 3 50%;
  -ms-flex: 0 3 50%;
  flex: 0 3 50%;
}

.af-view .af-class-career-benefit-blocks-header {
  position: relative;
  display: inline-block;
  padding-left: 30px;
}

.af-view .af-class-career-benefit-blocks-header.af-class-reversed {
  width: 100%;
  margin-bottom: 40px;
  padding-left: 30px;
}

.af-view .af-class-career-benefit-blocks-header.af-class-reversed.af-class-extend-large {
  right: 0px;
  min-width: 500px;
  margin-bottom: 0px;
  -webkit-transform: translate(-40%, 0px);
  -ms-transform: translate(-40%, 0px);
  transform: translate(-40%, 0px);
}

.af-view .af-class-career-benefit-blocks-header.af-class-reversed.af-class-flex-end {
  padding-left: 40px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.af-view .af-class-benefit-card-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 420px;
  min-width: 300px;
  margin-bottom: 40px;
  padding: 30px 0px 0px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.15);
}

.af-view .af-class-benefit-card-wrapper.af-class-offset {
  -webkit-transform: translate(50px, 50px);
  -ms-transform: translate(50px, 50px);
  transform: translate(50px, 50px);
}

.af-view .af-class-benefit-card-wrapper.af-class-move-left-med {
  -webkit-transform: translate(-50px, 0px);
  -ms-transform: translate(-50px, 0px);
  transform: translate(-50px, 0px);
}

.af-view .af-class-benefit-card-wrapper.af-class-move-left-small {
  -webkit-transform: translate(-30px, 0px);
  -ms-transform: translate(-30px, 0px);
  transform: translate(-30px, 0px);
}

.af-view .af-class-benefit-card-title {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.af-view .af-class-text-block-9 {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-div-block-63 {
  display: none;
}

.af-view .af-class-image-10 {
  display: block;
  width: 60px;
  margin-right: auto;
  margin-left: auto;
}

.af-view .af-class-benefit-card-content-wrapper {
  position: relative;
  z-index: 1;
  padding-bottom: 25px;
}

.af-view .af-class-benefit-card-title-description {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
}

.af-view .af-class-career-benefit-cards-offset {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  padding-top: 20px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-transform: translate(37%, 0px);
  -ms-transform: translate(37%, 0px);
  transform: translate(37%, 0px);
}

.af-view .af-class-career-benefit-cards-offset.af-class-reversed {
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.af-view .af-class-career-benefit-cards-offset.af-class-_401k-reversed {
  position: relative;
  padding-left: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  -webkit-transform: translate(0px, 50px);
  -ms-transform: translate(0px, 50px);
  transform: translate(0px, 50px);
}

.af-view .af-class-career-benefit-card-column-offset {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.af-view .af-class-career-benefit-card-column-offset.af-class-reversed {
  margin-top: 0px;
  padding-right: 30px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.af-view .af-class-career-benefit-card-column-offset.af-class-reversed.af-class-extends-large {
  padding-right: 30px;
}

.af-view .af-class-career-benefit-card-column {
  position: relative;
  padding-right: 20px;
  padding-bottom: 50px;
  padding-left: 0px;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.af-view .af-class-career-benefit-card-column.af-class-reversed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 6px;
  padding-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.af-view .af-class-career-benefit-card-column.af-class-reversed.af-class-extends-large {
  padding-left: 0px;
}

.af-view .af-class-navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 1px solid #d2d2d2;
  background-color: #fff;
}

.af-view .af-class-container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1440px;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 17px;
  padding-bottom: 0px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-dropdown-list {
  background-color: #fff;
}

.af-view .af-class-dropdown-list-2 {
  background-color: #fff;
}

.af-view .af-class-dropdown-list-2.w--open {
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.af-view .af-class-dropdown-list-3 {
  background-color: #fff;
}

.af-view .af-class-dropdown-list-3.w--open {
  right: 10px;
}

.af-view .af-class-slider-poi-header {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
}

.af-view .af-class-div-block-71 {
  padding-top: 10px;
  background-color: #7ba4da;
}

.af-view .af-class-div-block-73 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 60%;
  -ms-flex: 0 60%;
  flex: 0 60%;
}

.af-view .af-class-div-block-74 {
  width: 90%;
}

.af-view .af-class-list-tall {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-image: url('../images/career-pro-dev-benefits2x-p-1080.jpeg');
  background-position: 100% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-list-tall-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 500px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  background-color: #000;
}

.af-view .af-class-list-tall-content-wrapper.af-class-blur {
  padding-top: 60px;
  padding-right: 60px;
  padding-bottom: 60px;
  -webkit-flex-basis: 45%;
  -ms-flex-preferred-size: 45%;
  flex-basis: 45%;
  background-color: rgba(0, 0, 0, 0.88);
}

.af-view .af-class-div-block-77 {
  width: 90%;
  max-width: 500px;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}

.af-view .af-class-text-block-10 {
  margin-left: 15px;
}

.af-view .af-class-image-11 {
  width: 20px;
  height: 20px;
}

.af-view .af-class-unordered-list {
  margin-top: 60px;
  padding-left: 0px;
}

.af-view .af-class-list-tall-header {
  position: relative;
  display: inline-block;
  margin-bottom: 25px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
}

.af-view .af-class-content-booklet {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.af-view .af-class-content-booklet.af-class-reversed {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.af-view .af-class-content-booklet-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1920px;
  padding-left: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-content-booklet-container.af-class-reversed {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-content-booklet-content {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin-right: 20px;
  padding: 50px 50px 50px 40px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-left: 10px solid #ecd925;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.23);
}

.af-view .af-class-content-booklet-content.af-class-reversed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 20px;
  padding-right: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-right: 10px solid #ecd925;
  border-left-style: none;
  text-align: right;
}

.af-view .af-class-content-booklet-image-column {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.af-view .af-class-booklet-bind-block {
  position: absolute;
  top: 50%;
  right: -35px;
  width: 50px;
  height: 10px;
  background-color: #ecd925;
}

.af-view .af-class-booklet-bind-block.af-class-reversed {
  left: -27px;
}

.af-view .af-class-text-block-11 {
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: 3px solid #ecd925;
  font-family: 'Outfit', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
}

.af-view .af-class-div-block-78 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 75px;
  padding-bottom: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.af-view .af-class-div-block-79 {
  width: 100%;
  max-width: 960px;
  text-align: center;
}

.af-view .af-class-text-block-12 {
  font-family: 'Outfit', sans-serif;
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
}

.af-view .af-class-text-block-13 {
  display: inline-block;
  margin-top: 30px;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.af-view .af-class-list-faq-item-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 30px 25px 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #f6f6f6;
}

.af-view .af-class-list-faq-item-container.af-class-selected {
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #ecd925;
}

.af-view .af-class-list-faq-items {
  width: 100%;
  max-width: 960px;
  padding-left: 0px;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
}

.af-view .af-class-faq-plus {
  padding: 10px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.af-view .af-class-faq-plus.af-class-active {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.af-view .af-class-div-block-80 {
  border-radius: 3px;
  background-color: #ecd925;
}

.af-view .af-class-list-faq {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px;
  padding-bottom: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-list-faq-question-title {
  max-width: 60%;
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
}

.af-view .af-class-list-faq-question-title.af-class-active {
  font-weight: 700;
}

.af-view .af-class-list-faq-question-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 30px 20px 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-list-faq-answer-container {
  padding: 25px 30px 25px 40px;
  background-color: #f3f8ff;
}

.af-view .af-class-section-heading {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
}

.af-view .af-class-section-heading.af-class-faq {
  padding-top: 0px;
  padding-bottom: 30px;
}

.af-view .af-class-list-faq-answer-cta {
  padding: 0px 15px 6px 0px;
  border-radius: 3px;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-text-block-14 {
  display: inline-block;
  padding-right: 10px;
}

.af-view .af-class-image-12 {
  position: relative;
  top: -2px;
}

.af-view .af-class-list-staff-tab-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 960px;
  margin-bottom: 40px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
}

.af-view .af-class-leadership-tab {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-leadership-tab:hover {
  background-color: #ecd925;
}

.af-view .af-class-leadership-tab.af-class-active {
  background-color: #ecd925;
}

.af-view .af-class-list-staff {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px;
  padding-bottom: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-list-staff-leaders-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 960px;
  margin-bottom: 20px;
  padding-bottom: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-list-staff-leader-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33%;
  margin-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-list-staff-leader-profile {
  width: 100px;
  border-radius: 50%;
}

.af-view .af-class-list-staff-leader-name {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 18px;
  font-weight: 700;
}

.af-view .af-class-list-staff-leader-position {
  max-width: 200px;
  font-family: 'Outfit', sans-serif;
  color: #727272;
  font-size: 16px;
  text-align: center;
}

.af-view .af-class-list-staff-tab-header-dropdown {
  display: none;
}

.af-view .af-class-div-block-81 {
  max-width: 750px;
}

.af-view .af-class-div-block-82 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 75px;
  padding-bottom: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-corp-gov-section-header {
  position: relative;
  z-index: 2;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 15px;
  background-color: transparent;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
}

.af-view .af-class-corp-gov-section {
  padding-top: 50px;
}

.af-view .af-class-sustainable-sub-nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 960px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-sustainble-sub-nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #d2d2d2;
}

.af-view .af-class-sustainble-sub-nav-link.af-class-last-child {
  border-right-style: none;
}

.af-view .af-class-sustainable-sub-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-sustainable-why-we-care {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
}

.af-view .af-class-environmental-goals {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
}

.af-view .af-class-green-highlight {
  position: relative;
}

.af-view .af-class-nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-nav-bottom-link-label {
  margin-left: 20px;
  font-family: 'Outfit', sans-serif;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-blue-highlight {
  position: relative;
  line-height: 32px;
}

.af-view .af-class-sustainable-intro-cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 9px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 16px;
  text-decoration: none;
}

.af-view .af-class-text-block-17 {
  display: inline-block;
  padding-right: 10px;
  font-weight: 500;
}

.af-view .af-class-div-block-84 {
  position: relative;
  left: -40px;
  margin-bottom: 75px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  border-left: 10px solid #ecd925;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
}

.af-view .af-class-div-block-85 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 800px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-86 {
  -webkit-flex-basis: 55%;
  -ms-flex-preferred-size: 55%;
  flex-basis: 55%;
}

.af-view .af-class-div-block-87 {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 45%;
  -ms-flex-preferred-size: 45%;
  flex-basis: 45%;
  background-image: url('../images/sustainable-intro2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-div-block-88 {
  width: 90%;
  max-width: 800px;
  padding-right: 20px;
  padding-bottom: 50px;
  padding-left: 50px;
}

.af-view .af-class-div-block-89 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-div-block-90 {
  padding-bottom: 20px;
  padding-left: 30px;
  -webkit-flex-basis: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
}

.af-view .af-class-div-block-91 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 40%;
  -ms-flex: 1 0 40%;
  flex: 1 0 40%;
  background-image: url('../images/benchmark-LEED-community.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-yellow-highlight {
  position: relative;
  width: 100%;
}

.af-view .af-class-cards-content-offset {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 50px 0px 130px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.af-view .af-class-cards-content-offset--card {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 500px;
  -ms-flex-preferred-size: 500px;
  flex-basis: 500px;
  background-color: #2e2e2e;
}

.af-view .af-class-cards-content-offset--card.af-class-reversed {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.af-view .af-class-cards-content-offset-title {
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  text-transform: none;
}

.af-view .af-class-content-card-offset-inner-wrap {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 50px;
}

.af-view .af-class-image-14 {
  position: absolute;
  left: 1px;
  top: 1px;
  right: 0px;
  display: block;
  width: 100%;
  -webkit-transform: translate(2px, -99.5%);
  -ms-transform: translate(2px, -99.5%);
  transform: translate(2px, -99.5%);
}

.af-view .af-class-image-14.af-class-reversed {
  top: auto;
  bottom: 0px;
  height: auto;
  -webkit-transform: translate(0px, 96%) rotateX(180deg) rotateY(0deg) rotateZ(0deg);
  transform: translate(0px, 96%) rotateX(180deg) rotateY(0deg) rotateZ(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.af-view .af-class-html-embed-3 {
  display: none;
}

.af-view .af-class-card-content-offset-outer-wrap {
  padding: 20px 20px 20px 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 2 650px;
  -ms-flex: 0 2 650px;
  flex: 0 2 650px;
}

.af-view .af-class-card-content-offset-outer-wrap.af-class-reversed {
  position: relative;
  padding-right: 0px;
  padding-left: 20px;
  -webkit-transform: translate(0px, 10%);
  -ms-transform: translate(0px, 10%);
  transform: translate(0px, 10%);
}

.af-view .af-class-div-block-94 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-video-w-blurb-container {
  position: relative;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding-top: 100px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-96 {
  width: 100%;
  max-width: 500px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #f1f5fb;
}

.af-view .af-class-video {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.af-view .af-class-video-w-blurb {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 50px;
  padding-bottom: 5%;
  padding-left: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-video-w-blurb-left-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 50%;
  bottom: 50%;
  z-index: 0;
  background-color: #f1f5fb;
}

.af-view .af-class-video-w-blurb-right-bg {
  position: absolute;
  left: 50%;
  top: 50%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  background-color: #f1f5fb;
}

.af-view .af-class-div-block-98 {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  background-image: url('../images/building-community-volunteering.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-cards-highlight-activity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-cards-highlight-activity.af-class-reversed {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.af-view .af-class-cards-highlight-activity-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1600px;
}

.af-view .af-class-cards-highlight-activity-container.af-class-reversed {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.af-view .af-class-div-block-99 {
  margin-bottom: -50px;
  padding-top: 60px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.af-view .af-class-div-block-100 {
  padding: 60px 75px 100px;
  background-color: #333;
}

.af-view .af-class-cards-highlight-community-heading {
  position: relative;
  margin-top: 0px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  letter-spacing: 1px;
}

.af-view .af-class-cards-highlight-community--cards {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-card-highlights-community--card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 46%;
  height: auto;
  max-width: 360px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.af-view .af-class-div-block-103 {
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: #fff;
}

.af-view .af-class-div-block-104 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 850px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-transform: translate(0px, -50px);
  -ms-transform: translate(0px, -50px);
  transform: translate(0px, -50px);
}

.af-view .af-class-cards-highlight-community-card-title {
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.af-view .af-class-image-15 {
  width: 100%;
  height: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.af-view .af-class-grid-hover-action {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 75px;
  padding-top: 75px;
  padding-bottom: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #f1f5fb;
}

.af-view .af-class-grid-hover-action-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1440px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: transparent;
}

.af-view .af-class-grid-hover-card-container {
  width: 33%;
  padding: 10px;
  background-color: transparent;
}

.af-view .af-class-grid-hover--card-content-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 300px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: url('../images/making-impact-atlanta-bg2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-grid-hover--card-content-wrap.af-class-chicago {
  background-image: url('../images/making-impact-chicago-bg2x.jpg');
}

.af-view .af-class-grid-hover--card-content-wrap.af-class-dallas {
  background-image: url('../images/making-impact-dallas-bg2x.jpg');
}

.af-view .af-class-grid-hover--card-content-wrap.af-class-denver {
  background-image: url('../images/making-impact-denver-bg2x.jpg');
}

.af-view .af-class-grid-hover--card-content-wrap.af-class-houston {
  background-image: url('../images/making-impact-houston-bg2x.jpg');
}

.af-view .af-class-grid-hover--card-content-wrap.af-class-seattle {
  background-image: url('../images/making-impact-seattle-bg2x.jpg');
}

.af-view .af-class-grid-hover--card-content-wrap.af-class-florida {
  background-image: url('../images/making-impact-florida-bg2x.jpg');
}

.af-view .af-class-grid-hover--card-content-wrap.af-class-california {
  background-image: url('../images/making-impact-california-bg2x.jpg');
}

.af-view .af-class-grid-hover-action-card-label {
  padding: 10px 15px;
  background-color: hsla(0, 0%, 100%, 0.9);
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-grid-hover-action-header {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  margin-right: auto;
  margin-left: auto;
  -webkit-transform: translate(0px, -100%);
  -ms-transform: translate(0px, -100%);
  transform: translate(0px, -100%);
  font-family: 'Outfit', sans-serif;
  text-align: center;
}

.af-view .af-class-video-testimonial {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-video-testimonial-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1440px;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-text-block-19 {
  margin-bottom: 25px;
  font-family: 'Outfit', sans-serif;
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
}

.af-view .af-class-div-block-107 {
  padding-right: 35px;
  padding-left: 35px;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  text-align: center;
}

.af-view .af-class-div-block-108 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 35px;
  padding-left: 35px;
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.af-view .af-class-text-block-20 {
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-content-video-testimonial {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 75px;
  padding-bottom: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-content-video-testimonial-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1440px;
}

.af-view .af-class-content-video-testy-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-content-video-testy-card {
  display: block;
  width: 100%;
  max-width: 500px;
  margin-bottom: 50px;
  padding-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-video-play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 60px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.af-view .af-class-div-block-109 {
  position: relative;
  display: block;
  margin-top: 25px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-content-video-testy-header {
  position: relative;
  font-family: 'Outfit', sans-serif;
  font-size: 28px;
  line-height: 44px;
}

.af-view .af-class-content-video-testimonial-bg-blue-left {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 55%;
  bottom: 65%;
  z-index: -1;
  background-color: #f1f5fb;
}

.af-view .af-class-content-video-testimonial-bg-blue-right {
  position: absolute;
  left: 54%;
  top: 40%;
  right: 0%;
  bottom: 20%;
  z-index: -1;
  background-color: #f1f5fb;
}

.af-view .af-class-video-2 {
  width: 100%;
  height: 100%;
}

.af-view .af-class-div-block-110 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-find-career-short-callout-band {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f1f5fb;
}

.af-view .af-class-text-block-21 {
  margin-top: -6px;
  padding-top: 0px;
  padding-right: 40px;
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  line-height: 38px;
  font-weight: 700;
}

.af-view .af-class-benchmark-leed-numbers-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 800px;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-text-block-22 {
  font-family: 'Outfit', sans-serif;
  font-size: 72px;
  line-height: 82px;
  font-weight: 700;
}

.af-view .af-class-text-block-22.af-class-green-highlight-half {
  position: relative;
  z-index: 2;
  display: inline-block;
}

.af-view .af-class-text-block-23 {
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.af-view .af-class-benchmark-leed-level-number {
  position: relative;
  font-family: 'Outfit', sans-serif;
  font-size: 48px;
  line-height: 50px;
  font-weight: 700;
}

.af-view .af-class-text-block-25 {
  margin-top: 15px;
  font-family: 'Outfit', sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.af-view .af-class-benchmark-row {
  margin-left: 50px;
  padding-top: 30px;
  padding-bottom: 0px;
  padding-left: 0px;
  background-color: #f5f5f5;
}

.af-view .af-class-benchmark-row.af-class-margin-top-bottom {
  margin-top: 30px;
  margin-bottom: 30px;
}

.af-view .af-class-benchmark-number-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 1px solid #dbdbdb;
}

.af-view .af-class-benchmark-number-column.af-class-first-child {
  padding-left: 0px;
  border-right-style: solid;
}

.af-view .af-class-benchmark-number-column.af-class-last-child {
  border-right-style: none;
}

.af-view .af-class-div-block-118 {
  width: 100%;
  padding-right: 60px;
  padding-bottom: 30px;
  padding-left: 30px;
}

.af-view .af-class-div-block-119 {
  position: relative;
  width: 100%;
  padding-bottom: 30px;
  padding-left: 60px;
}

.af-view .af-class-div-block-120 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 15px 20px 15px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.af-view .af-class-image-16 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 85px;
  -webkit-transform: translate(-60%, 20%);
  -ms-transform: translate(-60%, 20%);
  transform: translate(-60%, 20%);
}

.af-view .af-class-sub-region-map-poi-bar-wrapper {
  position: absolute;
  left: 50%;
  right: auto;
  bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.af-view .af-class-map-poi-icon {
  width: 40px;
  padding-right: 10px;
}

.af-view .af-class-map-poi-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #e7e7e7;
}

.af-view .af-class-map-poi-column.af-class-last-child {
  border-right-style: none;
}

.af-view .af-class-map-poi-label {
  padding-left: 0px;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.af-view .af-class-text-block-26 {
  position: absolute;
  left: 50%;
  top: 0px;
  z-index: 10;
  padding: 3px 15px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #3f3f3f;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-header-property-map {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.af-view .af-class-property-modal-wrapper {
  position: absolute;
  left: 25%;
  top: 15%;
  z-index: 10;
  width: 100%;
  height: auto;
  max-width: 320px;
  min-width: 320px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.25);
}

.af-view .af-class-div-block-121 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-text-block-27 {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 3px;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-div-block-122 {
  width: 100%;
  height: auto;
  padding: 0px 15px 10px;
}

.af-view .af-class-property-card-modal-feat-list {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-left: 14px;
}

.af-view .af-class-div-block-123 {
  width: 100%;
  padding: 5px 20px 10px;
}

.af-view .af-class-div-block-124 {
  width: 100%;
  padding: 10px 20px;
}

.af-view .af-class-div-block-125 {
  padding-right: 0px;
  padding-left: 0px;
}

.af-view .af-class-div-block-126 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0px 20px 14px;
}

.af-view .af-class-div-block-127 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #ebebeb;
}

.af-view .af-class-text-block-28 {
  padding-right: 13px;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}

.af-view .af-class-image-17 {
  position: relative;
  bottom: -2px;
  width: 11px;
}

.af-view .af-class-property-card-modal-feat-list-item {
  position: relative;
  padding-bottom: 7px;
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-text-block-29 {
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-text-block-30 {
  margin-top: 6px;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.af-view .af-class-property-modal-feat-title {
  width: 100%;
  margin-bottom: -5px;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-property-modal-text {
  padding-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-div-block-128 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 100%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-benchmark-link {
  margin-left: 10px;
  border-bottom: 2px solid #ecd925;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 700;
  text-decoration: none;
}

.af-view .af-class-text-block-32 {
  margin-top: 15px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-list-blocks-blurb-column-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 50px;
  padding-left: 50px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-flex-basis: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
}

.af-view .af-class-property-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: url('../images/Chicago_Sub_Region_Banner2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-html-embed-4 {
  display: none;
}

.af-view .af-class-property-slider-arrow {
  position: relative;
  top: 50%;
  width: 40px;
  height: 40px;
  background-color: hsla(0, 0%, 100%, 0.9);
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-property-slider-arrow.af-class-left {
  position: absolute;
}

.af-view .af-class-property-slider-arrow.af-class-right {
  position: absolute;
  right: 0px;
}

.af-view .af-class-move-in-date-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 400px;
  padding: 10px 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #505d68;
  font-family: 'Outfit', sans-serif;
  color: #fff;
}

.af-view .af-class-move-in-date-dash-line {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  width: 100%;
  height: 1px;
  border-top: 1px dotted #fff;
  opacity: 0.47;
  -webkit-transform: translate(0px, 100%);
  -ms-transform: translate(0px, 100%);
  transform: translate(0px, 100%);
}

.af-view .af-class-text-block-33 {
  position: relative;
  z-index: 1;
  padding-right: 10px;
  padding-left: 20px;
  background-color: #505d68;
  font-weight: 300;
}

.af-view .af-class-text-block-34 {
  position: relative;
  z-index: 1;
  padding-right: 20px;
  padding-left: 10px;
  background-color: #505d68;
  font-weight: 700;
}

.af-view .af-class-property-info-card-wrapper {
  position: static;
  left: 30px;
  top: -30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
}

.af-view .af-class-property-info-card-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
}

.af-view .af-class-property-info-card-location-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  border-right: 1px solid #f6f6f6;
}

.af-view .af-class-property-info-card-price-info {
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.af-view .af-class-property-card-location-top-row {
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-bottom: 1px solid #f6f6f6;
}

.af-view .af-class-property-card-location-bottom-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 15px 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  line-height: 16px;
}

.af-view .af-class-property-phase--property-name {
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-div-block-130 {
  position: relative;
  padding: 15px 10px 5px;
}

.af-view .af-class-text-block-35 {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-text-block-36 {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-text-block-37 {
  margin-top: 10px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 400;
}

.af-view .af-class-text-block-38 {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 500;
}

.af-view .af-class-text-block-39 {
  padding-bottom: 7px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 16px;
}

.af-view .af-class-property-banner-info-wrapper {
  position: absolute;
  left: 30px;
  bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-button-view-photos-label {
  padding-left: 10px;
}

.af-view .af-class-button--3d-tour {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  padding: 8px 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  font-family: 'Outfit', sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.af-view .af-class-image-19 {
  width: 18px;
}

.af-view .af-class-button-tour-label {
  position: relative;
  top: -2px;
  padding-right: 10px;
  padding-left: 0px;
}

.af-view .af-class-button-apply {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  padding: 8px 25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #7ba4da;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.af-view .af-class-button-apply.af-class-no-margin {
  margin-top: 10px;
  margin-left: 0px;
  padding: 12px 20px;
  box-shadow: none;
}

.af-view .af-class-button-apply-arrow {
  width: 10px;
}

.af-view .af-class-button-apply-label {
  position: relative;
  top: -3px;
  padding-right: 10px;
  padding-left: 0px;
}

.af-view .af-class-property-scroll-down-text {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 40px);
  -ms-transform: translate(-50%, 40px);
  transform: translate(-50%, 40px);
  font-family: 'Outfit', sans-serif;
  color: #727272;
}

.af-view .af-class-property-intro-floorplans {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  margin-top: 65px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-property-intro-copy-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
}

.af-view .af-class-property-intro-image-column {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  background-image: url('../images/property-intro-image-loft2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-property-intro-copy {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 0px 240px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.af-view .af-class-property-intro-copy-container {
  max-width: 435px;
}

.af-view .af-class-image-20 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 10;
  -webkit-transform: translate(0px, 55%);
  -ms-transform: translate(0px, 55%);
  transform: translate(0px, 55%);
}

.af-view .af-class-property-intro-floorplan-wrapper {
  position: relative;
  background-color: #018094;
}

.af-view .af-class-div-block-131 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-empty-column-50vw {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.af-view .af-class-div-block-133 {
  padding: 50px 30px 50px 50px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.af-view .af-class-property-sub-section-tagline {
  margin-top: 10px;
  font-family: 'Outfit', sans-serif;
  font-size: 38px;
  line-height: 42px;
  font-weight: 700;
}

.af-view .af-class-property-sub-section-tagline.af-class-white {
  width: 100%;
  color: #fff;
}

.af-view .af-class-property-floorplans-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-property-floorplans-wrapper {
  position: relative;
  z-index: 11;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-property-floorplan-card-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-property-floorplan-card {
  overflow: hidden;
  width: 100%;
  max-width: 320px;
  border-bottom: 5px solid #008d94;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 4px 29px 0 rgba(0, 0, 0, 0.3);
}

.af-view .af-class-property-floorplan-card-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2px 15px 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #008d94;
}

.af-view .af-class-floor-plan-image {
  padding: 20px 15px;
}

.af-view .af-class-property-floorplan-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}

.af-view .af-class-property-floorplan-feat-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.af-view .af-class-property-floorplan-feat-col.af-class-first-child {
  border-right: 1px solid #d8d8d8;
}

.af-view .af-class-property-floorplan-size-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
}

.af-view .af-class-property-floorplan-cta-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-property-floorplan-header-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 2 50%;
  -ms-flex: 1 2 50%;
  flex: 1 2 50%;
}

.af-view .af-class-property-floorplan-header-col.af-class-right-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-property-floorplan-large-text {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-property-floorplan-icon {
  max-height: 20px;
  padding-right: 15px;
}

.af-view .af-class-property-floorplan-size-arrow {
  max-width: 20px;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 0px;
  padding-left: 0px;
}

.af-view .af-class-floorplan-card-sqft-label {
  position: relative;
  top: -3px;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.af-view .af-class-property-floorplan-card-small-text {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}

.af-view .af-class-property-floorplan-card-cta-label {
  padding-right: 10px;
}

.af-view .af-class-floorplan-special-card-icon {
  width: 25px;
  margin-right: 10px;
}

.af-view .af-class-div-block-134 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-property-floorplan-special-card {
  max-width: 250px;
  padding: 20px 24px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
}

.af-view .af-class-text-block-40 {
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.af-view .af-class-text-block-41 {
  margin-top: 5px;
  font-family: 'Outfit', sans-serif;
  color: #727272;
  font-size: 12px;
  line-height: 20px;
}

.af-view .af-class-div-block-135 {
  display: inline-block;
  margin-top: 10px;
}

.af-view .af-class-text-block-42 {
  display: inline-block;
  padding-right: 10px;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-weight: 700;
}

.af-view .af-class-text-block-43 {
  font-family: 'Outfit', sans-serif;
  color: #09c18a;
  font-size: 16px;
  line-height: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.af-view .af-class-spotlight-cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 75px 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: -webkit-gradient(linear, left top, right top, from(#043f49), to(rgba(0, 0, 0, 0.3))),
    url('../images/spotlight-cta-lofts-interior-kitchen2x.jpg');
  background-image: linear-gradient(90deg, #043f49, rgba(0, 0, 0, 0.3)), url('../images/spotlight-cta-lofts-interior-kitchen2x.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 700;
}

.af-view .af-class-spotlight-cta-heading {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 36px;
  line-height: 48px;
  font-weight: 600;
  text-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}

.af-view .af-class-spotlight-cta-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-spotlight-cta-button-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-slider-reviews {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-slider-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 400px;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/slider-reviews-bg2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-review-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  height: 100%;
  max-width: 800px;
  padding-top: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-slider-header {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 40px;
  padding-left: 0px;
}

.af-view .af-class-slider-review-container {
  width: 100%;
  max-width: 1440px;
  padding-right: 60px;
  padding-bottom: 60px;
  padding-left: 60px;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-star-icon {
  max-width: 45px;
  padding-right: 7px;
  padding-left: 7px;
}

.af-view .af-class-star-rating-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 25px;
  padding-top: 9px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-star-rating-wrapper.af-class-review-slider {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-icon-quote {
  position: absolute;
  top: 20%;
  right: 0px;
  -webkit-transform: translate(150%, 0px);
  -ms-transform: translate(150%, 0px);
  transform: translate(150%, 0px);
}

.af-view .af-class-icon-quote.af-class-opening {
  left: 0px;
  top: 30%;
  right: auto;
  -webkit-transform: translate(-150%, 0px) rotate(180deg);
  -ms-transform: translate(-150%, 0px) rotate(180deg);
  transform: translate(-150%, 0px) rotate(180deg);
}

.af-view .af-class-icon-quote.af-class-closing {
  top: 30%;
  width: 45px;
}

.af-view .af-class-spotlight-key-fact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/spotlight-key-fact-banner2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-image-21 {
  width: 140px;
}

.af-view .af-class-spotlight-key-fact-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-spotlight-key-fact-big-text {
  font-family: 'Outfit', sans-serif;
  color: #018094;
  font-size: 48px;
  line-height: 73px;
  font-weight: 700;
}

.af-view .af-class-spotlight-key-fact-label {
  font-family: 'Outfit', sans-serif;
  color: #018094;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.af-view .af-class-grid-highlight-images {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-grid-highlight-column {
  padding-right: 20px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.af-view .af-class-grid-highlight-column.af-class-first-col {
  -webkit-box-flex: 50%;
  -webkit-flex: 50%;
  -ms-flex: 50%;
  flex: 50%;
}

.af-view .af-class-grid-highlight-column.af-class-_2nd-column {
  padding-right: 0px;
  padding-left: 20px;
  -webkit-box-flex: 50%;
  -webkit-flex: 50%;
  -ms-flex: 50%;
  flex: 50%;
}

.af-view .af-class-grid-highlight-brick.af-class-intro {
  width: 100%;
}

.af-view .af-class-grid-highlight-image-caption {
  padding: 7px 20px 11px;
  background-color: rgba(0, 0, 0, 0.9);
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.af-view .af-class-grid-highlight-image-caption.af-class-blur {
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: rgba(0, 0, 0, 0.85);
  font-weight: 300;
}

.af-view .af-class-grid-highlight-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30vh;
  max-height: 500px;
  min-height: 300px;
  margin-bottom: 40px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: url('../images/grid-highlight-kitchen2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: 0 13px 20px 0 rgba(0, 0, 0, 0.2);
}

.af-view .af-class-grid-highlight-image.af-class-helper-text {
  margin-bottom: 5px;
}

.af-view .af-class-helper-text {
  padding-top: 15px;
  padding-left: 30px;
  font-family: 'Outfit', sans-serif;
  color: #9c9c9c;
  font-weight: 300;
}

.af-view .af-class-grid-highlight-intro-headings {
  padding: 50px;
}

.af-view .af-class-icon-arrow--right {
  width: 40px;
  height: 20px;
  padding-top: 0px;
}

.af-view .af-class-div-block-136 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  max-width: 400px;
  padding: 40px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.af-view .af-class-text-block-44 {
  position: relative;
  top: 0px;
  padding-right: 20px;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;
}

.af-view .af-class-div-block-137 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 250px;
  margin-top: 40px;
  margin-right: 30px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))),
    url('../images/grid-highlight-main-cta2x.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/grid-highlight-main-cta2x.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.af-view .af-class-spotlight-special-feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-spotlight-special-feat-column {
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 33.33%;
  -ms-flex-preferred-size: 33.33%;
  flex-basis: 33.33%;
  /*  background-image: url('../images/example-bg.png');
  background-position: 0px 0px;
  background-size: 125px;*/
}

.af-view .af-class-spotlight-special-feat-column.af-class-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
  margin-left: 30px;
  padding: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background-color: #018094;
  background-image: none;
}

.af-view .af-class-spotlight-special-feat-column.af-class-left-col {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  /*  background-image: url('../images/special-feat-boats2x.jpg');*/
  /*  background-position: 50% 50%;
  background-size: cover;*/
}

.af-view .af-class-spotlight-special-feat-column.af-class-right-col {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background-image: url('../images/special-feat-couple2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-image-22 {
  width: 10px;
  margin-left: 10px;
}

.af-view .af-class-content-retail-showcase-condensed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-div-block-138 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 1440px;
}

.af-view .af-class-div-block-139 {
  -webkit-box-flex: 60%;
  -webkit-flex: 60%;
  -ms-flex: 60%;
  flex: 60%;
}

.af-view .af-class-div-block-140 {
  padding-top: 30px;
  padding-right: 80px;
  padding-left: 60px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
}

.af-view .af-class-retail-image-caption-wrap {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.85);
  font-family: 'Outfit', sans-serif;
  color: #fdfdfd;
  font-weight: 400;
}

.af-view .af-class-retail-image-caption-wrap.af-class-blur {
  display: inline-block;
  width: 90%;
  margin-bottom: 50px;
  margin-left: 30px;
  line-height: 24px;
}

.af-view .af-class-div-block-141 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-142 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
}

.af-view .af-class-div-block-143 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 50px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.af-view .af-class-div-block-144 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 30vh;
  min-height: 300px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: url('../images/retail-3-Forks2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-html-embed-5 {
  position: absolute;
  left: 80px;
  bottom: -5px;
  z-index: 10;
  width: 40px;
  height: 25px;
}

.af-view .af-class-div-block-145 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1440px;
}

.af-view .af-class-div-block-146 {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 45%;
  -ms-flex-preferred-size: 45%;
  flex-basis: 45%;
}

.af-view .af-class-div-block-147 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 150px;
  padding-right: 50px;
  padding-left: 50px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.af-view .af-class-sustainability-highlights-expanded {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-149 {
  position: relative;
  top: -86px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  padding-right: 50px;
  padding-left: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
}

.af-view .af-class-image-24 {
  position: absolute;
  top: -6px;
  right: 0px;
  max-width: 150px;
  -webkit-transform: translate(50%, 0px);
  -ms-transform: translate(50%, 0px);
  transform: translate(50%, 0px);
}

.af-view .af-class-div-block-150 {
  max-width: 500px;
  margin-bottom: -40px;
  padding-right: 24px;
}

.af-view .af-class-div-block-151 {
  margin-bottom: 50px;
  padding: 30px 30px 30px 8px;
  -webkit-flex-basis: 42%;
  -ms-flex-preferred-size: 42%;
  flex-basis: 42%;
}

.af-view .af-class-div-block-152 {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #f2f2f2;
}

.af-view .af-class-image-25 {
  position: relative;
  width: 70px;
  height: 70px;
  -webkit-transform: translate(0px, 50%);
  -ms-transform: translate(0px, 50%);
  transform: translate(0px, 50%);
}

.af-view .af-class-div-block-153 {
  position: absolute;
  z-index: 1;
  display: inline-block;
  -webkit-transform: translate(-50%, -65%);
  -ms-transform: translate(-50%, -65%);
  transform: translate(-50%, -65%);
}

.af-view .af-class-div-block-154 {
  position: relative;
  z-index: 1;
  width: 100%;
}

.af-view .af-class-heading-18 {
  font-family: 'Outfit', sans-serif;
  font-size: 19px;
  line-height: 30px;
}

.af-view .af-class-spotlight-tour {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-spotlight-tour-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-height: 100%;
  max-width: 1440px;
  min-height: 450px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  background-image: url('../images/spotlight-tour-kitchen2x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-div-block-155 {
  padding: 50px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  background-color: rgba(0, 0, 0, 0.8);
}

.af-view .af-class-div-block-155.af-class-blur {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-width: 500px;
  min-height: 100%;
  padding: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-div-block-155.af-class-blur.af-class-max-width {
  width: 50%;
  max-width: 475px;
}

.af-view .af-class-property-header-button-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-image-26 {
  display: none;
}

.af-view .af-class-property-sub-page-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50vh;
  max-height: 500px;
  min-height: 375px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-property-sub-page-header.af-class-amenities {
  max-height: 700px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), color-stop(60%, hsla(0, 0%, 100%, 0))),
    url('../images/amenities-header2x.jpg');
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), hsla(0, 0%, 100%, 0) 60%), url('../images/amenities-header2x.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.af-view .af-class-property-sub-page-header.af-class-contact {
  max-height: 700px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), color-stop(60%, hsla(0, 0%, 100%, 0))),
    url('../images/lofts-amenity-exterior-lobby2x.jpg');
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), hsla(0, 0%, 100%, 0) 60%), url('../images/lofts-amenity-exterior-lobby2x.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.af-view .af-class-property-sub-page-header-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding-bottom: 40px;
  padding-left: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-transform: translate(0px, 0%);
  -ms-transform: translate(0px, 0%);
  transform: translate(0px, 0%);
}

.af-view .af-class-heading-19 {
  width: 100%;
  padding-left: 30px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 72px;
  line-height: 82px;
  text-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
}

.af-view .af-class-property-subpage-placard-brick {
  padding: 10px 15px;
}

.af-view .af-class-property-subpage-placard-brick.af-class-prop-name {
  padding-right: 20px;
  padding-left: 20px;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}

.af-view .af-class-property-subpage-placard-brick.af-class-prop-address {
  padding: 15px 20px;
  border-right: 1px solid #d8d8d8;
}

.af-view .af-class-property-subpage-placard-brick.af-class-prop-cta {
  padding-top: 8px;
  padding-bottom: 10px;
  border-top: 1px solid #d8d8d8;
}

.af-view .af-class-property-subpage-placard-brick.af-class-prop-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 20px 6px 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-property-subpage-placard {
  width: 545px;
  height: 200px;
  min-width: 390px;
  margin-top: 50px;
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
}

.af-view .af-class-icon--star--active {
  width: 20px;
  margin-right: 5px;
}

.af-view .af-class-icon--star--inactive {
  width: 20px;
}

.af-view .af-class-text-block-47 {
  padding-top: 6px;
  padding-bottom: 6px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-text-block-48 {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
}

.af-view .af-class-text-block-49 {
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  line-height: 25px;
  font-weight: 700;
}

.af-view .af-class-button-2 {
  display: block;
  border-radius: 6px;
  text-align: center;
}

.af-view .af-class-text-block-50 {
  padding-left: 7px;
  font-family: 'Outfit', sans-serif;
  color: #727272;
}

.af-view .af-class-div-block-157 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-top: 10px;
  padding: 6px 12px 5px 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #06845e;
  color: #fff;
}

.af-view .af-class-image-28 {
  width: 14px;
}

.af-view .af-class-list-amenities-w-gallery {
  position: relative;
  width: 50vw;
}

.af-view .af-class-list-amenities-w-gallery.af-class-reversed {
  left: 50%;
  margin-top: 100px;
}

.af-view .af-class-amenities-slider-list-item {
  width: 50%;
  margin-bottom: 15px;
  padding-right: 40px;
  font-family: 'Outfit', sans-serif;
  font-size: 13px;
}

.af-view .af-class-amenities-slider-list-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 750px;
  padding-top: 50px;
  padding-left: 75px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-159 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 30px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.af-view .af-class-div-block-159.af-class-reversed {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-amenity-slider-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #018094;
}

.af-view .af-class-amenity-slider-heading.af-class-reversed {
  padding-left: 40px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.af-view .af-class-div-block-160 {
  width: 90%;
  max-width: 600px;
  padding-right: 50px;
}

.af-view .af-class-amenities-image-slider-container {
  position: absolute;
  left: 100%;
  top: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 50vw;
  height: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-amenities-image-slider-container.af-class-reversed {
  left: -100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.af-view .af-class-amenity-slider-image {
  width: 82%;
  height: 100%;
  max-width: 82%;
}

.af-view .af-class-amenity-slider-image--next {
  width: 14%;
  height: 100%;
}

.af-view .af-class-html-embed-6 {
  display: none;
}

.af-view .af-class-amenities-slider-toolbar {
  position: absolute;
  left: 0px;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 27px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.15);
  -webkit-transform: translate(25%, 50%);
  -ms-transform: translate(25%, 50%);
  transform: translate(25%, 50%);
}

.af-view .af-class-amenities-slider-toolbar.af-class-reversed {
  left: auto;
  right: 0px;
  padding-right: 27px;
  padding-left: 27px;
  -webkit-transform: translate(-25%, 50%);
  -ms-transform: translate(-25%, 50%);
  transform: translate(-25%, 50%);
}

.af-view .af-class-slider-dot {
  border-radius: 50%;
  background-color: #3f3f3f;
}

.af-view .af-class-slider-dot.af-class-active {
  width: 16px;
  height: 16px;
  margin-right: 7px;
  margin-left: 7px;
  background-color: #018094;
}

.af-view .af-class-slider-dot.af-class-active.af-class-next {
  width: 13px;
  height: 13px;
  margin-right: 5px;
  margin-left: 5px;
  opacity: 0.6;
}

.af-view .af-class-slider-dot.af-class-active.af-class-last {
  width: 10px;
  height: 10px;
  opacity: 0.3;
}

.af-view .af-class-html-embed-8 {
  width: 14px;
  height: 25px;
  margin-right: 20px;
}

.af-view .af-class-html-embed-9 {
  width: 14px;
  height: 25px;
  margin-left: 20px;
}

.af-view .af-class-list-amenities-resources {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 150px;
  margin-bottom: 50px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-list-resources-list-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  padding-right: 60px;
  padding-left: 60px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-div-block-163 {
  width: 35%;
  padding: 60px 50px 60px 60px;
  background-color: #018094;
}

.af-view .af-class-div-block-164 {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 300px;
  padding-right: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-165 {
  position: absolute;
  z-index: -1;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f6f6f6;
  -webkit-transform: translate(-30%, 20%);
  -ms-transform: translate(-30%, 20%);
  transform: translate(-30%, 20%);
}

.af-view .af-class-div-block-166 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-div-block-166.af-class-offset {
  margin-top: 60px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.af-view .af-class-image-31 {
  width: 100px;
}

.af-view .af-class-heading-20 {
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
}

.af-view .af-class-div-block-167 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1000px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-image-32 {
  width: 100px;
}

.af-view .af-class-div-block-168 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #eaeaea;
}

.af-view .af-class-div-block-168.af-class-last-child {
  margin-right: 0px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.af-view .af-class-div-block-169 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-list-perks-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.76)), to(rgba(0, 0, 0, 0.22))),
    url('../images/amenities-amenity-perks-banner2x.jpg');
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.22)), url('../images/amenities-amenity-perks-banner2x.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.af-view .af-class-list-perks-banner-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  height: 470px;
  max-width: 1440px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-172 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1440px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate(0px, -20%);
  -ms-transform: translate(0px, -20%);
  transform: translate(0px, -20%);
}

.af-view .af-class-list-perks {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-list-perks-title {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 48px;
  line-height: 50px;
  font-weight: 700;
  text-transform: uppercase;
}

.af-view .af-class-list-resources {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-list-resource-item-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-list-resource-item {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 320px;
  margin-right: 15px;
  padding-right: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 100%;
  -ms-flex: 0 100%;
  flex: 0 100%;
}

.af-view .af-class-spotlight-next-steps {
  padding: 50px;
}

.af-view .af-class-spotlight-next-steps-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40vh;
  min-height: 400px;
  padding: 50px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#000), to(transparent)), url('../images/spotlight-tour-kitchen2x.jpg');
  background-image: linear-gradient(0deg, #000, transparent), url('../images/spotlight-tour-kitchen2x.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.af-view .af-class-div-block-175 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-spotlight-next-steps-label {
  padding-right: 30px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  text-transform: uppercase;
}

.af-view .af-class-text-block-53 {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 48px;
  line-height: 52px;
  font-weight: 700;
}

.af-view .af-class-next-steps-arrow {
  position: relative;
  top: 8px;
  width: 50px;
  height: 50px;
  margin-left: 30px;
}

.af-view .af-class-div-block-176 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 35px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-left: 1px solid #fff;
}

.af-view .af-class-div-block-177 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding: 17px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-sticky-floorplan-wrapper {
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fafafa;
}

.af-view .af-class-floorplan-link-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
}

.af-view .af-class-div-block-179 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  background-color: #fff;
}

.af-view .af-class-text-block-54 {
  padding-left: 15px;
  line-height: 13px;
  letter-spacing: 1px;
}

.af-view .af-class-div-block-180 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.af-view .af-class-text-block-55 {
  padding-right: 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: 'Outfit', sans-serif;
  color: #727272;
}

.af-view .af-class-floorplan-apply-btn {
  margin-right: 30px;
  margin-left: 30px;
  padding-right: 30px;
  padding-left: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
}

.af-view .af-class-button-3 {
  padding-right: 25px;
  padding-left: 25px;
  border-radius: 3px;
}

.af-view .af-class-link {
  padding-bottom: 2px;
  border-bottom: 1px solid grey;
  font-family: 'Outfit', sans-serif;
  color: grey;
  text-decoration: none;
}

.af-view .af-class-floorplan-nav-link {
  margin-left: 8px;
  padding-left: 15px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-floorplan-nav-link.af-class-active {
  margin-left: 0px;
  padding-left: 0px;
  color: #008d94;
  font-weight: 700;
}

.af-view .af-class-div-block-182 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding-top: 0px;
  padding-right: 30px;
  padding-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-spotlight-current-specials {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #3f3f3f;
}

.af-view .af-class-div-block-184 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
  padding-top: 8px;
  padding-right: 30px;
  padding-bottom: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px solid #fff;
  font-family: 'Outfit', sans-serif;
  color: #fff;
}

.af-view .af-class-image-33 {
  width: 20px;
}

.af-view .af-class-div-block-185 {
  margin-right: 35px;
}

.af-view .af-class-text-block-56 {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

.af-view .af-class-text-block-57 {
  background-color: transparent;
  opacity: 0.5;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}

.af-view .af-class-text-block-58 {
  padding-left: 15px;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.af-view .af-class-button-4 {
  padding-right: 25px;
  padding-left: 25px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  background-color: #3f3f3f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#575757), to(#636363));
  background-image: linear-gradient(180deg, #575757, #636363);
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
}

.af-view .af-class-image-34 {
  height: 75px;
}

.af-view .af-class-div-block-186 {
  position: relative;
  margin-right: 20px;
}

.af-view .af-class-div-block-187 {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(40%, rgba(63, 63, 63, 0)), to(#3f3f3f));
  background-image: linear-gradient(90deg, rgba(63, 63, 63, 0) 40%, #3f3f3f);
}

.af-view .af-class-image-35 {
  height: 225px;
}

.af-view .af-class-floorplan-section-heading-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.af-view .af-class-floorplan-section-header {
  position: absolute;
  left: 0%;
  top: 50%;
  z-index: 2;
  -webkit-transform: translate(-20%, -75%);
  -ms-transform: translate(-20%, -75%);
  transform: translate(-20%, -75%);
  font-family: 'Outfit', sans-serif;
  font-size: 72px;
  line-height: 100px;
}

.af-view .af-class-div-block-189 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-div-block-190 {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(44%, hsla(0, 0%, 100%, 0)));
  background-image: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0) 44%);
}

.af-view .af-class-floorplan-section-cards-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-div-block-192 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1400px;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-transform: translate(0px, -35px);
  -ms-transform: translate(0px, -35px);
  transform: translate(0px, -35px);
}

.af-view .af-class-div-block-193 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-card-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #008d94;
}

.af-view .af-class-floorplans-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 31%;
  max-width: 400px;
  margin-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 3px;
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.15);
}

.af-view .af-class-div-block-194 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}

.af-view .af-class-floorplans-card-beds-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.af-view .af-class-floorplans-card-beds-column.af-class-bordered {
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
}

.af-view .af-class-div-block-196 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-html-embed-11 {
  margin-right: 10px;
  margin-left: 10px;
}

.af-view .af-class-floorplans-card-column-labels {
  padding-left: 5px;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
}

.af-view .af-class-image-36 {
  width: 100%;
  height: auto;
  padding: 30px;
}

.af-view .af-class-text-block-60 {
  padding-right: 15px;
  font-family: 'Outfit', sans-serif;
  color: #018094;
  font-size: 18px;
  font-weight: 700;
}

.af-view .af-class-div-block-197 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-floorplans-card-cta-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-html-embed-12 {
  margin-top: 5px;
}

.af-view .af-class-text-block-61 {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-text-block-62 {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

.af-view .af-class-text-block-63 {
  margin-left: 15px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-floorplans-card-unit-label {
  font-family: 'Outfit', sans-serif;
  color: #707070;
  font-size: 15px;
  font-weight: 400;
}

.af-view .af-class-floorplan-policies {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-div-block-200 {
  width: 50%;
  height: 100%;
}

.af-view .af-class-policies-tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-tab-labels-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-top: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.af-view .af-class-tab-header {
  padding-right: 20px;
  padding-left: 20px;
  opacity: 0.75;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
}

.af-view .af-class-tab-header.af-class-active {
  position: relative;
  z-index: 1;
  margin-left: 10px;
  padding: 6px 16px;
  border-top: 5px solid #7ba4da;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #fff;
  box-shadow: 0 -4px 15px 0 rgba(0, 0, 0, 0.15);
  opacity: 1;
  font-size: 18px;
}

.af-view .af-class-tab-header.af-class-active.af-class-small {
  color: #018094;
  font-size: 14px;
  font-weight: 400;
}

.af-view .af-class-div-block-203 {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -16px;
  z-index: 0;
  height: 11px;
  box-shadow: 0 -1px 20px 0 rgba(0, 0, 0, 0.1);
}

.af-view .af-class-div-block-204 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-tabbed-content-dotted-line {
  position: absolute;
  left: 0px;
  top: 50%;
  bottom: 0px;
  width: 100%;
  height: 1px;
  border: 1px dotted #d8d8d8;
  -webkit-transform: translate(0px, 2px);
  -ms-transform: translate(0px, 2px);
  transform: translate(0px, 2px);
}

.af-view .af-class-tabbed-breakdown-header {
  position: relative;
  z-index: 1;
  padding-right: 10px;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
  color: #008d94;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
}

.af-view .af-class-div-block-206 {
  width: 90%;
  max-width: 550px;
  padding-right: 20px;
  padding-left: 20px;
}

.af-view .af-class-tab-content-details-row {
  padding-top: 20px;
  padding-right: 30px;
  padding-left: 20px;
}

.af-view .af-class-div-block-208 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.af-view .af-class-floor-plan-size-link {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.af-view .af-class-active-tab-content-wrap {
  padding-top: 0px;
  padding-right: 15px;
  padding-left: 15px;
}

.af-view .af-class-div-block-212 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-floorplann-unit-row {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 30px 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-bottom: 1px solid #d2d2d2;
}

.af-view .af-class-floorplann-unit-row.af-class-selected {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-floorplan-name-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 15px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-floorplan-unit-column {
  padding-right: 15px;
}

.af-view .af-class-div-block-215 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-floorplan-lease-term-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 110px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-text-block-65 {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-floorplan-unit-column-label {
  margin-bottom: 5px;
  font-family: 'Outfit', sans-serif;
  color: #727272;
}

.af-view .af-class-floorplan-unit-column-value {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 500;
}

.af-view .af-class-text-block-66 {
  padding-right: 8px;
  font-family: 'Outfit', sans-serif;
  color: #666667;
  font-weight: 700;
}

.af-view .af-class-div-block-216 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-text-block-67 {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #d2d2d2;
  font-family: 'Outfit', sans-serif;
  color: #008d94;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-div-block-217 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  padding-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-floorplan-unit-column-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-floorplan-unit-column-container.af-class-ctas {
  width: auto;
}

.af-view .af-class-div-block-218 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  max-width: 600px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #282828;
}

.af-view .af-class-div-block-219 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1600px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-220 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.af-view .af-class-floorplan-units-title {
  padding-bottom: 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: 'Outfit', sans-serif;
  color: #008d94;
  font-size: 30px;
  line-height: 42px;
  font-weight: 700;
}

.af-view .af-class-div-block-221 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-div-block-222 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 30px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 1px solid #d2d2d2;
}

.af-view .af-class-div-block-223 {
  width: 100%;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-left: 40px;
  border-bottom: 1px solid #d2d2d2;
}

.af-view .af-class-div-block-224 {
  width: 90%;
  padding-top: 30px;
}

.af-view .af-class-div-block-225 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.af-view .af-class-image-38 {
  margin-right: 16px;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-div-block-226 {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  margin-top: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.af-view .af-class-div-block-227 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-228 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.af-view .af-class-div-block-229 {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, right top, left top, from(#282828), to(transparent));
  background-image: linear-gradient(270deg, #282828, transparent);
}

.af-view .af-class-div-block-230 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-text-block-69 {
  margin-right: 20px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 300;
}

.af-view .af-class-left-arrrow {
  position: relative;
  top: 1px;
  width: 14px;
  height: 20px;
  margin-right: 2px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.af-view .af-class-text-block-70 {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 700;
}

.af-view .af-class-html-embed-19 {
  width: 30px;
  height: 20px;
  margin-top: 15px;
}

.af-view .af-class-floorplan-unit-details-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 700px;
  margin-top: 20px;
  padding-top: 20px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px dashed #d2d2d2;
}

.af-view .af-class-unordered-list-3 {
  padding-left: 20px;
}

.af-view .af-class-floorplan-unit-header {
  padding-bottom: 8px;
  font-family: 'Outfit', sans-serif;
  color: #008d94;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-unit-feat-list-item {
  margin-bottom: 5px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
}

.af-view .af-class-list-item-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-234 {
  position: absolute;
  left: 0px;
  right: 0px;
  height: 1px;
  border-top: 1px dotted #727272;
  -webkit-transform: translate(0px, 1px);
  -ms-transform: translate(0px, 1px);
  transform: translate(0px, 1px);
}

.af-view .af-class-div-block-235 {
  width: 100%;
  max-width: 320px;
}

.af-view .af-class-text-block-71 {
  position: relative;
  z-index: 1;
  padding-right: 10px;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
  color: #727272;
  font-weight: 700;
}

.af-view .af-class-text-block-72 {
  position: relative;
  z-index: 1;
  padding-left: 10px;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
  color: #727272;
  font-weight: 700;
  text-align: right;
}

.af-view .af-class-unordered-list-4 {
  margin-top: 5px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d2d2d2;
}

.af-view .af-class-div-block-236 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.af-view .af-class-div-block-237 {
  margin-top: 50px;
}

.af-view .af-class-button-5 {
  margin-top: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-left: 30px;
  border-radius: 3px;
  background-color: #018094;
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.af-view .af-class-right-arrow {
  width: 20px;
  height: 38px;
  margin-right: 2px;
  background-color: transparent;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  color: #fff;
}

.af-view .af-class-property-navigation {
  position: relative;
  z-index: 100;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 1px solid #d2d2d2;
}

.af-view .af-class-property-nav-container {
  position: relative;
  z-index: 11;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1600px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #fff;
}

.af-view .af-class-image-39 {
  height: 45px;
}

.af-view .af-class-property-menu-line {
  width: 20px;
  height: 2px;
  background-color: #018094;
}

.af-view .af-class-property-menu-line.af-class-top {
  margin-bottom: 10px;
}

.af-view .af-class-div-block-240 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 1px solid #d2d2d2;
}

.af-view .af-class-property-nav-link {
  overflow: visible;
  margin-right: 8px;
  margin-left: 8px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-property-nav-link.w--current {
  padding-bottom: 0px;
  border-bottom: 3px none #018094;
  color: #4c698f;
  font-size: 14px;
}

.af-view .af-class-property-nav-link.af-class-last-child {
  position: relative;
}

.af-view .af-class-property-nav-brand-wrapper {
  padding: 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px solid #d2d2d2;
  background-color: #fff;
}

.af-view .af-class-property-nav-link-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-menu-icon-label {
  padding-bottom: 4px;
}

.af-view .af-class-tabbed-breakdown-price {
  position: relative;
  z-index: 1;
  padding-right: 0px;
  padding-left: 10px;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
  color: #008d94;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  white-space: nowrap;
}

.af-view .af-class-property-location-intro {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-div-block-242 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.af-view .af-class-div-block-243 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #008d94;
}

.af-view .af-class-div-block-244 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  padding-top: 75px;
  padding-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-location-benefit-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-text-block-74 {
  margin-left: 20px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-div-block-245 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
}

.af-view .af-class-div-block-246 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.af-view .af-class-div-block-247 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  background-color: #fff;
}

.af-view .af-class-list-item-4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
}

.af-view .af-class-list-item-4.af-class-last-child {
  border-bottom-style: none;
}

.af-view .af-class-unordered-list-5 {
  padding-left: 0px;
}

.af-view .af-class-div-block-248 {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  z-index: -1;
  height: 1px;
  border-bottom: 1px dashed #000;
}

.af-view .af-class-location-distance-label {
  padding-right: 10px;
  padding-left: 10px;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
}

.af-view .af-class-text-block-76 {
  padding-right: 20px;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.af-view .af-class-text-block-76.af-class-last-child {
  padding-right: 10px;
}

.af-view .af-class-div-block-249 {
  width: 100%;
  max-width: 500px;
}

.af-view .af-class-div-block-250 {
  width: 90%;
  max-width: 590px;
}

.af-view .af-class-text-block-77 {
  margin-top: 30px;
  padding-left: 0px;
  font-family: 'Outfit', sans-serif;
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
}

.af-view .af-class-image-40 {
  position: relative;
  margin-top: 30px;
}

.af-view .af-class-div-block-251 {
  margin-right: 30px;
}

.af-view .af-class-location-score-number {
  position: relative;
  font-family: 'Outfit', sans-serif;
  font-size: 64px;
  line-height: 64px;
  font-weight: 700;
}

.af-view .af-class-location-score-label {
  margin-top: 0px;
  font-family: 'Outfit', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.af-view .af-class-property-location-map {
  position: relative;
  z-index: 1;
  min-height: 90vh;
}

.af-view .af-class-property-location-map-map {
  height: 85vh;
  min-height: 500px;
  background-image: url('../images/Sub_Region_Map_Google_Default.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-locations-map-poi-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  height: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
}

.af-view .af-class-div-block-255 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #3f3f3f;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-div-block-255.af-class-perks {
  background-color: #f5a623;
}

.af-view .af-class-div-block-256 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px solid #d2d2d2;
}

.af-view .af-class-div-block-256.af-class-last-child {
  border-right-style: none;
}

.af-view .af-class-text-block-80 {
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-div-block-257 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-div-block-258 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-sustainable-posts {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(60%, #008d94), color-stop(0, #fff));
  background-image: linear-gradient(180deg, #008d94 60%, #fff 0);
}

.af-view .af-class-sustainable-posts.af-class-bg-image {
  position: relative;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(60%, #008d94), color-stop(0, #fff));
  background-image: linear-gradient(180deg, #008d94 60%, #fff 0);
}

.af-view .af-class-div-block-260 {
  position: relative;
  z-index: 10;
  width: 90%;
  max-width: 1440px;
}

.af-view .af-class-div-block-261 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-div-block-262 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-div-block-263 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Outfit', sans-serif;
  color: #727272;
}

.af-view .af-class-right-arrow--dark {
  position: relative;
  top: 2px;
  width: 15px;
  height: 25px;
  margin-left: 15px;
}

.af-view .af-class-text-block-81 {
  margin-top: 5px;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-unordered-list-6 {
  padding: 30px;
  border-radius: 3px;
  background-color: #fff;
}

.af-view .af-class-list-item-5 {
  padding-top: 15px;
  padding-right: 5px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d2d2d2;
}

.af-view .af-class-text-block-82 {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 36px;
  line-height: 40px;
  font-weight: 600;
}

.af-view .af-class-yellow-highlight-half {
  position: relative;
  z-index: 1;
}

.af-view .af-class-location-benefit-icon {
  width: 40px;
  height: auto;
}

.af-view .af-class-html-embed-21 {
  position: relative;
  left: 2px;
  top: 3px;
  width: 10px;
}

.af-view .af-class-arrow-chevron-white-circled {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  padding: 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 50%;
}

.af-view .af-class-arrow-chevron-white-circled.af-class-left {
  margin-right: 20px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.af-view .af-class-unordered-list-7 {
  padding-left: 20px;
}

.af-view .af-class-bold-text {
  line-height: 24px;
}

.af-view .af-class-home-intro-content-container {
  max-width: 500px;
}

.af-view .af-class-html-embed-22 {
  width: 22px;
  height: 22px;
}

.af-view .af-class-close {
  width: 13px;
  height: 20px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.af-view .af-class-floorplans-card-col-sqft-label {
  padding-left: 5px;
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-html-embed-23 {
  display: none;
}

.af-view .af-class-sticky-card-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  height: 100vh;
  padding-top: 60px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 40%;
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
}

.af-view .af-class-the-grid-wrapper {
  width: 60%;
}

.af-view .af-class-div-block-267 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-grid-step-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-grid-step-wrapper.af-class-next-step {
  border-top: 1px dotted #d2d2d2;
}

.af-view .af-class-grid-step-wrapper.af-class-payment-breakdown {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-top: 1px dotted #d2d2d2;
}

.af-view .af-class-grid-step-header {
  padding-top: 30px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.af-view .af-class-floorplan-card-header-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-floorplan-card-header-col.af-class-right-aligned {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-floorplan-card-header-col.af-class-left-aligned {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-floorplan-card-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 1px solid #d2d2d2;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
}

.af-view .af-class-floorplan-card-col.af-class-last-col {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-right-style: none;
}

.af-view .af-class-floorplan-card-col.af-class-border-top.af-class-profile {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-floorplan-card-col.af-class-border-top.af-class-profile.af-class-no-padding.af-class-no-border {
  border-right-style: none;
}

.af-view .af-class-floorplan-card-col.af-class-border-top.af-class-profile.af-class-no-padding.af-class-no-border.af-class-right-align {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-floorplan-card-col.af-class-profile {
  padding: 5px 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 13px;
}

.af-view .af-class-floorplan-card-col.af-class-profile.af-class-no-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.af-view .af-class-floorplan-card-col.af-class-profile.af-class-no-padding.af-class-left-align {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-floorplan-card-col.af-class-no-border {
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-right-style: none;
}

.af-view .af-class-floorplan-card-col.af-class-no-border.af-class-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-floorplan-card-col.af-class-expired {
  border-right-style: none;
}

.af-view .af-class-floorplan-card-col.af-class-border-left {
  border-left: 1px solid #d2d2d2;
  border-right-style: none;
}

.af-view .af-class-floorplan-card-col.af-class-right-align {
  padding-right: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-right-style: none;
}

.af-view .af-class-floorplan-card-col.af-class-padded-small {
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-floorplan-card-col.af-class-padded-small.af-class-no-border {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.af-view .af-class-floorplan-card-col.af-class-padded-xlarge {
  padding-top: 20px;
  padding-bottom: 20px;
}

.af-view .af-class-floorplan-card-col.af-class-padded-xlarge.af-class-expired {
  padding-top: 10px;
  padding-bottom: 10px;
}

.af-view .af-class-card-details-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #d2d2d2;
}

.af-view .af-class-card-details-row.af-class-padded {
  padding: 10px;
}

.af-view .af-class-card-details-row.af-class-padded.af-class-sides-padded {
  padding: 15px;
}

.af-view .af-class-card-details-row.af-class-padded--large {
  padding: 10px 0px;
}

.af-view .af-class-card-details-row.af-class-padded-xsmall {
  padding: 5px;
}

.af-view .af-class-floorplan-sticky-card {
  position: relative;
  max-width: 400px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 3px 3px 16px 0 rgba(0, 0, 0, 0.2);
}

.af-view .af-class-div-block-272 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #d2d2d2;
}

.af-view .af-class-floorplan-card-image-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 50px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-floorplan-card-label {
  margin-bottom: -5px;
  font-family: 'Outfit', sans-serif;
  color: #aeaeae;
  font-size: 10px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-floorplan-card-bold-heading {
  margin-top: 5px;
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-floorplan-sticky-card-back-wrap {
  position: absolute;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate(0px, -100%);
  -ms-transform: translate(0px, -100%);
  transform: translate(0px, -100%);
}

.af-view .af-class-div-block-273 {
  width: 100%;
  max-width: 775px;
  padding-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-div-block-274 {
  width: 90%;
  max-width: 900px;
}

.af-view .af-class-lease-options-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.af-view .af-class-div-block-275 {
  width: 100%;
  max-width: 400px;
  margin-right: 0px;
  padding-right: 0px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-div-block-275.af-class-full-width {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-right: auto;
  padding-top: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-div-block-275.af-class-first-child {
  margin-right: 30px;
  margin-bottom: 30px;
  padding-top: 0px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-div-block-276 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-div-block-277 {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  z-index: 0;
  height: 1px;
  border: 1px dotted #d2d2d2;
}

.af-view .af-class-text-block-83 {
  position: relative;
  z-index: 1;
  margin-left: auto;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-text-block-83.af-class-padding-right {
  padding-right: 15px;
}

.af-view .af-class-text-block-83.af-class-padding-right.af-class-selected {
  background-color: #fafafa;
}

.af-view .af-class-text-block-84 {
  position: relative;
  z-index: 1;
  padding-right: 10px;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-text-block-84.af-class-padding-left {
  padding-left: 15px;
}

.af-view .af-class-text-block-84.af-class-padding-left.af-class-selected {
  background-color: #fafafa;
}

.af-view .af-class-pet-counter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px 2px;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
}

.af-view .af-class-icon--dog {
  width: 30px;
  height: 30px;
  margin-top: -2px;
  margin-right: 15px;
}

.af-view .af-class-div-block-279 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 22px;
  height: 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #727272;
  border-radius: 3px;
}

.af-view .af-class-div-block-280 {
  width: 10px;
  height: 2px;
  border-radius: 2px;
  background-color: #3f3f3f;
}

.af-view .af-class-text-block-85 {
  padding-right: 10px;
  padding-left: 10px;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.af-view .af-class-div-block-281 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-text-block-86 {
  width: 100%;
  padding-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.af-view .af-class-lease-option {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-lease-option.af-class-button-style {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
}

.af-view .af-class-lease-option.af-class-button-style.af-class-margin-bottom {
  width: 100%;
  max-width: 400px;
  margin-bottom: 15px;
}

.af-view .af-class-lease-option.af-class-button-style.af-class-margin-bottom.af-class-selected {
  border-color: #018094;
  background-color: #fafafa;
}

.af-view .af-class-html-embed-24 {
  display: none;
}

.af-view .af-class-floorplan-card-content {
  width: 100%;
}

.af-view .af-class-div-block-283 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35%;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-short-term-list-wrapper {
  position: relative;
  width: 65%;
  padding: 5% 3% 5% 6%;
  background-color: #018094;
}

.af-view .af-class-div-block-285 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 17%;
  padding-top: 12%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-short-term-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 700px;
  padding-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-short-term-list-item {
  width: 45%;
  margin-right: 0px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dotted hsla(0, 0%, 100%, 0.5);
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}

.af-view .af-class-short-term-list-tagline {
  width: 100%;
  max-width: 450px;
  -webkit-transform: translate(0px, -120%);
  -ms-transform: translate(0px, -120%);
  transform: translate(0px, -120%);
  font-family: 'Outfit', sans-serif;
  color: #018094;
  font-size: 36px;
  line-height: 42px;
}

.af-view .af-class-text-block-88 {
  margin-bottom: 30px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.af-view .af-class-div-block-286 {
  position: static;
}

.af-view .af-class-image-41 {
  position: absolute;
  right: 100%;
  bottom: 0px;
  width: 100%;
  max-height: none;
  max-width: 600px;
}

.af-view .af-class-list-short-term-w-gallery {
  position: relative;
  width: 50vw;
}

.af-view .af-class-short-term-slider-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 100px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #018094;
}

.af-view .af-class-short-term-image-slider-container {
  position: absolute;
  left: 100%;
  top: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 50vw;
  height: 150%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-blog-card--tag {
  margin-bottom: 15px;
  padding: 4px 15px 5px;
  border-radius: 25px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-blog-card--tag.af-class-yellow {
  background-color: rgba(236, 217, 37, 0.2);
}

.af-view .af-class-blog-card--tag.af-class-yellow.af-class-small {
  font-size: 10px;
}

.af-view .af-class-blog-card--tag.af-class-blue {
  margin-right: 15px;
  background-color: rgba(123, 164, 218, 0.2);
}

.af-view .af-class-blog-card--featured {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 450px;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 6px;
  background-color: hsla(0, 0%, 100%, 0.5);
}

.af-view .af-class-blog-card--featured.af-class-blur {
  position: relative;
  right: 150px;
  bottom: 10%;
  background-color: hsla(0, 0%, 100%, 0.85);
}

.af-view .af-class-blog-card--publish-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-blog-card--featured-title {
  margin-top: 30px;
  font-size: 34px;
  line-height: 40px;
}

.af-view .af-class-blog-banner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 70vh;
  max-height: 800px;
  min-height: 500px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: url('../images/banner2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-text-block-89 {
  width: 100%;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px none #d2d2d2;
  font-family: 'Outfit', sans-serif;
  color: #727272;
}

.af-view .af-class-blog--search-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(241, 245, 251, 0.9);
}

.af-view .af-class-blog--search-bar.af-class-blur {
  position: relative;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.af-view .af-class-blog-search-bar-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1440px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-288 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-289 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 175px;
  margin-left: 15px;
  padding: 5px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #4c698f;
  border-radius: 20px;
}

.af-view .af-class-arrow-chevron-down {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.af-view .af-class-div-block-290 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-blog-search-bar--search-field {
  width: 150px;
  padding-left: 10px;
  border-bottom: 1px solid #4c698f;
}

.af-view .af-class-blog-posts {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-blog-posts-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.af-view .af-class-blog-post-cards-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  padding-right: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 720px;
  -ms-flex-preferred-size: 720px;
  flex-basis: 720px;
}

.af-view .af-class-blog-sidebar-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  padding-left: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 360px;
  -ms-flex-preferred-size: 360px;
  flex-basis: 360px;
}

.af-view .af-class-blog-card--w-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 720px;
  margin-bottom: 30px;
  padding: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  background-color: hsla(0, 0%, 100%, 0.5);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

.af-view .af-class-blog-card--w-image:hover, .af-view .af-class-blog-card--w-image:has(a:focus) {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
}

.af-view .af-class-blog-card--content-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
}

.af-view .af-class-blog-card-image-container {
  width: 30%;
  background-image: url('../images/blog-w-image-placeholder2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-author {
  width: 100%;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 700;
}

.af-view .af-class-blog-card--date {
  width: 100%;
  font-family: 'Outfit', sans-serif;
  color: #727272;
}

.af-view .af-class-blog-card--title {
  margin-top: 30px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 24px;
  line-height: 30px;
}

.af-view .af-class-div-block-297 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-blog-card--small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 360px;
  margin-bottom: 30px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  background-color: #fff;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

.af-view .af-class-blog-card--small:hover {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
}

.af-view .af-class-blog-card--small.af-class-related {
  max-width: 280px;
  margin-bottom: 0px;
}

.af-view .af-class-blog-card--small-title {
  width: 100%;
  margin-top: 30px;
  font-size: 18px;
  line-height: 24px;
}

.af-view .af-class-social-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 360px;
  margin-bottom: 30px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  background-color: #fff;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

.af-view .af-class-social-card:hover {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
}

.af-view .af-class-social-card--title {
  margin-left: 10px;
  font-family: 'Outfit', sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-social-card--description {
  margin-top: 15px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.af-view .af-class-social-card--icon {
  width: 30px;
}

.af-view .af-class-social-card-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.af-view .af-class-social-card--image-container {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url('../images/social-card-placeholder2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-social-card--content-container {
  padding: 15px;
}

.af-view .af-class-link-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
}

.af-view .af-class-blog-sidebar-section-heading {
  font-family: 'Outfit', sans-serif;
  padding-bottom: 0.25em;
}

h1.af-class-blog-sidebar-section-heading {
  color: #333;
  box-sizing: border-box;
  font-weight: bold;
  margin-bottom: 10px !important;
  font-size: 32px !important;
  line-height: 36px !important;
  margin-top: 20px !important;
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-blog-article-title {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 50px;
  font-family: 'Outfit', sans-serif;
  font-size: 40px;
  line-height: 48px;
}

.af-view .af-class-div-block-301 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  height: 100%;
  max-width: 500px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-302 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 8px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
}

.af-view .af-class-div-block-303 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-blog-article--social-icon {
  width: 30px;
  margin-left: 20px;
}

.af-view .af-class-blog-article-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 60vh;
  max-height: 600px;
  min-height: 400px;
}

.af-view .af-class-blog-article-header-image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 65%;
  background-image: url('../images/banner2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-blog-article-banner-content-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35%;
  padding-top: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 500px;
  -ms-flex: 1 0 500px;
  flex: 1 0 500px;
}

.af-view .af-class-back-arrow--long {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.af-view .af-class-blog-article-back-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-text-block-92 {
  margin-left: 10px;
}

.af-view .af-class-div-block-304 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-blog-article-publsih-date {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #d2d2d2;
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-blog-article-author {
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-article-share-heading {
  padding-right: 5px;
  font-family: 'Outfit', sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.af-view .af-class-blog-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-blog-content-container {
  width: 90%;
  max-width: 700px;
  padding-top: 75px;
  font-family: 'Open Sans', sans-serif;
}

.af-view .af-class-blog-article-content-header {
  margin-top: 50px;
  font-family: 'Outfit', sans-serif;
  font-size: 28px;
  line-height: 40px;
}

.af-view .af-class-image-42 {
  position: absolute;
  left: 0px;
  width: 140px;
  border-radius: 50%;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(-65%, 0px);
  -ms-transform: translate(-65%, 0px);
  transform: translate(-65%, 0px);
}

.af-view .af-class-article-author-box-title {
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 4px solid #ecd925;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-author-bio-block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 88%;
  padding: 40px 40px 40px 75px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 6px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.af-view .af-class-div-block-305 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 75px;
  padding-bottom: 75px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.af-view .af-class-blog-share-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
}

.af-view .af-class-blog-share-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1200px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-blog-related-posts {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-blog-related-posts-container {
  width: 90%;
  max-width: 1200px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.af-view .af-class-div-block-306 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-article-heading-author-prefix {
  padding-right: 5px;
  font-family: 'Outfit', sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  text-transform: none;
}

.af-view .af-class-signup-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.af-view .af-class-link-2 {
  padding: 9px 25px;
  border-radius: 3px;
  background-color: #eee;
  color: #727272;
  text-decoration: none;
}

.af-view .af-class-div-block-311 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-submit-button {
  padding: 12px 40px 17px;
  border-radius: 3px;
  background-color: #008d94;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
}

.af-view .af-class-submit-button.af-class-ada-blue {
  background-color: #3876c7;
}

.af-view .af-class-input-field {
  margin-top: 20px;
  padding-top: 21px;
  padding-bottom: 21px;
  border-radius: 3px;
}

.af-view .af-class-input-field::-webkit-input-placeholder {
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-input-field:-ms-input-placeholder {
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-input-field::-ms-input-placeholder {
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-input-field::placeholder {
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-create-profile-image {
  width: 100%;
  height: 100vh;
  max-width: 33%;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  background-image: url('../images/katie-treadway-171141-unsplash.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-div-block-313 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-div-block-314 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin-top: 30px;
  padding-top: 30px;
  padding-left: 30px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-form-block {
  width: 100%;
  max-width: 600px;
  padding-right: 15px;
  padding-left: 15px;
}

.af-view .af-class-heading-23 {
  font-family: 'Outfit', sans-serif;
  font-size: 28px;
  line-height: 34px;
}

.af-view .af-class-create-profile-home-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 400px;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-floorplan-card-profile {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 400px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 3px 3px 16px 0 rgba(0, 0, 0, 0.2);
}

.af-view .af-class-floorplan-card-profile.af-class-quote-summary {
  height: auto;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  box-shadow: none;
}

.af-view .af-class-floorplan-card-profile.af-class-quote-summary.af-class-available {
  height: auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.12);
}

.af-view .af-class-heading-24 {
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 26px;
}

.af-view .af-class-lozenge--expired {
  display: block;
  margin: 20px auto 30px;
  padding: 8px 15px;
  border-radius: 20px;
  background-color: #ffe5e5;
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-community-placard {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  margin-top: 30px;
  border-radius: 3px;
}

.af-view .af-class-community-placard.af-class-margin-bottom {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.af-view .af-class-div-block-317 {
  -webkit-flex-shrink: 2;
  -ms-flex-negative: 2;
  flex-shrink: 2;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  background-image: url('../images/community-placard2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-div-block-318 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 601%;
  -ms-flex-preferred-size: 601%;
  flex-basis: 601%;
  background-color: #fafafa;
}

.af-view .af-class-text-block-93 {
  margin-bottom: 5px;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.af-view .af-class-link-3 {
  display: block;
  margin-bottom: 5px;
  font-family: 'Outfit', sans-serif;
  color: #666667;
  line-height: 17px;
  text-decoration: none;
}

.af-view .af-class-link-4 {
  display: inline-block;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-floorplan-card-col-label {
  color: #727272;
  font-size: 11px;
  line-height: 14px;
}

.af-view .af-class-floorplan-card-bold-price {
  margin-top: -1px;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-floorplan-card-profile-col-label {
  text-align: center;
}

.af-view .af-class-div-block-319 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60vh;
  min-height: 600px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: url('../images/feedback-template-hero.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-div-block-320 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1440px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-321 {
  background-color: rgba(0, 0, 0, 0.85);
}

.af-view .af-class-div-block-321.af-class-blur {
  max-width: 450px;
  padding: 50px 50px 100px;
  background-color: rgba(0, 0, 0, 0.8);
}

.af-view .af-class-heading-25 {
  margin-bottom: 30px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
}

.af-view .af-class-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0px 0px 0px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.af-view .af-class-dropdown-toggle.af-class-border {
  border-bottom: 1px solid #3f3f3f;
}

.af-view .af-class-icon {
  position: relative;
  padding: 0px;
  background-color: #ecd925;
  font-size: 24px;
}

.af-view .af-class-icon.af-class-contact-page {
  background-color: rgba(236, 217, 37, 0);
}

.af-view .af-class-div-block-322 {
  padding: 15px 0px 15px 15px;
  background-color: #ecd925;
}

.af-view .af-class-div-block-322.af-class-contact-page {
  background-color: rgba(236, 217, 37, 0);
}

.af-view .af-class-text-block-94 {
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-dropdown-list-4 {
  top: 0px;
}

.af-view .af-class-dropdown-list-4.w--open {
  top: 54px;
  padding-left: 15px;
  border-top: 1px solid #d2d2d2;
  background-color: #fff;
}

.af-view .af-class-community-dropdown-link {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5px;
  border-bottom: 1px dotted #ccc;
  background-color: #fff;
}

.af-view .af-class-utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #3f3f3f;
}

.af-view .af-class-utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 600px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: center;
}

.af-view .af-class-heading-26 {
  margin-top: 0px;
  margin-bottom: 30px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 48px;
  line-height: 50px;
}

.af-view .af-class-text-block-95 {
  font-family: 'Outfit', sans-serif;
  color: #a0a0a0;
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  text-transform: none;
}

.af-view .af-class-image-43 {
  width: 110px;
}

.af-view .af-class-text-block-96 {
  padding-bottom: 10px;
  border-bottom: 1px solid #727272;
  font-family: 'Outfit', sans-serif;
  color: #fff;
}

.af-view .af-class-_404-link {
  border-bottom: 3px solid #8a8a8a;
  color: #fff;
  text-decoration: none;
}

.af-view .af-class-div-block-323 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.af-view .af-class-phase-1-banner-content {
  position: relative;
  height: 600px;
  max-height: 80vh;
}

.af-view .af-class-phase-1-banner-container {
  position: absolute;
  left: 100px;
  top: 45px;
  right: 100px;
  bottom: 0px;
  background-image: url('../images/maxresdefault.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-phase-1-header-content {
  position: relative;
  top: 50%;
  width: 100%;
  max-width: 500px;
  padding: 25px 50px 50px;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);
  -webkit-transform: translate(-10%, -50%);
  -ms-transform: translate(-10%, -50%);
  transform: translate(-10%, -50%);
}

.af-view .af-class-p2-header-title {
  margin-top: 10px;
  margin-bottom: 15px;
  font-family: 'Outfit', sans-serif;
  color: #018094;
  font-size: 32px;
  line-height: 44px;
}

.af-view .af-class-phase-1-breadcrumb-link {
  margin-right: 0px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  text-decoration: none;
}

.af-view .af-class-breadcrumb-container {
  display: inline-block;
  padding-bottom: 5px;
  -webkit-transform: translate(0px, -100%);
  -ms-transform: translate(0px, -100%);
  transform: translate(0px, -100%);
}

.af-view .af-class-breadcrumb-container.af-class-blur {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-breadcrumb-container.af-class-blur.af-class-light-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 5px 10px 7px;
  background-color: hsla(0, 0%, 100%, 0.64);
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.af-view .af-class-radiant-large {
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(76, 105, 143, 0.1);
}

.af-view .af-class-radiant-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
}

.af-view .af-class-radiant-medium {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(76, 105, 143, 0.2);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.af-view .af-class-radiant-small {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 3;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #4c698f;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.af-view .af-class-dev-phase-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 130px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-325 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75%;
  max-width: 800px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-phase-1-dev-phase {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f6f6f6;
}

.af-view .af-class-dotted-timline {
  position: absolute;
  left: 60px;
  top: 19px;
  right: 60px;
  z-index: 1;
  height: 1px;
  border-style: dotted;
  border-width: 1px;
  border-color: rgba(76, 105, 143, 0.25);
}

.af-view .af-class-dev-phase-label {
  margin-top: 5px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-dev-phase-date {
  font-family: 'Outfit', sans-serif;
  color: #727272;
}

.af-view .af-class-div-block-328 {
  height: 75px;
  background-color: #7ba4da;
}

.af-view .af-class-phase-1-nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1125px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-phase-1-nav-menu-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-div-block-331 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-right: 1px solid #d2d2d2;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 500;
}

.af-view .af-class-div-block-331.af-class-last-child {
  border-right-style: none;
}

.af-view .af-class-div-block-331.af-class-current {
  box-shadow: inset 0 10px 0 0 #ecd925;
}

.af-view .af-class-div-block-331.af-class-not-available {
  color: #6e6e6e;
  font-size: 13px;
  font-weight: 400;
}

.af-view .af-class-div-block-332 {
  width: 90%;
  max-width: 1440px;
}

.af-view .af-class-div-block-333 {
  width: 330px;
  padding: 30px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.af-view .af-class-property-location-teaser {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: url('../images/Sub_Region_Map_Google_Default.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-link-block-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Outfit', sans-serif;
  color: #6d92c3;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-phase-1-amenity-list-item {
  width: 45%;
  max-width: 250px;
  margin-bottom: 15px;
  font-family: 'Outfit', sans-serif;
  font-size: 13px;
  line-height: 18px;
}

.af-view .af-class-phase-1-amenity-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 550px;
  margin-top: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-334 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding: 50px 50px 50px 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 100%, 0)), color-stop(11%, #fff));
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff 11%);
}

.af-view .af-class-div-block-335 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.af-view .af-class-div-block-336 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: url('../images/girl-yoga.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-p1-impact-image-col {
  width: 50%;
  background-image: url('../images/AdobeStock_213579380_Preview.jpg');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-div-block-338 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.af-view .af-class-p1-sustainable-content-col {
  width: 55%;
}

.af-view .af-class-div-block-340 {
  width: 50%;
  padding: 20px;
}

.af-view .af-class-div-block-341 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-big-stat-number {
  font-family: 'Outfit', sans-serif;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
}

.af-view .af-class-div-block-342 {
  width: 75%;
  max-width: 400px;
  padding-bottom: 15px;
}

.af-view .af-class-div-block-343 {
  position: relative;
  width: 90%;
  max-width: 750px;
  padding: 40px;
}

.af-view .af-class-phase-1-sustainability {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.af-view .af-class-text-block-100 {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 700;
}

.af-view .af-class-unordered-list-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 550px;
  padding-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-phase-1-sustainable-list-item {
  width: 45%;
  padding-top: 20px;
  padding-right: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f6f6f6;
}

.af-view .af-class-p1-sustainable-content-container {
  position: relative;
  padding: 10% 40px 40px 15%;
}

.af-view .af-class-image-44 {
  position: absolute;
  left: 0px;
  top: 10%;
  max-width: 150px;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.af-view .af-class-p1-impact-content-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.af-view .af-class-p1-sustainable-image-col {
  width: 45%;
  margin-left: 30px;
  background-image: url('../images/chuttersnap-685131-unsplash.jpg');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-div-block-344 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.af-view .af-class-logo-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-div-block-345 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
}

.af-view .af-class-div-block-346 {
  width: 100%;
  background-color: #3f3f3f;
}

.af-view .af-class-div-block-346.af-class-loft {
  padding-bottom: 30px;
  background-color: #018094;
}

.af-view .af-class-div-block-347 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 95%;
  padding-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.af-view .af-class-text-block-101 {
  font-family: 'Outfit', sans-serif;
  font-size: 25px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
}

.af-view .af-class-retail-showcase-cta-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 20px;
  padding-right: 5%;
  padding-bottom: 25px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #3f3f3f;
  background-image: -webkit-gradient(linear, left top, right top, from(#292929), to(#3f3f3f));
  background-image: linear-gradient(90deg, #292929, #3f3f3f);
}

.af-view .af-class-retail-showcase-cta-row.af-class-loft {
  background-color: #018094;
  background-image: none;
}

.af-view .af-class-text-block-102 {
  padding-right: 20px;
  font-family: 'Outfit', sans-serif;
  color: hsla(0, 0%, 100%, 0.59);
  font-size: 25px;
  line-height: 36px;
  font-weight: 700;
}

.af-view .af-class-link-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 25px;
  line-height: 36px;
  font-weight: 700;
  text-decoration: none;
}

.af-view .af-class-content-retail-showcase {
  background-image: url('../images/Frisco-Crossing_007.jpg');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-div-block-351 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60vw;
  margin-left: auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-section-tagline-large {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
}

.af-view .af-class-section-tagline-large.af-class-white {
  max-width: 650px;
  padding-top: 10px;
  padding-right: 0px;
  color: #fff;
  font-size: 62px;
  line-height: 65px;
}

.af-view .af-class-div-block-352 {
  width: 100%;
  padding: 50px 70px 70px;
  background-color: #3f3f3f;
}

.af-view .af-class-div-block-352.af-class-loft {
  background-color: #018094;
}

.af-view .af-class-p1-retail-item {
  width: 50%;
  padding: 45px;
  background-color: transparent;
}

.af-view .af-class-p1-retail-item.af-class-no-top-padding {
  background-color: transparent;
}

.af-view .af-class-text-block-103 {
  margin-bottom: 15px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;
}

.af-view .af-class-p1-retail-item-description {
  font-family: 'Outfit', sans-serif;
  color: hsla(0, 0%, 100%, 0.61);
  font-weight: 300;
}

.af-view .af-class-div-block-354 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-div-block-355 {
  width: 100%;
}

.af-view .af-class-div-block-356 {
  width: 80%;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-left: 30px;
  background-color: #3f3f3f;
}

.af-view .af-class-div-block-356.af-class-loft {
  background-color: #018094;
}

.af-view .af-class-image-45 {
  width: 18px;
  height: 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.af-view .af-class-text-block-104 {
  padding-right: 12px;
}

.af-view .af-class-phase-1-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 90vh;
  max-height: 800px;
  min-height: 500px;
  padding-left: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/maxresdefault.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-phase-2-dev-phase {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f6f6f6;
}

.af-view .af-class-p2-header-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 500px;
  padding: 30px 50px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);
  -webkit-transform: translate(0px, -10%);
  -ms-transform: translate(0px, -10%);
  transform: translate(0px, -10%);
}

.af-view .af-class-breadcrumb-divider {
  position: relative;
  width: 2px;
  height: 17px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #727272;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}

.af-view .af-class-phase-2-breadcrumb-link {
  margin-right: 0px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  text-decoration: none;
}

.af-view .af-class-p2-header-content-link {
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  line-height: 16px;
}

.af-view .af-class-phase-2-progress-bar {
  position: absolute;
  left: 60px;
  top: 16px;
  right: 60%;
  z-index: 1;
  height: 1px;
  border: 4px solid #4c698f;
  border-radius: 20px;
}

.af-view .af-class-slider-gallery {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 700px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-slider-gallery-prev-arrow {
  position: absolute;
  left: 0px;
  top: 50%;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 65px;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-slider-gallery-prev-arrow-icon {
  width: 14px;
  margin-right: 20px;
}

.af-view .af-class-slider-gallery-next-arrow {
  position: absolute;
  top: 50%;
  right: 0px;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 125px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-slider-gallery-image-preview {
  position: absolute;
  top: 50%;
  width: 250px;
  height: 410px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-slider-gallery-image-preview.af-class-prev {
  left: 0px;
  width: 12%;
  background-image: -webkit-gradient(linear, right top, left top, from(hsla(0, 0%, 88.2%, 0)), to(#fff)), url('../images/example-bg.png');
  background-image: linear-gradient(270deg, hsla(0, 0%, 88.2%, 0), #fff), url('../images/example-bg.png');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, 125px;
}

.af-view .af-class-slider-gallery-image-preview.af-class-next {
  right: 0px;
  width: 12%;
  background-image: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 84.3%, 0)), to(#fff)), url('../images/example-bg.png');
  background-image: linear-gradient(90deg, hsla(0, 0%, 84.3%, 0), #fff), url('../images/example-bg.png');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, 125px;
}

.af-view .af-class-div-block-358 {
  position: relative;
  width: 70%;
  height: 80%;
  background-image: url('../images/example-bg.png');
  background-position: 0px 0px;
  background-size: 125px;
  box-shadow: 0 13px 50px 0 rgba(0, 0, 0, 0.19);
}

.af-view .af-class-slider-gallery-image-caption {
  padding: 7px 20px 11px;
  background-color: rgba(0, 0, 0, 0.9);
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.af-view .af-class-slider-gallery-image-caption.af-class-blur {
  position: absolute;
  left: 50%;
  bottom: 0px;
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 15px;
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  font-weight: 300;
}

.af-view .af-class-div-block-359 {
  width: 90%;
  max-width: 1440px;
}

.af-view .af-class-div-block-360 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-361 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 50px;
}

.af-view .af-class-div-block-362 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-image: url('../images/bike-program.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-div-block-363 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding-right: 50px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-list-item-6 {
  position: relative;
  margin-bottom: 0px;
  padding-bottom: 40px;
}

.af-view .af-class-div-block-365 {
  position: absolute;
  top: 0px;
  z-index: -1;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f6f6f6;
  -webkit-transform: translate(-25%, -25%);
  -ms-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
}

.af-view .af-class-text-block-105 {
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-div-block-366 {
  width: 90%;
  max-width: 500px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.af-view .af-class-unordered-list-9 {
  margin-top: 60px;
  padding-left: 20px;
}

.af-view .af-class-text-block-106 {
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
}

.af-view .af-class-text-block-107 {
  width: 100%;
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
}

.af-view .af-class-div-block-367 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48%;
  min-height: 230px;
  margin-bottom: 20px;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fff;
}

.af-view .af-class-div-block-368 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-div-block-369 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1440px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.af-view .af-class-div-block-370 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 500px;
  padding-top: 75px;
  padding-bottom: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.25))), url('../images/Bitmap.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('../images/Bitmap.jpg');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.af-view .af-class-div-block-371 {
  width: 40%;
}

.af-view .af-class-fullscreen-impact-tagline {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  text-shadow: 0 1px 16px rgba(0, 0, 0, 0.43);
}

.af-view .af-class-impact-factiod-statement {
  width: 100%;
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  font-size: 27px;
  line-height: 34px;
  font-weight: 700;
}

.af-view .af-class-div-block-372 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 75px 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/life-style-drinking-wine2x.jpg');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-newsletter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 40px;
}

.af-view .af-class-div-block-374 {
  padding: 10px 20px 16px;
  background-color: #ecd925;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-div-block-375 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 500px;
  padding-left: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
}

.af-view .af-class-text-block-108 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  color: #727272;
  font-weight: 700;
}

.af-view .af-class-div-block-376 {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-377 {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  padding-right: 50px;
  padding-bottom: 60px;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-div-block-378 {
  position: absolute;
  left: 29px;
  top: 29px;
  right: 29px;
  bottom: 29px;
  z-index: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
}

.af-view .af-class-div-block-378.af-class-blur {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.85)), to(rgba(0, 0, 0, 0.85)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85));
}

.af-view .af-class-heading-27 {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 28px;
  line-height: 42px;
}

.af-view .af-class-sticky-quote-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1440px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-text-block-110 {
  font-family: 'Outfit', sans-serif;
  color: #7a7a7a;
}

.af-view .af-class-sticky-bar-col-value {
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}

.af-view .af-class-sticky-bar-detail-col {
  width: 40%;
  padding-right: 30px;
  padding-left: 30px;
  border-right: 1px solid #d2d2d2;
}

.af-view .af-class-div-block-381 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 0px;
  padding-left: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-save-quote {
  width: auto;
  margin-left: 20px;
  padding-bottom: 15px;
  border-radius: 6px;
  background-color: #09c18a;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 25px;
}

.af-view .af-class-apply {
  margin-left: 10px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-left: 30px;
  border-radius: 6px;
  background-color: #008d94;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 25px;
}

.af-view .af-class-sticky-quote-summary-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: 1px solid #d2d2d2;
}

.af-view .af-class-payment-breakdown-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-payment-breakdown-wrap.af-class-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-payment-detail-content {
  width: 50%;
  max-width: 350px;
  margin-right: 40px;
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d2d2d2;
}

.af-view .af-class-payment-title {
  width: 100%;
  padding-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-div-block-383 {
  width: 100%;
  height: auto;
  max-width: 400px;
}

.af-view .af-class-div-block-383.af-class-no-border {
  border-bottom-style: none;
}

.af-view .af-class-div-block-384 {
  width: 45%;
  height: auto;
  padding-top: 10px;
  padding-right: 20px;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-div-block-384.af-class-full-width {
  width: 100%;
  padding-top: 0px;
}

.af-view .af-class-monthly-payment-details {
  width: 100%;
  max-width: 400px;
  padding-top: 20px;
  border-top: 1px solid #d2d2d2;
  border-bottom-style: none;
}

.af-view .af-class-lease-step-wrap {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 0px;
}

.af-view .af-class-lease-step-wrap.af-class-last-child {
  padding-bottom: 75px;
}

.af-view .af-class-lozenge-hide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 3px 20px 7px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #000;
  font-family: 'Outfit', sans-serif;
  color: #fff;
}

.af-view .af-class-arrow-down--chevron {
  position: relative;
  top: 4px;
  width: 16px;
  height: 20px;
  margin-left: 10px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.af-view .af-class-link--text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: 'Outfit', sans-serif;
  color: #018094;
  line-height: 20px;
  font-weight: 500;
}

.af-view .af-class-arrow-left--chevron {
  width: 13px;
  height: 20px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.af-view .af-class-arrow-left--chevron.af-class-black {
  width: 10px;
  height: 11px;
}

.af-view .af-class-text-block-112 {
  line-height: 17px;
}

.af-view .af-class-div-block-385 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 27px;
  height: 27px;
  padding: 3px 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #eaeaea;
}

.af-view .af-class-div-block-385.af-class-next {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.af-view .af-class-date-range-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 9px;
  padding-left: 9px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: 'Outfit', sans-serif;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-div-block-387 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 255px;
  padding-left: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.af-view .af-class-div-block-388 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 75vh;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.af-view .af-class-profile-sidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 30px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 30%;
  -webkit-flex: 30%;
  -ms-flex: 30%;
  flex: 30%;
  background-color: #fafafa;
}

.af-view .af-class-quote-details-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0px;
  padding: 60px 50px 60px 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-text-block-113 {
  width: 100%;
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
}

.af-view .af-class-div-block-391 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-div-block-392 {
  position: relative;
  width: 100%;
  margin-top: auto;
  padding: 25px 15px 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 3px;
  background-color: #fff;
}

.af-view .af-class-link-text {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-quote-summary-unti-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 40%;
  -webkit-flex: 40%;
  -ms-flex: 40%;
  flex: 40%;
}

.af-view .af-class-quote-summary-unti-col.af-class-margin-right {
  margin-left: 40px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.af-view .af-class-link-5 {
  display: inline-block;
  padding: 3px 9px 8px;
  border: 1px solid #008d94;
  border-radius: 6px;
  font-family: 'Outfit', sans-serif;
  color: #008d94;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}

.af-view .af-class-list-label {
  margin-bottom: -5px;
  font-family: 'Outfit', sans-serif;
  color: #aeaeae;
  font-size: 10px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-list-item-7 {
  margin-bottom: 20px;
  padding-right: 15px;
}

.af-view .af-class-quote-details-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 500px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-quote-summary-details-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 700px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 60%;
  -webkit-flex: 60%;
  -ms-flex: 60%;
  flex: 60%;
}

.af-view .af-class-quote-summary-apply-btn {
  position: relative;
  display: inline-block;
  padding: 10px 25px 12px;
  border-radius: 3px;
  background-color: #018094;
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  text-decoration: none;
}

.af-view .af-class-div-block-394 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-395 {
  width: 100%;
  max-width: none;
  padding-right: 0px;
}

.af-view .af-class-div-block-396 {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-div-block-397 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-full-screen-menu-background {
  position: fixed;
  left: 0px;
  top: 75px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  height: 100%;
  min-height: 95vh;
  padding: 6% 10%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: #2e2e2e;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8))),
    url('../images/Chicago_Sub_Region_Banner2x.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../images/Chicago_Sub_Region_Banner2x.jpg');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover;
}

.af-view .af-class-full-screen-menu-link {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 36px;
  line-height: 62px;
  font-weight: 700;
  text-decoration: none;
}

.af-view .af-class-full-screen-menu-link.af-class-sub-link {
  width: 30%;
  margin: 0px;
  color: #d3d3d3;
  font-size: 18px;
  line-height: 48px;
  font-weight: 500;
}

.af-view .af-class-full-screen-menu-item {
  width: auto;
  margin-bottom: 10px;
  padding-bottom: 20px;
  padding-left: 15px;
  border-bottom: 1px solid #3f3f3f;
}

.af-view .af-class-unordered-list-10 {
  width: 80%;
  max-width: 900px;
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.af-view .af-class-full-screen-sub-menu-item {
  width: 30%;
  max-width: 300px;
}

.af-view .af-class-unordered-list-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 11px;
  padding-left: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-398 {
  position: fixed;
  left: 0px;
  top: 90%;
  right: 0px;
  bottom: 0px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#000), to(transparent));
  background-image: linear-gradient(0deg, #000, transparent);
}

.af-view .af-class-slider-timeline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-slider-timeline-content-col {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
  padding-right: 39px;
  padding-bottom: 200px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-slider-timeline-image-col {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-basis: 280px;
  -ms-flex-preferred-size: 280px;
  flex-basis: 280px;
  background-image: url('../images/timeline-image-col.jpg');
  background-position: 0px 0px;
  background-size: 279px;
}

.af-view .af-class-timeline-header-wrap {
  width: 80%;
  padding-bottom: 20px;
  padding-left: 40px;
}

.af-view .af-class-timeline-track-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-timeline-track-card-w-image {
  position: relative;
  overflow: visible;
  width: 100%;
  max-width: 290px;
  margin-left: 40px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 3px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
}

.af-view .af-class-timeline-card-image-wrap {
  height: 165px;
  background-image: url('../images/example-bg.png');
  background-position: 0px 0px;
  background-size: 125px;
}

.af-view .af-class-timeline-card-tag {
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 2px 10px 4px;
  border: 1px solid #018094;
  border-radius: 3px;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
  font-size: 10px;
  text-transform: uppercase;
}

.af-view .af-class-timeline-card-content-wrap {
  padding: 20px;
  background-color: #fff;
}

.af-view .af-class-timeline-card-author-wrap {
  padding-top: 10px;
  border-top: 1px solid #d2d2d2;
}

.af-view .af-class-timeline-card-title {
  margin-bottom: 25px;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.af-view .af-class-timeline-track {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 15%;
}

.af-view .af-class-timeline-cards-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.af-view .af-class-timeline-navigation {
  position: absolute;
  top: -30px;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 115px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transform: translate(0px, -100%);
  -ms-transform: translate(0px, -100%);
  transform: translate(0px, -100%);
}

.af-view .af-class-timeline-arrow-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px 10px 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
}

.af-view .af-class-timeline-prev-arrow {
  position: relative;
  top: -5px;
  width: 12px;
  height: 25.109375px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.af-view .af-class-timeline-next-arrow {
  position: relative;
  left: 3px;
  width: 12px;
  height: 25.109375px;
}

.af-view .af-class-timeline-card-author {
  font-family: 'Outfit', sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.af-view .af-class-timeline-card-author.af-class-date {
  color: #727272;
}

.af-view .af-class-timeline-active-card-indicator {
  position: absolute;
  left: 50%;
  bottom: -34px;
  z-index: 100;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.af-view .af-class-timeline-active-indicator-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(76, 105, 143, 0.2);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.af-view .af-class-div-block-399 {
  position: absolute;
  width: 1px;
  height: 50px;
  background-color: #018094;
}

.af-view .af-class-text-block-114 {
  position: absolute;
  bottom: 0px;
  -webkit-transform: translate(-50%, 110%);
  -ms-transform: translate(-50%, 110%);
  transform: translate(-50%, 110%);
  font-family: 'Outfit', sans-serif;
  color: #018094;
  font-weight: 300;
}

.af-view .af-class-arrow--right {
  width: 20px;
}

.af-view .af-class-image-46 {
  width: 20px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.af-view .af-class-div-block-400 {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 40%;
  background-image: url('../images/sustainable-posts-bg2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-initiative-grid-heading-wrap {
  text-align: center;
}

.af-view .af-class-initiative-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-initiative-grid-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33%;
  min-height: 330px;
  padding: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.af-view .af-class-initiatives-grid-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin-top: 30px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-initiative-grid-image-block {
  width: 100%;
  background-image: url('../images/initiative-bg-template2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-initiative-grid-image-block.af-class-leed-bg {
  background-image: url('../images/LEED-Coin_Landscaping.jpg');
  background-size: 380px;
}

.af-view .af-class-initiative-grid-image-block.af-class-solar-bg {
  background-image: url('../images/stock-photo-solar-panels-in-the-city-602215058.jpg');
  background-size: 380px;
}

.af-view .af-class-initiative-grid-image-block.af-class-car-charging-bg {
  background-image: url('../images/DFElectricCarCharging_DSC0065.jpg');
  background-size: 380px;
}

.af-view .af-class-initiative-grid-image-block.af-class-water-faucet-bg {
  background-image: url('../images/jacek-dylag-1145288-unsplash.jpg');
  background-size: 380px;
}

.af-view .af-class-initiative-grid-image-block.af-class-humming-bird-bg {
  background-image: url('../images/john-duncan-1143242-unsplash.jpg');
  background-size: 380px;
}

.af-view .af-class-initiative-grid-image-block.af-class-plants-bg {
  background-image: url('../images/stock-photo-solar-panels-in-the-city-602215058-copy-5.jpg');
  background-size: 379px;
}

.af-view .af-class-initiative-descriptive-block {
  padding: 15px;
  background-image: url('../images/example-bg.png');
  background-position: 0px 0px;
  background-size: 125px;
}

.af-view .af-class-initiative-descriptive-block.af-class-highlight {
  width: 100%;
  padding: 20px;
  background-image: url('../images/initiative-bg-blurred-p-300.jpeg');
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: inset 0 5px 0 0 #f5a623;
}

.af-view .af-class-initiative-header-block {
  padding: 15px;
  background-image: url('../images/example-bg.png');
  background-position: 0px 0px;
  background-size: 125px;
}

.af-view .af-class-initiative-header-block.af-class-highlight {
  width: 100%;
  padding: 20px;
  background-image: url('../images/initiative-bg-blurred-p-300.jpeg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 5px 0 0 #f5a623;
}

.af-view .af-class-initiative-grid-big-heading {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 28px;
  line-height: 36px;
  text-transform: uppercase;
  text-shadow: 0 1px 10px rgba(0, 0, 0, 0.75);
}

.af-view .af-class-initiative-block-small-heading {
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 1px 8px rgba(0, 0, 0, 0.75);
}

.af-view .af-class-initiaive-block-fact {
  width: 100%;
  padding-left: 15px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  text-transform: uppercase;
}

.af-view .af-class-div-block-402 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-initiative-factoid-block {
  padding: 30px 30px 0px;
  background-color: #f6f6f6;
  background-image: url('../images/example-bg.png');
  background-position: 0px 0px;
  background-size: 125px;
}

.af-view .af-class-initiative-factoid-block.af-class-no-image {
  width: 100%;
  background-image: none;
}

.af-view .af-class-initiative-grid-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-activism-event-col {
  width: 100%;
  max-width: 400px;
  padding-right: 20px;
  padding-left: 20px;
}

.af-view .af-class-activism-event-col.af-class-first-child {
  max-width: 400px;
  padding-left: 0px;
}

.af-view .af-class-activism-event-col.af-class-last-child {
  padding-right: 0px;
}

.af-view .af-class-activisim-event-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.af-view .af-class-activism-event-heading {
  font-family: 'Outfit', sans-serif;
  color: #416917;
  text-transform: uppercase;
}

.af-view .af-class-activism-advocacy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-activism-advocacy-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-div-block-403 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}

.af-view .af-class-activism-event-description {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.af-view .af-class-activism-details-col {
  width: 70%;
  height: auto;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.af-view .af-class-div-block-404 {
  width: 30%;
  height: auto;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.af-view .af-class-activism-content-block-w-bg {
  position: relative;
  z-index: 0;
  padding: 40px 50px 40px 40px;
  background-color: #f6f6f6;
}

.af-view .af-class-div-block-405 {
  max-width: 800px;
  padding-top: 30px;
  padding-right: 50px;
  padding-left: 40px;
}

.af-view .af-class-div-block-406 {
  max-width: 800px;
  padding-top: 20px;
  padding-right: 75px;
  padding-left: 40px;
}

.af-view .af-class-activism-suub-heading {
  font-family: 'Outfit', sans-serif;
  font-size: 28px;
  line-height: 34px;
}

.af-view .af-class-activism-image-offset {
  position: relative;
  width: 100%;
  height: auto;
  -webkit-transform: translate(-10%, 10%);
  -ms-transform: translate(-10%, 10%);
  transform: translate(-10%, 10%);
}

.af-view .af-class-sustainable_billboard {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-sustainable-billboard-content-blur {
  position: relative;
  width: 60%;
  max-width: 540px;
  padding: 50px 50px 75px;
  background-color: rgba(0, 0, 0, 0.75);
}

.af-view .af-class-suatianble-bb-background {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  padding: 50px;
  background-image: url('../images/sustainable-spotlight2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-sustainable-bb-description {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 15px;
  line-height: 29px;
  font-weight: 300;
}

.af-view .af-class-div-block-407 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-div-block-408 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-home-guide-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-411 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 37px;
}

.af-view .af-class-div-block-412 {
  width: 100%;
  padding-right: 20px;
}

.af-view .af-class-div-block-413 {
  width: 40%;
  background-image: url('../images/mixed-couple2x.jpg');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-residents-details-col {
  width: 90%;
  padding-top: 20px;
  padding-right: 0px;
  padding-left: 40px;
}

.af-view .af-class-div-block-414 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.af-view .af-class-giving-back-green-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
}

.af-view .af-class-giving-back-green {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-giving-back-green-image {
  width: 60%;
  min-height: 400px;
  background-image: url('../images/giving-back-green2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-giving-back-green-content {
  width: 40%;
  padding: 30px;
}

.af-view .af-class-heading-28 {
  width: 100%;
  font-family: 'Outfit', sans-serif;
  color: #76b535;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
}

.af-view .af-class-industry-influence-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0px 0px 0px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  background-color: #fff;
}

.af-view .af-class-home-guide-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-div-block-415 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-div-block-416 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-div-block-417 {
  width: 50%;
  background-image: url('../images/Sub_Region_Map_Google_Default.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-div-block-418 {
  width: 50%;
}

.af-view .af-class-div-block-419 {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  background-color: #018094;
}

.af-view .af-class-property-contact-detail-col-label {
  font-family: 'Outfit', sans-serif;
  color: #018094;
  font-size: 12px;
  text-transform: uppercase;
}

.af-view .af-class-property-contact-detail-col-detail {
  font-family: 'Outfit', sans-serif;
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
}

.af-view .af-class-property-contact-detail-col {
  width: 100%;
}

.af-view .af-class-property-contact-detail-col.af-class-top-padding {
  padding-top: 30px;
}

.af-view .af-class-div-block-420 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.af-view .af-class-property-contact-details-wrap {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
}

.af-view .af-class-property-contact-details-content {
  width: 90%;
  max-width: 600px;
}

.af-view .af-class-div-block-421 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.af-view .af-class-property-contact-social-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  padding-right: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-image-49 {
  width: 30px;
}

.af-view .af-class-text-block-115 {
  padding-left: 10px;
}

.af-view .af-class-div-block-422 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-property-contact-address {
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
}

.af-view .af-class-map-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #3f3f3f;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.af-view .af-class-map-icon.af-class-perks {
  position: relative;
  left: 50%;
  top: 50%;
  background-color: #f5a623;
}

.af-view .af-class-locations-map-retail-modal {
  position: absolute;
  top: 0%;
  right: 66px;
  width: 250px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.25);
  -webkit-transform: translate(0px, -10%);
  -ms-transform: translate(0px, -10%);
  transform: translate(0px, -10%);
}

.af-view .af-class-locations-map-retail-modal-bottom-section {
  padding: 20px;
  border-top: 1px solid #d2d2d2;
}

.af-view .af-class-locations-map-retail-modal-top-section {
  padding: 40px 20px 20px;
}

.af-view .af-class-map-retail-modal-address {
  margin-top: 15px;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
}

.af-view .af-class-locations-modal-description {
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}

.af-view .af-class-text-block-116 {
  padding-right: 10px;
  color: #b47208;
  font-size: 16px;
  line-height: 17px;
  font-weight: 500;
}

.af-view .af-class-div-block-423 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-locations-mapmodal-chevron {
  width: 9px;
}

.af-view .af-class-div-block-424 {
  padding: 50px;
  background-image: url('../images/initiative-bg-blurred.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-div-block-425 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 700;
}

.af-view .af-class-div-block-426 {
  position: absolute;
  top: 10px;
  right: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #76757a;
}

.af-view .af-class-image-50 {
  display: inline-block;
  width: 12px;
}

.af-view .af-class-div-block-427 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
}

.af-view .af-class-heading-29 {
  font-family: 'Outfit', sans-serif;
  text-transform: uppercase;
}

.af-view .af-class-text-block-118 {
  margin-bottom: 10px;
  font-family: 'Outfit', sans-serif;
  line-height: 18px;
  font-weight: 500;
}

.af-view .af-class-text-block-119 {
  font-family: 'Outfit', sans-serif;
  color: #6991c6;
  font-weight: 700;
}

.af-view .af-class-text-block-120 {
  padding-left: 7px;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.af-view .af-class-div-block-428 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-contact--community-block {
  width: 30%;
  padding-right: 0px;
  padding-bottom: 40px;
  padding-left: 40px;
}

.af-view .af-class-div-block-429 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-bottom: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #d2d2d2;
}

.af-view .af-class-heading-30 {
  margin-bottom: 50px;
  font-family: 'Outfit', sans-serif;
  text-align: center;
}

.af-view .af-class-div-block-430 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-image-51 {
  width: 15px;
}

.af-view .af-class-text-block-121 {
  padding-right: 10px;
}

.af-view .af-class-div-block-431 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 49%;
  padding: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f6f6f6;
  text-align: center;
}

.af-view .af-class-text-block-122 {
  width: 100%;
  max-width: 600px;
  margin-bottom: 50px;
  font-family: 'Outfit', sans-serif;
  font-size: 24px;
  line-height: 31px;
  font-weight: 700;
}

.af-view .af-class-heading-31 {
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.af-view .af-class-div-block-432 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-div-block-433 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-image-52 {
  position: absolute;
  top: auto;
  bottom: 0px;
  width: 30px;
  height: 30px;
}

.af-view .af-class-property-modal-mobile {
  width: 545px;
  height: 200px;
  min-width: 390px;
  margin-top: 50px;
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
}

.af-view .af-class-property-modal-mobile-placard-brick {
  padding: 10px 15px;
}

.af-view .af-class-property-modal-mobile-placard-brick.af-class-prop-name {
  padding-right: 20px;
  padding-left: 20px;
  border-right: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}

.af-view .af-class-property-modal-mobile-placard-brick.af-class-prop-address {
  padding: 15px 20px;
  border-right: 1px solid #d8d8d8;
}

.af-view .af-class-property-modal-mobile-placard-brick.af-class-prop-cta {
  padding-top: 8px;
  padding-bottom: 10px;
  border-top: 1px solid #d8d8d8;
}

.af-view .af-class-property-modal-mobile-placard-brick.af-class-prop-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 20px 6px 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-property-modal-mobile-slide-wrap {
  position: relative;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 60%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
  background-image: url('../images/property-card-slide-default2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-image-2-copy {
  position: absolute;
  left: 50%;
  bottom: 10px;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.af-view .af-class-image-3-copy {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 25px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-image-3-copy-2 {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 25px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-image-3-copy-2.af-class-slide-right {
  position: absolute;
  left: auto;
  right: 10px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.af-view .af-class-image-54 {
  width: 15px;
}

.af-view .af-class-image-55 {
  width: 20px;
  margin-right: 10px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.af-view .af-class-text-block-125 {
  font-size: 20px;
}

.af-view .af-class-div-block-441 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 960px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.af-view .af-class-button-6 {
  padding: 7px 25px 11px;
  border-radius: 3px;
  line-height: 15px;
}

.af-view .af-class-image-56 {
  width: 8px;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  -webkit-transform: translate(0px, 2px);
  -ms-transform: translate(0px, 2px);
  transform: translate(0px, 2px);
}

.af-view .af-class-link-text-w-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.af-view .af-class-expired {
  color: #686868;
  font-style: italic;
  font-weight: 700;
}

.af-view .af-class-div-block-442 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: center;
}

.af-view .af-class-div-block-442.af-class-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-neighborhood-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fff;
  box-shadow: inset 0 -11px 0 0 #eaeaea;
}

.af-view .af-class-neighborhood-card-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 250px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.46)), to(rgba(0, 0, 0, 0.46))),
    url('../images/AdobeStock_213579380_Preview.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.46), rgba(0, 0, 0, 0.46)), url('../images/AdobeStock_213579380_Preview.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.af-view .af-class-neighborhood-card-bottom {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-neighborhood-card-title {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}

.af-view .af-class-neighborhood-modal {
  position: absolute;
  left: 25%;
  top: 34%;
  width: 360px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.4);
}

.af-view .af-class-neighbor-modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 125px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/example-bg.png');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/example-bg.png');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, 125px;
}

.af-view .af-class-neighbor-modal-cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 1px solid #d2d2d2;
  font-family: 'Outfit', sans-serif;
  color: #4c698f;
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
  text-decoration: none;
}

.af-view .af-class-neighbor-modal-listings {
  padding: 20px 20px 0px;
}

.af-view .af-class-text-block-126 {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}

.af-view .af-class-div-block-443 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.af-view .af-class-heading-32 {
  margin-bottom: 5px;
  color: #3f3f3f;
  font-size: 18px;
  line-height: 24px;
}

.af-view .af-class-link-block-7 {
  width: 100%;
  font-family: 'Outfit', sans-serif;
  text-decoration: none;
}

.af-view .af-class-text-block-127 {
  color: #008d94;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.af-view .af-class-text-block-128 {
  color: #4c698f;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-list-item-8 {
  padding-top: 5px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d8d8d8;
}

.af-view .af-class-list-item-8.af-class-last-child {
  border-bottom-style: none;
}

.af-view .af-class-image-57 {
  width: 12px;
  margin-bottom: -5px;
  margin-left: 10px;
}

.af-view .af-class-image-58 {
  position: absolute;
  top: 7px;
  right: 10px;
  width: 35px;
}

.af-view .af-class-link-w-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.5);
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-link-w-icon:hover {
  background-color: #7ba4da;
  color: #fff;
}

.af-view .af-class-link-w-icon.af-class-no-background {
  margin-left: 0px;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: none;
  font-size: 16px;
}

.af-view .af-class-link-w-icon.af-class-no-background.af-class-property-color {
  padding-left: 0px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  color: #aeaeae;
  font-weight: 700;
}

.af-view .af-class-link-w-icon.af-class-no-background.af-class-property-color:hover {
  text-decoration: none;
}

.af-view .af-class-link-w-icon.af-class-small-shadow {
  margin-left: 0px;
  padding-right: 15px;
  padding-left: 15px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.25);
}

.af-view .af-class-minion-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 120px;
  margin-left: 20px;
  padding: 10px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #7ba4da;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.5);
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-link-icon-svg {
  width: 20px;
}

.af-view .af-class-link-icon-svg.af-class-margin-right {
  margin-right: 8px;
}

.af-view .af-class-link-icon-svg.af-class-margin-left {
  margin-left: 10px;
}

.af-view .af-class-html-embed-25 {
  width: 15px;
}

.af-view .af-class-html-embed-25.af-class-previous {
  margin-bottom: -6px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.af-view .af-class-review-arrow-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 100%;
}

.af-view .af-class-review-arrow-wrap.af-class-next {
  height: 50px;
}

.af-view .af-class-review-arrow-wrap.af-class-prev {
  margin-right: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-444 {
  position: absolute;
  right: 50%;
  bottom: 10%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translate(50%, 0px);
  -ms-transform: translate(50%, 0px);
  transform: translate(50%, 0px);
}

.af-view .af-class-html-embed-26 {
  margin-top: -8px;
  margin-left: -5px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.af-view .af-class-html-embed-27 {
  margin-bottom: 0px;
}

.af-view .af-class-nav-top-menulink {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;
}

.af-view .af-class-nav-top-menulink:hover {
  color: #4c698f;
}

.af-view .af-class-nav-top-menu-link-wrap {
  margin-left: 20px;
}

.af-view .af-class-nav-dropdown-link:hover {
  background-color: #fafafa;
  box-shadow: inset 10px 0 0 0 #ecd925;
  color: #000;
}

.af-view .af-class-bottom-menu-dropdown-toggle {
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.af-view .af-class-bottom-menu-dropdown-toggle.w--open {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.af-view .af-class-icon-2 {
  right: -20px;
  margin-bottom: 10px;
}

.af-view .af-class-dropdown-toggle-3 {
  padding: 5px 20px 10px 10px;
}

.af-view .af-class-dropdown-toggle-3.w--open {
  padding-right: 20px;
}

.af-view .af-class-top-nav-menu-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-bottom-nav-menu-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-property-contact-cta {
  padding: 40px;
}

.af-view .af-class-div-block-446 {
  background-image: url('../images/life-style-drinking-wine2x.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-div-block-447 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 100%;
  min-width: 800px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-dropdown-3 {
  width: 100%;
}

.af-view .af-class-icon-4 {
  color: #fffdfd;
  font-size: 34px;
  line-height: 30px;
}

.af-view .af-class-dropdown-toggle-5 {
  width: 100%;
  padding: 30px 0px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.21);
}

.af-view .af-class-dropdown-list-6 {
  position: relative;
}

.af-view .af-class-dropdown-list-6.w--open {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 500px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: transparent;
}

.af-view .af-class-property-menu-items {
  display: none;
}

.af-view .af-class-full-sreen-main-link {
  margin-top: 30px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 36px;
  line-height: 62px;
  font-weight: 700;
  text-decoration: none;
}

.af-view .af-class-seo-title {
  display: block;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  text-transform: uppercase;
}

.af-view .af-class-text-block-130 {
  display: inline-block;
  margin-right: 8px;
  font-family: 'Outfit', sans-serif;
  color: #018094;
  font-size: 19px;
  font-weight: 500;
}

.af-view .af-class-link-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-decoration: none;
}

.af-view .af-class-html-embed-28 {
  display: none;
}

.af-view .af-class-div-block-448 {
  background-color: #993030;
}

.af-view .af-class-cards-extending-blocks-large-image {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  padding-top: 60px;
  padding-bottom: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-449 {
  position: absolute;
  left: 0px;
  top: 0%;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-image-59 {
  position: relative;
  bottom: 0px;
  width: 100%;
  height: 100%;
  margin-top: 10%;
}

.af-view .af-class-div-block-450 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-div-block-451 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 50%;
  -ms-flex: 0 50%;
  flex: 0 50%;
}

.af-view .af-class-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-452 {
  width: 90%;
  max-width: 1200px;
}

.af-view .af-class-heading-33 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  text-align: center;
}

.af-view .af-class-link-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 200px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e7e4e4;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: 'Outfit', sans-serif;
  color: #043f49;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  text-decoration: none;
}

.af-view .af-class-link-block-9:hover {
  background-color: #3f3f3f;
  color: #fff;
}

.af-view .af-class-div-block-453 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-div-block-454 {
  padding: 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-field-field-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding: 13px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-field-field-wrapper.af-class-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-field-field-wrapper.af-class-full-width {
  width: 100%;
}

.af-view .af-class-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.af-view .af-class-div-block-455 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-input-label {
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.af-view .af-class-input-label.af-class-horizontal {
  padding-right: 50px;
}

.af-view .af-class-text-field {
  border-bottom: 1px solid #3f3f3f;
}

.af-view .af-class-section-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.af-view .af-class-div-block-456 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  max-width: 1300px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.af-view .af-class-link-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 300px;
  margin-top: 1px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url('../images/example-bg.png');
  background-position: 50% 50%;
  background-size: cover;
  font-family: 'Outfit', sans-serif;
  color: #3f3f3f;
  text-decoration: none;
}

.af-view .af-class-link-block-10.af-class-no-img-bg {
  background-color: #fff;
  background-image: none;
}

.af-view .af-class-link-block-10.af-class-factiod {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  background-color: #f3f3f3;
  background-image: none;
}

.af-view .af-class-social-grid-account-handle {
  font-weight: 700;
}

.af-view .af-class-social-grid-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.af-view .af-class-div-block-457 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-div-block-458 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  padding: 5px;
}

.af-view .af-class-social-grid-post-description {
  font-size: 12px;
  line-height: 18px;
}

.af-view .af-class-social-grid-post-description.af-class-large {
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
}

.af-view .af-class-div-block-459 {
  background-color: hsla(0, 0%, 100%, 0.75);
}

.af-view .af-class-div-block-459.af-class-blur {
  padding: 12px;
}

.af-view .af-class-div-block-459.af-class-blur.af-class-cover {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.af-view .af-class-social-grid-factoid-title {
  width: 100%;
  font-size: 26px;
  line-height: 36px;
}

.af-view .af-class-div-block-460 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-image-60 {
  height: 15px;
}

.af-view .af-class-text-block-131 {
  padding-right: 10px;
}

.af-view .af-class-social-grid-program-title {
  font-size: 26px;
  line-height: 38px;
  font-weight: 700;
}

.af-view .af-class-div-block-461 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-div-block-462 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  padding-right: 20px;
  padding-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-button-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  padding: 10px 20px 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #3874c7;
  font-family: 'Outfit', sans-serif;
  line-height: 1em;
}

.af-view .af-class-utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.af-view .af-class-section-3 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #2e2e2e;
}

.af-view .af-class-section-3.af-class-blur {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: rgba(46, 46, 46, 0.85);
}

.af-view .af-class-div-block-463 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  max-width: 900px;
  padding: 0%;
  background-color: #fff;
  box-shadow: 1px 1px 50px 0 rgba(0, 0, 0, 0.25);
}

.af-view .af-class-div-block-464 {
  padding-right: 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 50%;
  -ms-flex: 0 50%;
  flex: 0 50%;
}

.af-view .af-class-div-block-465 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 50%;
  -ms-flex: 0 50%;
  flex: 0 50%;
}

.af-view .af-class-div-block-466 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  padding: 10px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border: 1px solid #c6dbe6;
  border-radius: 6px;
  background-color: #fff;
}

.af-view .af-class-div-block-466.af-class-selected {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-style: none;
  background-color: #09c18a;
}

.af-view .af-class-div-block-466.af-class-no-margin {
  margin-bottom: 0px;
}

.af-view .af-class-image-61 {
  width: 100%;
}

.af-view .af-class-div-block-467 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-text-block-132 {
  font-family: 'Outfit', sans-serif;
  line-height: 14px;
}

.af-view .af-class-text-block-132.af-class-selected {
  color: #fff;
}

.af-view .af-class-div-block-468 {
  width: 15px;
  margin-right: 10px;
}

.af-view .af-class-content-heading {
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.af-view .af-class-div-block-469 {
  margin-top: 30px;
}

.af-view .af-class-div-block-469.af-class-margin-bottom-small {
  margin-top: 15px;
  margin-bottom: 30px;
}

.af-view .af-class-link-block-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.af-view .af-class-image-62 {
  width: 15px;
  margin-right: 5px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.af-view .af-class-text-block-133 {
  font-family: 'Outfit', sans-serif;
  color: #383838;
  line-height: 20px;
  text-decoration: none;
}

.af-view .af-class-div-block-470 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-close-modal {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  padding: 3px 10px 7px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 3px;
  background-color: #f0f0f0;
  -webkit-transform: translate(0%, 60%);
  -ms-transform: translate(0%, 60%);
  transform: translate(0%, 60%);
  text-decoration: none;
}

.af-view .af-class-div-block-471 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10% 8% 8%;
}

.af-view .af-class-heading-34 {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'Outfit', sans-serif;
  font-size: 32px;
  line-height: 40px;
}

.af-view .af-class-submit-button-2 {
  padding-right: 30px;
  padding-left: 30px;
}

.af-view .af-class-text-area::-webkit-input-placeholder {
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-text-area:-ms-input-placeholder {
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-text-area::-ms-input-placeholder {
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-text-area::placeholder {
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-image-63 {
  width: 30px;
  height: 100%;
}

.af-view .af-class-text-block-134 {
  margin-top: 0px;
  padding-right: 15px;
  line-height: 18px;
}

.af-view .af-class-div-block-472 {
  width: 100%;
}

.af-view .af-class-image-64 {
  width: 100%;
  height: 100%;
}

.af-view .af-class-image-65 {
  width: 100%;
  height: auto;
}

.af-view .af-class-image-66 {
  width: 100%;
  height: 100%;
}

.af-view .af-class-image-67 {
  width: 100%;
  height: auto;
}

.af-view .af-class-div-block-473 {
  width: 100%;
}

.af-view .af-class-image-68 {
  width: 100%;
}

.af-view .af-class-html-embed-29 {
  width: 13px;
  height: 20px;
}

.af-view .af-class-image-69 {
  width: 100%;
}

.af-view .af-class-div-block-474 {
  width: 50%;
  max-width: 400px;
  margin-right: auto;
  padding-right: 20px;
}

.af-view .af-class-div-block-474.af-class-padding-right {
  margin-right: auto;
  padding-right: 20px;
}

.af-view .af-class-div-block-475 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
}

.af-view .af-class-div-block-477 {
  display: none;
}

.af-view .af-class-social-grid-yellow-hightlight {
  position: relative;
  width: 100%;
  background-color: #ecd925;
  line-height: 50px;
}

.af-view .af-class-text-block-135 {
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-text-block-135.af-class-small {
  font-size: 13px;
}

.af-view .af-class-text-block-136 {
  font-family: 'Outfit', sans-serif;
}

.af-view .af-class-floorplan-card-preview {
  width: 100%;
}

.af-view .af-class-card-details-cta-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #d2d2d2;
}

.af-view .af-class-card-details-cta-row.af-class-padded {
  padding: 10px;
}

.af-view .af-class-card-details-cta-row.af-class-padded.af-class-sides-padded {
  padding: 15px;
}

.af-view .af-class-card-details-cta-row.af-class-padded--large {
  padding: 15px 0px;
}

.af-view .af-class-card-details-cta-row.af-class-padded-xsmall {
  padding: 5px;
}

.af-view .af-class-image-title-subtitle-cta {
  display: block;
  height: auto;
  min-height: 0vh;
  padding-top: 2%;
  padding-bottom: 2%;
}

.af-view .af-class-image-title-subtitle-cta-content {
  display: inline-block;
  padding: 3%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
}

.af-view .af-class-heading-xxl {
  margin-bottom: 40px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-size: 91px;
  line-height: 100px;
}

.af-view .af-class-nav-link-chevron.af-class-translation-chveron {
  -webkit-transform: translate(20px, 0px);
  -ms-transform: translate(20px, 0px);
  transform: translate(20px, 0px);
}

.af-view .af-class-list-tall-image-mobile {
  display: none;
}

.af-view .af-class-image-70 {
  width: 100%;
}

.af-view .af-class-html-embed-31 {
  display: none;
}

@media (max-width: 991px) {
  .af-view .af-class-wrapper.af-class-cards_news {
    background-position: 34% 50%;
    background-size: cover;
  }

  .af-view .af-class-wrapper.af-class-list_related_areas {
    padding-right: 40px;
    padding-left: 40px;
  }

  .af-view .af-class-container.af-class-image-background.af-class-full-width {
    position: relative;
    padding-top: 40%;
    padding-right: 0%;
    padding-bottom: 0px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-container.af-class-image-background.af-class-full-width.af-class-min-height {
    padding-top: 0%;
  }

  .af-view .af-class-container.af-class-footer.af-class-flex {
    padding-right: 50px;
    padding-left: 50px;
  }

  .af-view .af-class-home-intro-content {
    padding-right: 4%;
    padding-bottom: 5%;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-home-intro-images {
    background-size: cover;
  }

  .af-view .af-class-paragraph-text {
    font-size: 14px;
    line-height: 24px;
  }

  .af-view .af-class-amli-tag-thin {
    font-size: 42px;
    line-height: 42px;
  }

  .af-view .af-class-amli-tag-thick {
    font-size: 42px;
    line-height: 42px;
  }

  .af-view .af-class-button.af-class-boss.af-class-yellow {
    margin-top: 20px;
  }

  .af-view .af-class-home-intro-image.af-class-left-offset {
    -webkit-transform: translate(-20%, 50%);
    -ms-transform: translate(-20%, 50%);
    transform: translate(-20%, 50%);
  }

  .af-view .af-class-home-intro-image.af-class-right-offset {
    -webkit-transform: translate(90%, 50%);
    -ms-transform: translate(90%, 50%);
    transform: translate(90%, 50%);
  }

  .af-view .af-class-section-title {
    margin-bottom: 0px;
  }

  .af-view .af-class-section-tagline {
    margin-top: 0px;
    font-size: 32px;
    line-height: 38px;
  }

  .af-view .af-class-section-tagline.af-class-small {
    font-size: 32px;
    line-height: 38px;
  }

  .af-view .af-class-div-block-2 {
    padding-bottom: 50px;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-two-thirds-column {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    background-image: url('../images/home--sustainability-property.jpg');
    background-position: 0px 0px;
    background-size: cover;
  }

  .af-view .af-class-hide-mobile {
    display: none;
  }

  .af-view .af-class-inner-content-container.af-class-home-careers {
    position: relative;
    width: 100vw;
    max-width: 100%;
    padding-top: 3%;
  }

  .af-view .af-class-heading {
    font-size: 20px;
    line-height: 29px;
  }

  .af-view .af-class-footer-top {
    margin-bottom: 4%;
    padding-bottom: 4%;
  }

  .af-view .af-class-footer-text-link {
    margin-bottom: 5px;
    margin-left: 20px;
    font-size: 18px;
    font-weight: 300;
  }

  .af-view .af-class-footer-text-link.af-class-legal {
    color: hsla(0, 0%, 100%, 0.4);
    font-size: 13px;
  }

  .af-view .af-class-social-icon {
    max-height: 25px;
    margin-left: 31px;
  }

  .af-view .af-class-footer-text {
    color: hsla(0, 0%, 100%, 0.4);
  }

  .af-view .af-class-footer-equal-housing-logo {
    opacity: 0.4;
  }

  .af-view .af-class-real-estate-column-container {
    width: 100%;
    padding-top: 0px;
    background-color: #d2d2d2;
  }

  .af-view .af-class-map-container {
    height: 90vh;
    max-height: 100vh;
    min-height: 700px;
    padding-left: 0px;
    -webkit-flex-shrink: 2;
    -ms-flex-negative: 2;
    flex-shrink: 2;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    background-image: none;
  }

  .af-view .af-class-toggle-active {
    height: 48px;
    padding: 5px 0px 0px 10px;
  }

  .af-view .af-class-toggle-active-label {
    font-size: 13px;
  }

  .af-view .af-class-toggle-container {
    height: 41px;
  }

  .af-view .af-class-toggle-inactive-icon {
    max-height: 32px;
  }

  .af-view .af-class-toggle-inactive {
    padding-top: 3px;
    padding-right: 10px;
    padding-left: 14px;
  }

  .af-view .af-class-toggle-inactive-label {
    font-size: 13px;
  }

  .af-view .af-class-price-filter-container {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .af-view .af-class-price-filter-container.af-class-last-child {
    padding-right: 30px;
  }

  .af-view .af-class-border {
    width: 2px;
  }

  .af-view .af-class-filter-option {
    font-size: 13px;
  }

  .af-view .af-class-property-card {
    padding-bottom: 12px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-property-card.af-class-icon-wrapper {
    display: none;
    padding-right: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-div-block-5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-div-block-6 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-property-card-left-border {
    width: 0px;
  }

  .af-view .af-class-property-card-slide-default {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 2 100%;
    -ms-flex: 0 2 100%;
    flex: 0 2 100%;
  }

  .af-view .af-class-property-card-info-wrapper {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 2 100%;
    -ms-flex: 0 2 100%;
    flex: 0 2 100%;
  }

  .af-view .af-class-property-card-title {
    font-size: 16px;
    line-height: 24px;
  }

  .af-view .af-class-property-card-title-wrapper {
    position: relative;
    margin-left: 9px;
    padding: 5px 9px 8px 0px;
  }

  .af-view .af-class-property-card-address {
    display: none;
    margin-right: 30px;
    font-size: 13px;
    line-height: 15px;
  }

  .af-view .af-class-property-card-phone {
    display: inline-block;
    color: #727272;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
  }

  .af-view .af-class-property-card-bedroom {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    font-size: 14px;
    line-height: 16px;
  }

  .af-view .af-class-property-card-cta-label {
    font-size: 16px;
    line-height: 16px;
  }

  .af-view .af-class-property-card-cta-arrow {
    display: none;
    padding-left: 7px;
  }

  .af-view .af-class-property-card-icon {
    max-height: 26px;
  }

  .af-view .af-class-property-card-info-wtrapper {
    margin-left: 0px;
    padding-top: 13px;
    padding-bottom: 11px;
    padding-left: 12px;
  }

  .af-view .af-class-real-estate-column {
    max-height: 100vh;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 45%;
    -ms-flex: 1 0 45%;
    flex: 1 0 45%;
    border-right: 1px solid #b5b5b5;
    box-shadow: 1px 0 10px 0 rgba(0, 0, 0, 0.25);
  }

  .af-view .af-class-image-2 {
    bottom: 3px;
    display: none;
    width: 100px;
  }

  .af-view .af-class-image-3 {
    display: none;
  }

  .af-view .af-class-image-3.af-class-slide-right {
    right: 20px;
    display: none;
  }

  .af-view .af-class-lozenge--specials {
    position: absolute;
    top: 0px;
    right: 0px;
    display: inline-block;
    width: 100%;
    padding: 2px 10px 7px;
    border-radius: 0px;
    background-color: #06845e;
    font-family: 'Outfit', sans-serif;
    color: #fff;
  }

  .af-view .af-class-lozenge--specials.af-class-quote-summary {
    width: auto;
    border-radius: 20px;
  }

  .af-view .af-class-text-block {
    display: inline;
    padding-right: 0px;
    font-size: 13px;
  }

  .af-view .af-class-icon-fire {
    margin-top: -5px;
  }

  .af-view .af-class-image-5 {
    width: 45px;
  }

  .af-view .af-class-text-block-2 {
    line-height: 16px;
  }

  .af-view .af-class-lozenge--neighborhood {
    left: 20%;
    width: 210px;
  }

  .af-view .af-class-map--neighborhood-label-wrapper {
    padding-left: 10px;
  }

  .af-view .af-class-heading-2 {
    left: 10px;
    top: 10px;
    display: inline-block;
    margin-top: 0px;
    margin-left: 0px;
    font-family: 'Outfit', sans-serif;
    font-size: 48px;
    line-height: 48px;
  }

  .af-view .af-class-map {
    width: 45px;
  }

  .af-view .af-class-map-zoom-controls-wrapper {
    bottom: 76px;
    width: 65px;
  }

  .af-view .af-class-content_intro_image_title {
    font-size: 45px;
  }

  .af-view .af-class-point_blurb {
    font-size: 14px;
    line-height: 24px;
  }

  .af-view .af-class-slider-poi-content-wrapper {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-slider_poi_image.af-class-current {
    -webkit-flex-basis: 80%;
    -ms-flex-preferred-size: 80%;
    flex-basis: 80%;
  }

  .af-view .af-class-content-image-offset-paragraph {
    font-size: 14px;
    line-height: 24px;
  }

  .af-view .af-class-content_image_offset_cards {
    -webkit-transform: translate(0px, -20%);
    -ms-transform: translate(0px, -20%);
    transform: translate(0px, -20%);
  }

  .af-view .af-class-poi_card {
    width: 30%;
  }

  .af-view .af-class-poi_card_description {
    font-size: 14px;
    line-height: 24px;
  }

  .af-view .af-class-news-card-image {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .af-view .af-class-news_card {
    width: 48%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-paragraph {
    height: auto;
  }

  .af-view .af-class-paragraph.af-class-cards-testimonial {
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    border-radius: 0px;
    font-size: 14px;
    line-height: 25px;
  }

  .af-view .af-class-news-card-content {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-cards-news-header {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-div-block-15 {
    width: 100%;
    margin-top: 50px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-related-areas-locations-wrapper {
    margin-top: 50px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-related-areas-location-column {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-related-areas-location-column.af-class-zipcodes {
    width: 100%;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-related-areas-location-column.af-class-hoods {
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    border-right-color: hsla(0, 0%, 100%, 0.2);
    border-left-color: hsla(0, 0%, 100%, 0.2);
  }

  .af-view .af-class-related-areas-location-column.af-class-cities {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-related-areas-location-title {
    width: 100%;
    clear: none;
    text-align: center;
  }

  .af-view .af-class-city-text-link {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }

  .af-view .af-class-zipcode-text-link {
    width: 100%;
    max-width: 140px;
  }

  .af-view .af-class-div-block-16 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-17 {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-content_intro_billboard_title {
    font-size: 42px;
    line-height: 53px;
  }

  .af-view .af-class-div-block-18 {
    height: auto;
    min-height: 40vh;
  }

  .af-view .af-class-collage-poi-framed-image {
    margin: 20px 20px 15px;
  }

  .af-view .af-class-div-block-25 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-cards_points_of_interest {
    padding-right: 5%;
    padding-left: 5%;
    -webkit-transform: translate(0px, 0%);
    -ms-transform: translate(0px, 0%);
    transform: translate(0px, 0%);
  }

  .af-view .af-class-spotlight_billboard_descriptive {
    padding: 40px;
  }

  .af-view .af-class-spotlight-bb-descriptive-background {
    padding: 40px;
  }

  .af-view .af-class-spotlight-bb-background-offset {
    bottom: -15%;
    width: 30%;
    height: 70%;
  }

  .af-view .af-class-spotlight-billboard-descriptive-blur {
    width: 65%;
    max-width: 480px;
  }

  .af-view .af-class-related-areas-band {
    height: auto;
  }

  .af-view .af-class-related-area-band-column {
    max-height: 100%;
    min-height: 0vh;
  }

  .af-view .af-class-related-area-band-column.af-class-evanston {
    min-height: 0vh;
  }

  .af-view .af-class-related-area-band-column.af-class-deerfield {
    min-height: 0vh;
  }

  .af-view .af-class-div-block-26 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-27 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-about-header {
    max-height: 600px;
    min-height: 500px;
  }

  .af-view .af-class-about-header-blur-wrapper {
    left: 50%;
    border-radius: 0px;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
  }

  .af-view .af-class-about-header-title {
    font-size: 36px;
    line-height: 44px;
  }

  .af-view .af-class-list_detailed {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-29 {
    width: 100%;
    max-width: none;
    padding-right: 0px;
    padding-bottom: 130px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-div-block-30 {
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -20%);
    -ms-transform: translate(-50%, -20%);
    transform: translate(-50%, -20%);
  }

  .af-view .af-class-list-detailed-image-column {
    width: 100%;
  }

  .af-view .af-class-list-detailed-image-content {
    max-width: none;
    padding-right: 50px;
    padding-left: 50px;
  }

  .af-view .af-class-image-6 {
    width: 50px;
    margin-right: 15px;
  }

  .af-view .af-class-list-detailed-lists-column {
    padding-top: 0px;
    padding-left: 30px;
  }

  .af-view .af-class-heading-7 {
    font-size: 32px;
  }

  .af-view .af-class-list-detailed-list-content {
    max-width: 100%;
  }

  .af-view .af-class-list-blocks-with-text-blurb {
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-list-blocks-blurb-content {
    width: 100%;
    padding: 40px 30px 40px 60px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-list-blocks-blurb-logos {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-list-blocks-blurb-column {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .af-view .af-class-list-blocks-blurb-column.af-class-awards {
    height: auto;
    max-width: 50%;
    padding: 40px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-list-blocks-b-content-container {
    width: 100%;
    height: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-cards-news-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-real-estate-sticky-menu {
    width: 100%;
    background-color: transparent;
  }

  .af-view .af-class-div-block-34 {
    padding: 18px 15px 1px;
    background-color: #3f3f3f;
  }

  .af-view .af-class-slider-story-header {
    padding: 50px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .af-view .af-class-slider-story-slide-info {
    padding-right: 50px;
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .af-view .af-class-slider-story-slide-info-container {
    width: 100%;
    padding-left: 50px;
  }

  .af-view .af-class-slider-story-header-container {
    width: 100%;
  }

  .af-view .af-class-page-header.af-class-career-overview {
    max-height: 600px;
  }

  .af-view .af-class-page-header.af-class-culture {
    max-height: 600px;
  }

  .af-view .af-class-page-header.af-class-benefits {
    max-height: 600px;
  }

  .af-view .af-class-page-header.af-class-pro-dev {
    max-height: 600px;
    background-image: url('../images/pro-dev-banner2x.jpg');
  }

  .af-view .af-class-page-header-content.af-class-dark {
    width: 90%;
    padding: 30px 29px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }

  .af-view .af-class-career-page-heading {
    max-width: 400px;
    font-size: 48px;
  }

  .af-view .af-class-career-page-heading.af-class-career {
    font-size: 40px;
  }

  .af-view .af-class-career-page-heading.af-class-pro-dev {
    max-width: none;
    font-size: 36px;
    line-height: 48px;
  }

  .af-view .af-class-content-intro-descriptive {
    padding-top: 40px;
  }

  .af-view .af-class-content-intro-descriptive-title.af-class-centered {
    font-size: 28px;
    line-height: 42px;
  }

  .af-view .af-class-spotlight-text-image {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .af-view .af-class-list-compact {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-list-compact-list-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .af-view .af-class-list-compact-list-item {
    font-size: 16px;
  }

  .af-view .af-class-div-block-41 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-43 {
    top: 40px;
    bottom: 0px;
    background-position: 50% 0px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .af-view .af-class-spotlight-bb-list-item {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
  }

  .af-view .af-class-spotlight-bb-list-benefit-list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-cards-testimonial {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-cards-testimonial-column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-cards-testimonial-card {
    max-width: 480px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 0px;
  }

  .af-view .af-class-cards-testimonial-employee-info {
    position: absolute;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-transform: translate(0px, 60px);
    -ms-transform: translate(0px, 60px);
    transform: translate(0px, 60px);
  }

  .af-view .af-class-cards-testimonial-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-spotlight-career {
    padding: 5%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-about-page-header.af-class-development {
    max-height: 600px;
  }

  .af-view .af-class-about-page-header.af-class-leadership {
    max-height: 400px;
  }

  .af-view .af-class-heading-13 {
    font-size: 72px;
    line-height: 100px;
  }

  .af-view .af-class-slider_property_content_wrapper {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-slider-property-image.af-class-current {
    margin-right: 20px;
    -webkit-box-flex: 5;
    -webkit-flex-grow: 5;
    -ms-flex-positive: 5;
    flex-grow: 5;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-slider-property-image.af-class-next-slide {
    -webkit-box-flex: 0;
    -webkit-flex: 0 4 10%;
    -ms-flex: 0 4 10%;
    flex: 0 4 10%;
  }

  .af-view .af-class-slider-property-headers {
    margin-top: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-slider-property-title {
    margin-top: 0px;
    font-size: 28px;
    line-height: 42px;
  }

  .af-view .af-class-slider-property-list-title {
    margin-top: 2px;
    font-size: 18px;
  }

  .af-view .af-class-slider-property-list-items {
    padding-top: 30px;
    padding-bottom: 0px;
  }

  .af-view .af-class-slider-property-list-item {
    -webkit-flex-basis: 45%;
    -ms-flex-preferred-size: 45%;
    flex-basis: 45%;
  }

  .af-view .af-class-property_slide_prev_arrow {
    width: 50px;
    height: 50px;
  }

  .af-view .af-class-property_slide_next_arrow {
    width: 50px;
    height: 50px;
  }

  .af-view .af-class-content-collage-retail-content-column {
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-cert-collage-large-image {
    -webkit-box-flex: 50%;
    -webkit-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
  }

  .af-view .af-class-list-blocks-header {
    margin-top: 0px;
    font-size: 36px;
    line-height: 42px;
  }

  .af-view .af-class-list-block-details {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  }

  .af-view .af-class-list-block-column {
    padding-right: 10px;
    padding-bottom: 15px;
    padding-left: 10px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .af-view .af-class-list-blocks-container {
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-list-block-label {
    font-size: 14px;
  }

  .af-view .af-class-slider-employee-awards-content {
    margin-top: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-slider-employee-awards-details {
    width: 100%;
  }

  .af-view .af-class-slider-employee-award-image.af-class-current {
    margin-right: 20px;
    -webkit-box-flex: 5;
    -webkit-flex-grow: 5;
    -ms-flex-positive: 5;
    flex-grow: 5;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-slider-employee-awards-image.af-class-next-slide {
    -webkit-box-flex: 0;
    -webkit-flex: 0 4 10%;
    -ms-flex: 0 4 10%;
    flex: 0 4 10%;
  }

  .af-view .af-class-div-block-56 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-career-benefits-section-label {
    font-size: 16px;
  }

  .af-view .af-class-career-benefit-section-tag {
    max-width: 420px;
    padding-top: 20px;
    padding-left: 5px;
    font-size: 24px;
    line-height: 36px;
  }

  .af-view .af-class-career-benefits-header-icon {
    right: 85px;
    width: 100px;
  }

  .af-view .af-class-career-benefits-header-icon.af-class-extend-large {
    right: 0px;
  }

  .af-view .af-class-cards-career-benefit-blocks {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .af-view .af-class-cards-career-benefit-blocks.af-class-reversed {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-career-benefit-image-column {
    padding-top: 150px;
    padding-bottom: 150px;
    -webkit-flex-shrink: 2;
    -ms-flex-negative: 2;
    flex-shrink: 2;
    -webkit-flex-basis: 60%;
    -ms-flex-preferred-size: 60%;
    flex-basis: 60%;
  }

  .af-view .af-class-career-benefit-cards {
    max-width: 110%;
    -webkit-transform: translate(8%, 0px);
    -ms-transform: translate(8%, 0px);
    transform: translate(8%, 0px);
  }

  .af-view .af-class-career-benefit-cards.af-class-reversed {
    width: 100%;
    max-width: 100%;
    margin-bottom: -50px;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  .af-view .af-class-career-benefit-blocks-content-column {
    padding-top: 30px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 2 100%;
    -ms-flex: 0 2 100%;
    flex: 0 2 100%;
  }

  .af-view .af-class-career-benefit-blocks-content-column.af-class-reversed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
  }

  .af-view .af-class-career-benefit-blocks-header {
    width: 100%;
    padding-left: 30px;
  }

  .af-view .af-class-career-benefit-blocks-header.af-class-reversed {
    right: 0px;
  }

  .af-view .af-class-benefit-card-wrapper {
    width: 100%;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.15);
  }

  .af-view .af-class-benefit-card-title {
    padding-bottom: 10px;
    font-size: 18px;
    line-height: 28px;
  }

  .af-view .af-class-career-benefit-cards-offset {
    width: 100%;
    padding-top: 20px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-career-benefit-cards-offset.af-class-reversed {
    position: relative;
    margin-top: 0px;
    padding-top: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-career-benefit-cards-offset.af-class-_401k-reversed {
    position: relative;
    margin-top: 0px;
    padding-top: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-career-benefit-card-column-offset {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-career-benefit-card-column-offset.af-class-reversed {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
  }

  .af-view .af-class-career-benefit-card-column {
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-career-benefit-card-column.af-class-reversed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    align-self: start;
  }

  .af-view .af-class-container-2 {
    width: 100%;
    padding-right: 40px;
    padding-bottom: 0px;
    padding-left: 40px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-dropdown-list.w--open {
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.15);
  }

  .af-view .af-class-dropdown-list-2.w--open {
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.15);
  }

  .af-view .af-class-dropdown-list-3.w--open {
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.15);
  }

  .af-view .af-class-slider-poi-header {
    margin-top: 0px;
    font-size: 36px;
    line-height: 42px;
  }

  .af-view .af-class-div-block-73 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-shrink: 2;
    -ms-flex-negative: 2;
    flex-shrink: 2;
  }

  .af-view .af-class-list-tall {
    background-image: url('../images/career-pro-dev-benefits2x-p-1080.jpeg');
    background-position: 0px 50%;
    background-size: cover;
  }

  .af-view .af-class-list-tall-content-wrapper.af-class-blur {
    height: 100%;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .af-view .af-class-div-block-77 {
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-content-booklet.af-class-reversed {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .af-view .af-class-content-booklet-container {
    display: block;
    width: 100%;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-content-booklet-container.af-class-reversed {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-content-booklet-content {
    top: -20px;
    display: block;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    border-left-style: none;
  }

  .af-view .af-class-content-booklet-content.af-class-reversed {
    margin-right: auto;
    margin-left: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-right-style: none;
    text-align: left;
  }

  .af-view .af-class-content-booklet-image-column {
    width: 100%;
    height: 100%;
  }

  .af-view .af-class-booklet-bind-block {
    display: none;
  }

  .af-view .af-class-text-block-11 {
    display: block;
  }

  .af-view .af-class-div-block-78 {
    padding-right: 50px;
    padding-left: 50px;
  }

  .af-view .af-class-text-block-12 {
    font-size: 20px;
    line-height: 31px;
  }

  .af-view .af-class-text-block-13 {
    margin-top: 20px;
  }

  .af-view .af-class-list-faq-item-container {
    padding-left: 30px;
  }

  .af-view .af-class-list-faq-item-container.af-class-selected {
    background-color: transparent;
  }

  .af-view .af-class-list-faq-item-container.af-class-first-child {
    border-top: 1px solid #f6f6f6;
  }

  .af-view .af-class-list-faq-items {
    padding-left: 30px;
    border-style: none;
  }

  .af-view .af-class-list-faq {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-list-faq-question-title {
    max-width: 80%;
  }

  .af-view .af-class-list-faq-question-container {
    padding-left: 30px;
  }

  .af-view .af-class-list-faq-question-container.af-class-active {
    background-color: #ecd925;
  }

  .af-view .af-class-list-faq-answer-container {
    padding-left: 30px;
    background-color: rgba(243, 248, 255, 0.6);
  }

  .af-view .af-class-section-heading {
    margin-top: 0px;
    font-size: 36px;
    line-height: 42px;
  }

  .af-view .af-class-section-heading.af-class-faq {
    margin-bottom: 0px;
    padding-left: 30px;
  }

  .af-view .af-class-list-staff {
    padding-right: 40px;
    padding-left: 40px;
  }

  .af-view .af-class-list-staff-leaders-row {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-div-block-82 {
    padding-right: 75px;
    padding-left: 75px;
  }

  .af-view .af-class-sustainble-sub-nav-link {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 12px;
    text-align: center;
  }

  .af-view .af-class-sustainable-sub-nav {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-sustainable-why-we-care {
    padding-top: 0px;
  }

  .af-view .af-class-nav-menu {
    background-color: #fff;
  }

  .af-view .af-class-nav-bottom-link-label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-84 {
    padding: 40px 30px;
  }

  .af-view .af-class-div-block-88 {
    width: 100%;
  }

  .af-view .af-class-image-13 {
    display: block;
    width: 100px;
  }

  .af-view .af-class-div-block-89 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .af-view .af-class-div-block-90 {
    -webkit-flex-basis: 60%;
    -ms-flex-preferred-size: 60%;
    flex-basis: 60%;
  }

  .af-view .af-class-div-block-91 {
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-flex-basis: 40%;
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
  }

  .af-view .af-class-menu-button {
    background-color: #fff;
  }

  .af-view .af-class-menu-button.w--open {
    background-color: #3f3f3f;
  }

  .af-view .af-class-cards-content-offset {
    height: 100%;
  }

  .af-view .af-class-cards-content-offset--card {
    width: 100%;
    height: 100%;
    max-width: 600px;
  }

  .af-view .af-class-cards-content-offset-title {
    margin-bottom: 0px;
    font-size: 24px;
  }

  .af-view .af-class-content-card-offset-inner-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-card-content-offset-outer-wrap {
    padding-right: 0px;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-card-content-offset-outer-wrap.af-class-reversed {
    padding-left: 0px;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  .af-view .af-class-div-block-94 {
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-video-w-blurb-container {
    padding-top: 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-96 {
    max-width: 70%;
    padding-right: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    background-color: transparent;
  }

  .af-view .af-class-video-w-blurb-left-bg {
    right: 25%;
  }

  .af-view .af-class-video-w-blurb-right-bg {
    left: 50%;
    top: 75%;
  }

  .af-view .af-class-div-block-98 {
    -webkit-flex-shrink: 2;
    -ms-flex-negative: 2;
    flex-shrink: 2;
    -webkit-flex-basis: 475px;
    -ms-flex-preferred-size: 475px;
    flex-basis: 475px;
    background-position: 50% -157px;
  }

  .af-view .af-class-cards-highlight-activity {
    padding-bottom: 0px;
  }

  .af-view .af-class-cards-highlight-activity.af-class-reversed {
    padding-top: 0px;
  }

  .af-view .af-class-cards-highlight-activity-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-cards-highlight-activity-container.af-class-reversed {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-99 {
    margin-bottom: 0px;
    padding-top: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-div-block-100 {
    padding-top: 75px;
    padding-right: 40px;
    padding-left: 40px;
  }

  .af-view .af-class-cards-highlight-community--cards {
    position: relative;
    top: -60px;
    width: 100%;
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-card-highlights-community--card {
    max-width: 400px;
    margin-bottom: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-div-block-103 {
    height: 100%;
  }

  .af-view .af-class-div-block-104 {
    width: 90%;
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  .af-view .af-class-image-15 {
    height: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-video-testimonial-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-text-block-19 {
    font-size: 22px;
    line-height: 28px;
  }

  .af-view .af-class-div-block-107 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-108 {
    padding-right: 35px;
    padding-left: 35px;
  }

  .af-view .af-class-content-video-testimonial {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .af-view .af-class-content-video-testy-column {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-benchmark-leed-level-number {
    font-size: 40px;
    line-height: 42px;
  }

  .af-view .af-class-text-block-25 {
    width: 100%;
  }

  .af-view .af-class-benchmark-row {
    margin-left: 40px;
  }

  .af-view .af-class-sub-region-map-poi-bar-wrapper {
    left: 50%;
    bottom: 20px;
    overflow: visible;
    width: auto;
    max-width: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
  }

  .af-view .af-class-map-poi-icon {
    width: 25px;
    padding-right: 0px;
  }

  .af-view .af-class-map-poi-column {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-right-style: none;
  }

  .af-view .af-class-map-poi-label {
    padding-left: 0px;
    font-size: 10px;
    font-weight: 500;
  }

  .af-view .af-class-text-block-26 {
    padding: 0px 10px;
    font-size: 8px;
  }

  .af-view .af-class-header-property-map {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-image: url('../images/google-map3x.jpg');
    background-position: 50% 50%;
    background-size: cover;
  }

  .af-view .af-class-property-modal-wrapper {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 100%;
    max-width: 320px;
    background-color: #fff;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.25);
  }

  .af-view .af-class-div-block-121 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-text-block-27 {
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    text-align: left;
  }

  .af-view .af-class-div-block-122 {
    padding: 12px 15px 20px;
  }

  .af-view .af-class-property-card-modal-feat-list {
    padding-left: 10px;
  }

  .af-view .af-class-div-block-123 {
    padding-right: 14px;
    padding-left: 14px;
  }

  .af-view .af-class-div-block-124 {
    padding-right: 14px;
    padding-left: 14px;
  }

  .af-view .af-class-property-modal-feat-title {
    margin-bottom: 0px;
  }

  .af-view .af-class-text-block-32 {
    width: 100%;
  }

  .af-view .af-class-list-blocks-blurb-column-wrapper {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-property-header {
    height: 80vh;
  }

  .af-view .af-class-property-info-card-wrapper {
    width: 400px;
  }

  .af-view .af-class-property-info-card-price-info {
    display: block;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-property-phase--property-name {
    font-size: 18px;
  }

  .af-view .af-class-div-block-130 {
    display: inline-block;
  }

  .af-view .af-class-text-block-39 {
    margin-bottom: 0px;
    line-height: 19px;
  }

  .af-view .af-class-property-banner-info-wrapper {
    width: auto;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .af-view .af-class-button--3d-tour {
    margin-top: 15px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .af-view .af-class-button--3d-tour.af-class-no-margin {
    margin-top: 0px;
    margin-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .af-view .af-class-property-intro-copy {
    padding-right: 30px;
    padding-bottom: 200px;
    padding-left: 30px;
  }

  .af-view .af-class-image-20 {
    -webkit-transform: translate(0px, 19%);
    -ms-transform: translate(0px, 19%);
    transform: translate(0px, 19%);
  }

  .af-view .af-class-empty-column-50vw {
    display: none;
    -webkit-flex-basis: 40%;
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
  }

  .af-view .af-class-div-block-133 {
    padding-top: 60px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-property-sub-section-tagline.af-class-white {
    font-size: 32px;
  }

  .af-view .af-class-property-floorplans-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-property-floorplan-card-col {
    padding-bottom: 30px;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-property-floorplan-special-card {
    max-width: 300px;
  }

  .af-view .af-class-spotlight-cta-heading {
    font-size: 32px;
  }

  .af-view .af-class-spotlight-cta-container {
    max-width: 400px;
  }

  .af-view .af-class-review-container {
    width: 70%;
  }

  .af-view .af-class-slider-header {
    padding-left: 20px;
  }

  .af-view .af-class-slider-review-container {
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-star-rating-wrapper {
    padding-top: 3px;
  }

  .af-view .af-class-spotlight-key-fact {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 45px;
    padding-bottom: 45px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: url('../images/spotlight-key-fact-banner2x.jpg');
    background-position: 50% 50%;
    background-size: cover;
  }

  .af-view .af-class-image-21 {
    width: 120px;
  }

  .af-view .af-class-spotlight-key-fact-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-spotlight-key-fact-big-text {
    font-family: 'Outfit', sans-serif;
    color: #018094;
    font-size: 48px;
    line-height: 73px;
    font-weight: 700;
  }

  .af-view .af-class-spotlight-key-fact-label {
    font-family: 'Outfit', sans-serif;
    color: #018094;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
  }

  .af-view .af-class-icon-arrow--right {
    width: 50px;
  }

  .af-view .af-class-div-block-136 {
    display: inline-block;
    padding: 40px 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-color: transparent;
  }

  .af-view .af-class-text-block-44 {
    width: auto;
    margin-bottom: 30px;
    padding-right: 0px;
    color: #fff;
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
  }

  .af-view .af-class-div-block-137 {
    padding: 20px 30px;
  }

  .af-view .af-class-spotlight-special-feat-column.af-class-content {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-content-retail-showcase-condensed {
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-div-block-138 {
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-140 {
    width: 90%;
    padding: 0px 0px 40px;
  }

  .af-view .af-class-div-block-143 {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-div-block-145 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-div-block-147 {
    padding-top: 100px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-sustainability-highlights-expanded {
    margin-top: 50px;
  }

  .af-view .af-class-div-block-149 {
    top: -60px;
    padding-top: 50px;
  }

  .af-view .af-class-div-block-150 {
    margin-bottom: 0px;
    padding-right: 0px;
  }

  .af-view .af-class-div-block-151 {
    margin-bottom: 0px;
    padding-top: 60px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-153 {
    -webkit-transform: translate(-30%, -65%);
    -ms-transform: translate(-30%, -65%);
    transform: translate(-30%, -65%);
  }

  .af-view .af-class-heading-18 {
    font-size: 17px;
    line-height: 26px;
  }

  .af-view .af-class-spotlight-tour {
    padding: 30px;
  }

  .af-view .af-class-div-block-155.af-class-blur.af-class-max-width {
    padding: 50px;
  }

  .af-view .af-class-property-header-button-container {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .af-view .af-class-image-26 {
    display: none;
  }

  .af-view .af-class-image-27 {
    padding-right: 30px;
  }

  .af-view .af-class-property-sub-page-header.af-class-amenities {
    background-image: linear-gradient(8deg, rgba(0, 0, 0, 0.37), transparent), url('../images/amenities-header2x_1.jpg');
    background-position: 0px 0px, 0px 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }

  .af-view .af-class-property-sub-page-header.af-class-contact {
    background-image: linear-gradient(8deg, rgba(0, 0, 0, 0.37), transparent), url('../images/amenities-header2x_1.jpg');
    background-position: 0px 0px, 0px 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }

  .af-view .af-class-property-sub-page-header-container {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transform: translate(0px, 0%);
    -ms-transform: translate(0px, 0%);
    transform: translate(0px, 0%);
  }

  .af-view .af-class-heading-19 {
    margin-bottom: 30px;
    padding-left: 0px;
    font-size: 56px;
    line-height: 72px;
  }

  .af-view .af-class-property-subpage-placard-brick.af-class-prop-cta {
    margin-top: 5px;
    padding-top: 8px;
    padding-bottom: 10px;
  }

  .af-view .af-class-property-subpage-placard-brick.af-class-prop-details {
    position: relative;
    width: auto;
    padding-top: 15px;
    padding-bottom: 6px;
    padding-left: 15px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-property-subpage-placard {
    width: 370px;
    height: 192px;
    max-width: none;
    margin-top: 0px;
  }

  .af-view .af-class-icon--star--active {
    width: 20px;
    margin-right: 5px;
  }

  .af-view .af-class-icon--star--inactive {
    width: 20px;
  }

  .af-view .af-class-text-block-47 {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 14px;
  }

  .af-view .af-class-div-block-157 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    padding: 10px 12px 12px 6px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 20px;
    background-color: #06845e;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
    font-family: 'Outfit', sans-serif;
    color: #fff;
    font-size: 12px;
    text-transform: none;
  }

  .af-view .af-class-image-28 {
    width: 14px;
  }

  .af-view .af-class-div-block-158 {
    margin-top: 0px;
    padding-bottom: 5px;
  }

  .af-view .af-class-list-amenities-w-gallery {
    width: 100%;
    padding-top: 75px;
  }

  .af-view .af-class-list-amenities-w-gallery.af-class-reversed {
    left: 0%;
  }

  .af-view .af-class-amenities-slider-list-item {
    width: 50%;
    margin-bottom: 10px;
    padding-right: 50px;
  }

  .af-view .af-class-amenities-slider-list-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    max-width: none;
    padding-top: 75px;
    padding-right: 0px;
    padding-left: 50px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-div-block-159 {
    padding-right: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-div-block-159.af-class-reversed {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-amenity-slider-heading {
    padding-left: 40px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-div-block-160 {
    max-width: none;
  }

  .af-view .af-class-amenities-image-slider-container {
    position: relative;
    left: 0%;
    width: 100%;
    height: 500px;
  }

  .af-view .af-class-amenities-image-slider-container.af-class-reversed {
    left: 0%;
  }

  .af-view .af-class-amenities-slider-toolbar {
    left: 50%;
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
  }

  .af-view .af-class-amenities-slider-toolbar.af-class-reversed {
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
  }

  .af-view .af-class-list-resources-list-container {
    padding-right: 30px;
    padding-left: 40px;
  }

  .af-view .af-class-div-block-163 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-list-resource-item-wrap {
    width: 50%;
  }

  .af-view .af-class-list-resource-item-wrap.af-class-last-child {
    width: 100%;
  }

  .af-view .af-class-list-resource-item.af-class-margin-top {
    max-width: 360px;
    margin-top: 40px;
  }

  .af-view .af-class-spotlight-next-steps {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-spotlight-next-steps-container {
    width: 100%;
    height: 30vh;
    min-height: 200px;
    padding: 30px;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#000), to(hsla(0, 0%, 100%, 0))),
      url('../images/spotlight-tour-kitchen2x.jpg');
    background-image: linear-gradient(0deg, #000, hsla(0, 0%, 100%, 0)), url('../images/spotlight-tour-kitchen2x.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, 1318px;
  }

  .af-view .af-class-div-block-175 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .af-view .af-class-spotlight-next-steps-label {
    border-right: 1px none #fff;
    font-size: 16px;
  }

  .af-view .af-class-text-block-53 {
    font-size: 36px;
  }

  .af-view .af-class-div-block-177 {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .af-view .af-class-floorplan-link-list {
    margin-bottom: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-180 {
    margin-left: 15px;
  }

  .af-view .af-class-text-block-55 {
    font-size: 12px;
  }

  .af-view .af-class-floorplan-apply-btn {
    display: none;
  }

  .af-view .af-class-link {
    margin-left: 20px;
    font-size: 12px;
  }

  .af-view .af-class-floorplan-nav-link {
    margin-left: 15px;
    font-size: 15px;
  }

  .af-view .af-class-floorplan-nav-link.af-class-active {
    margin-left: 20px;
  }

  .af-view .af-class-div-block-182 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }

  .af-view .af-class-div-block-184 {
    position: relative;
    z-index: 1;
    margin-right: 20px;
    margin-left: -175px;
    padding-right: 10px;
  }

  .af-view .af-class-div-block-185 {
    position: relative;
    width: 50%;
    margin-right: 10px;
    padding-top: 15px;
  }

  .af-view .af-class-text-block-56 {
    font-size: 14px;
    line-height: 20px;
  }

  .af-view .af-class-text-block-57 {
    padding-bottom: 15px;
  }

  .af-view .af-class-text-block-58 {
    width: 100%;
    padding-left: 6px;
    font-size: 11px;
    text-shadow: 1px 1px 6px #000;
  }

  .af-view .af-class-button-4 {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 12px;
    letter-spacing: 0px;
  }

  .af-view .af-class-image-34 {
    height: 95px;
  }

  .af-view .af-class-div-block-186 {
    margin-right: 0px;
  }

  .af-view .af-class-div-block-187 {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(63, 63, 63, 0)), color-stop(90%, #3f3f3f));
    background-image: linear-gradient(90deg, rgba(63, 63, 63, 0), #3f3f3f 90%);
  }

  .af-view .af-class-image-35 {
    height: 150px;
  }

  .af-view .af-class-floorplan-section-header {
    -webkit-transform: translate(-20%, -75%);
    -ms-transform: translate(-20%, -75%);
    transform: translate(-20%, -75%);
    font-size: 48px;
    text-align: right;
  }

  .af-view .af-class-div-block-192 {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .af-view .af-class-card-header {
    padding: 10px 15px;
  }

  .af-view .af-class-floorplans-card {
    width: 50%;
    max-width: 320px;
    margin-bottom: 50px;
  }

  .af-view .af-class-div-block-194 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-floorplans-card-beds-column {
    width: 50%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    border-bottom: 1px solid #d8d8d8;
  }

  .af-view .af-class-floorplans-card-beds-column.af-class-bordered {
    padding-right: 5px;
    padding-left: 15px;
    border-right-style: none;
  }

  .af-view .af-class-div-block-196 {
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-floorplans-card-column-labels {
    padding-left: 4px;
  }

  .af-view .af-class-floorplans-card-cta-row {
    padding-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .af-view .af-class-text-block-61 {
    font-size: 16px;
  }

  .af-view .af-class-text-block-62 {
    font-size: 12px;
  }

  .af-view .af-class-text-block-63 {
    margin-left: 8px;
    font-size: 16px;
  }

  .af-view .af-class-floorplans-card-unit-label {
    padding-bottom: 10px;
    font-size: 14px;
  }

  .af-view .af-class-floorplan-policies {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-div-block-200 {
    width: 100%;
    -webkit-box-flex: 40%;
    -webkit-flex: 40%;
    -ms-flex: 40%;
    flex: 40%;
  }

  .af-view .af-class-policies-tabs {
    width: 100%;
    margin-top: 40px;
    -webkit-box-flex: 60%;
    -webkit-flex: 60%;
    -ms-flex: 60%;
    flex: 60%;
  }

  .af-view .af-class-tab-header {
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-tab-header.af-class-active {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 15px;
    line-height: 20px;
  }

  .af-view .af-class-div-block-206 {
    max-width: none;
  }

  .af-view .af-class-tab-content-details-row {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-208 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-image-37 {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-tabbed-content-wrap {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px none #d2d2d2;
  }

  .af-view .af-class-floorplann-unit-row {
    padding: 0px 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-bottom-style: none;
  }

  .af-view .af-class-floorplann-unit-row.af-class-selected {
    width: 100%;
    padding-right: 30px;
    padding-bottom: 40px;
    padding-left: 30px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom-style: solid;
  }

  .af-view .af-class-floorplan-unit-column {
    padding-right: 15px;
  }

  .af-view .af-class-text-block-65 {
    font-size: 16px;
  }

  .af-view .af-class-floorplan-unit-column-label {
    margin-bottom: 0px;
    font-size: 10px;
  }

  .af-view .af-class-floorplan-unit-column-value {
    font-size: 12px;
  }

  .af-view .af-class-text-block-66 {
    font-size: 12px;
  }

  .af-view .af-class-text-block-67 {
    font-size: 14px;
  }

  .af-view .af-class-div-block-217 {
    width: 100%;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-floorplan-unit-column-container {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px none #d2d2d2;
  }

  .af-view .af-class-div-block-218 {
    width: 100%;
    max-width: none;
    margin-top: 60px;
  }

  .af-view .af-class-div-block-219 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-div-block-223 {
    padding-left: 30px;
  }

  .af-view .af-class-div-block-224 {
    padding-top: 20px;
  }

  .af-view .af-class-div-block-225 {
    padding: 25px;
  }

  .af-view .af-class-div-block-227 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .af-view .af-class-floorplan-unit-details-wrapper {
    width: 100%;
    max-width: 100%;
    margin-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-property-nav-link {
    margin-right: 7px;
    margin-left: 7px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-property-nav-link.w--current {
    font-weight: 700;
  }

  .af-view .af-class-property-nav-brand-wrapper {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-property-nav-link-container {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: scroll;
    width: 100%;
    padding: 20px 20px 20px 0px;
    border-top: 1px solid #d8d8d8;
    background-color: #fff;
    -webkit-transform: translate(0px, 100%);
    -ms-transform: translate(0px, 100%);
    transform: translate(0px, 100%);
  }

  .af-view .af-class-div-block-244 {
    padding-top: 50px;
  }

  .af-view .af-class-location-benefit-list-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .af-view .af-class-div-block-245 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-246 {
    width: 100%;
    margin-top: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-list-item-4 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .af-view .af-class-image-40 {
    left: 0%;
  }

  .af-view .af-class-location-score-number {
    font-size: 48px;
    line-height: 50px;
  }

  .af-view .af-class-locations-map-poi-bar {
    overflow: scroll;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-div-block-255 {
    min-height: 40px;
    min-width: 40px;
    margin-right: 10px;
  }

  .af-view .af-class-div-block-256 {
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-text-block-80 {
    width: 100%;
  }

  .af-view .af-class-sustainable-posts {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-div-block-261 {
    margin-left: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-div-block-262 {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-unordered-list-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-div-block-264 {
    width: 100%;
  }

  .af-view .af-class-close {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .af-view .af-class-floorplans-card-col-sqft-label {
    padding-left: 4px;
  }

  .af-view .af-class-sticky-card-column {
    width: 100%;
    margin-top: 50px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-the-grid-wrapper {
    width: 100%;
  }

  .af-view .af-class-div-block-267 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-grid-step-wrapper.af-class-payment-breakdown {
    width: 100%;
  }

  .af-view .af-class-floorplan-card-col {
    font-size: 12px;
  }

  .af-view .af-class-floorplan-card-col.af-class-profile {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .af-view .af-class-floorplan-sticky-card {
    width: 100%;
    max-width: 500px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-floorplan-card-label {
    margin-bottom: 0px;
  }

  .af-view .af-class-floorplan-card-bold-heading {
    margin-top: 0px;
    font-size: 16px;
  }

  .af-view .af-class-floorplan-sticky-card-back-wrap {
    padding-bottom: 15px;
  }

  .af-view .af-class-div-block-274 {
    width: 100%;
    max-width: none;
    padding-right: 50px;
    padding-left: 50px;
  }

  .af-view .af-class-lease-options-wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-div-block-275 {
    max-width: 320px;
    margin-right: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-275.af-class-full-width {
    width: 100%;
    max-width: 100%;
    margin-top: 30px;
  }

  .af-view .af-class-div-block-275.af-class-first-child {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-pet-counter {
    padding: 7px 15px 5px;
  }

  .af-view .af-class-icon--dog {
    width: 25px;
  }

  .af-view .af-class-lease-option.af-class-button-style.af-class-margin-bottom {
    max-width: 320px;
  }

  .af-view .af-class-lease-option.af-class-button-style.af-class-margin-bottom.af-class-selected {
    max-width: 320px;
  }

  .af-view .af-class-floorplan-card-content {
    width: 100%;
  }

  .af-view .af-class-div-block-283 {
    width: 45%;
  }

  .af-view .af-class-short-term-list-wrapper {
    width: 55%;
  }

  .af-view .af-class-div-block-285 {
    margin-top: 0%;
  }

  .af-view .af-class-short-term-list-item {
    width: 100%;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom-color: hsla(0, 0%, 100%, 0.25);
    font-size: 14px;
    line-height: 18px;
  }

  .af-view .af-class-short-term-list-tagline {
    -webkit-transform: translate(0px, -10%);
    -ms-transform: translate(0px, -10%);
    transform: translate(0px, -10%);
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-image-41 {
    width: 50vw;
  }

  .af-view .af-class-list-short-term-w-gallery {
    width: 100%;
  }

  .af-view .af-class-short-term-slider-heading {
    padding-left: 40px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-short-term-image-slider-container {
    position: relative;
    left: 0%;
    width: 100%;
    height: 500px;
  }

  .af-view .af-class-blog-card--featured.af-class-blur {
    right: auto;
    bottom: 50px;
    background-color: hsla(0, 0%, 100%, 0.9);
  }

  .af-view .af-class-blog-banner {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-blog--search-bar.af-class-blur {
    padding-top: 0px;
    background-color: rgba(241, 245, 251, 0.95);
  }

  .af-view .af-class-blog-search-bar-container {
    padding-top: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-div-block-288 {
    margin-left: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-div-block-288.af-class-margin-right {
    margin-right: 30px;
  }

  .af-view .af-class-div-block-289 {
    width: 150px;
  }

  .af-view .af-class-div-block-290 {
    margin-top: 20px;
  }

  .af-view .af-class-blog-search-bar--search-field {
    width: auto;
    padding: 10px 10px 6px;
    border: 1px solid #4c698f;
    border-radius: 50%;
  }

  .af-view .af-class-blog-posts {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-blog-posts-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-blog-post-cards-column {
    position: relative;
    width: 100%;
    padding-right: 0px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-blog-sidebar-column {
    width: 100%;
    padding-left: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-blog-card--small {
    margin-right: 50px;
    margin-left: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-blog-card--small.af-class-related {
    margin-right: 30px;
  }

  .af-view .af-class-social-card {
    width: 300px;
    margin-right: 30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-blog-sidebar-section {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: scroll;
    width: 100%;
    padding-right: 0px;
    padding-bottom: 40px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-card-guide-track {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: scroll;
    width: 100%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-search-icon {
    position: relative;
    top: 1px;
  }

  .af-view .af-class-blog-sidebar-section-heading {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .af-view .af-class-blog-article-title {
    margin-bottom: 30px;
    font-size: 36px;
    line-height: 46px;
  }

  .af-view .af-class-div-block-301 {
    height: 100%;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-302 {
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .af-view .af-class-div-block-303 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-blog-article--social-icon {
    width: 25px;
    margin-left: 13px;
  }

  .af-view .af-class-blog-article--social-icon.af-class-no-margin {
    margin-left: 0px;
  }

  .af-view .af-class-blog-article-banner {
    max-height: 500px;
  }

  .af-view .af-class-blog-article-banner-content-col {
    -webkit-flex-basis: 400px;
    -ms-flex-preferred-size: 400px;
    flex-basis: 400px;
  }

  .af-view .af-class-back-arrow--long {
    position: relative;
    top: -2px;
  }

  .af-view .af-class-blog-article-back-button {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-div-block-304 {
    padding-bottom: 30px;
  }

  .af-view .af-class-blog-article-publsih-date {
    margin-top: 9px;
    margin-left: 0px;
    padding-top: 6px;
    padding-right: 15px;
    padding-left: 0px;
    border-top: 1px solid hsla(0, 0%, 82.4%, 0.5);
    border-left-style: none;
  }

  .af-view .af-class-blog-content-container {
    max-width: 600px;
    padding-top: 50px;
  }

  .af-view .af-class-blog-related-posts-container {
    width: 100%;
    padding-top: 40px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-306 {
    overflow: scroll;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-307 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-heading-22 {
    margin-bottom: 20px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-308 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-signup-wrapper {
    margin-top: 50px;
  }

  .af-view .af-class-create-profile-image {
    display: none;
  }

  .af-view .af-class-div-block-313 {
    width: 100%;
    padding-top: 50px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-div-block-314 {
    width: 100%;
    padding-top: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-floorplan-card-profile {
    width: 100%;
    height: 100%;
    max-width: 450px;
  }

  .af-view .af-class-floorplan-card-profile.af-class-quote-summary {
    width: 100%;
  }

  .af-view .af-class-floorplan-card-profile.af-class-quote-summary.af-class-available {
    width: 100%;
  }

  .af-view .af-class-community-placard.af-class-margin-bottom {
    width: 100%;
    padding-right: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-317 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-div-block-318 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-floorplan-card-col-label {
    font-size: 11px;
    line-height: 16px;
  }

  .af-view .af-class-floorplan-card-profile-col-label {
    font-size: 12px;
    line-height: 16px;
  }

  .af-view .af-class-phase-1-banner-content {
    height: 550px;
  }

  .af-view .af-class-phase-1-banner-container {
    left: 0px;
    top: 35px;
    right: 0px;
    padding-bottom: 40px;
  }

  .af-view .af-class-phase-1-header-content {
    position: relative;
    left: 5%;
    bottom: 0px;
    width: 90%;
    height: auto;
    max-width: 100%;
    padding: 30px;
    -webkit-transform: translate(0px, -15%);
    -ms-transform: translate(0px, -15%);
    transform: translate(0px, -15%);
  }

  .af-view .af-class-p2-header-title {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 34px;
  }

  .af-view .af-class-breadcrumb-container {
    -webkit-transform: translate(10%, -100%);
    -ms-transform: translate(10%, -100%);
    transform: translate(10%, -100%);
  }

  .af-view .af-class-breadcrumb-container.af-class-blur {
    padding-bottom: 10px;
    padding-left: 20px;
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
    transform: translate(0px, -100%);
  }

  .af-view .af-class-div-block-325 {
    width: 75%;
  }

  .af-view .af-class-div-block-328 {
    height: 25px;
  }

  .af-view .af-class-phase-1-nav-container {
    width: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-div-block-331 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-331.af-class-current {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    box-shadow: inset 0 5px 0 0 #ecd925;
  }

  .af-view .af-class-div-block-331.af-class-not-available {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-332 {
    width: 100%;
  }

  .af-view .af-class-property-location-teaser {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-phase-1-amenity-list {
    padding-left: 0px;
  }

  .af-view .af-class-div-block-334 {
    width: 80%;
  }

  .af-view .af-class-big-stat-number {
    font-size: 36px;
    line-height: 42px;
  }

  .af-view .af-class-div-block-342 {
    width: 100%;
  }

  .af-view .af-class-p1-sustainable-content-container {
    padding-right: 30px;
    padding-left: 50px;
  }

  .af-view .af-class-image-44 {
    -webkit-transform: translate(-70%, 0px);
    -ms-transform: translate(-70%, 0px);
    transform: translate(-70%, 0px);
  }

  .af-view .af-class-p1-impact-content-col {
    width: 80%;
  }

  .af-view .af-class-p1-sustainable-image-col {
    width: 40%;
    margin-left: 0px;
  }

  .af-view .af-class-logo-holder {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-345 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .af-view .af-class-retail-showcase-cta-row {
    width: 100%;
    margin-bottom: 0px;
    padding-right: 10%;
  }

  .af-view .af-class-div-block-351 {
    width: 80vw;
  }

  .af-view .af-class-section-tagline-large {
    margin-top: 0px;
    font-size: 36px;
    line-height: 42px;
  }

  .af-view .af-class-section-tagline-large.af-class-white {
    font-size: 48px;
    line-height: 60px;
  }

  .af-view .af-class-div-block-352 {
    padding: 40px 50px 30px;
  }

  .af-view .af-class-p1-retail-item {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-p1-retail-item.af-class-no-top-padding {
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
  }

  .af-view .af-class-text-block-103 {
    margin-bottom: 10px;
  }

  .af-view .af-class-p1-retail-item-description {
    font-size: 13px;
    line-height: 21px;
  }

  .af-view .af-class-div-block-356 {
    padding-right: 30px;
    padding-bottom: 0px;
    padding-left: 30px;
  }

  .af-view .af-class-phase-1-header {
    padding-left: 0px;
  }

  .af-view .af-class-p2-header-content {
    left: 50%;
    width: 90%;
    max-width: 100vw;
    padding: 30px;
    -webkit-transform: translate(-50%, 15%);
    -ms-transform: translate(-50%, 15%);
    transform: translate(-50%, 15%);
  }

  .af-view .af-class-breadcrumb-divider {
    width: 1px;
  }

  .af-view .af-class-text-block-106 {
    font-size: 37px;
  }

  .af-view .af-class-div-block-368 {
    width: 80%;
    margin-top: 35px;
  }

  .af-view .af-class-div-block-369 {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-div-block-370 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-371 {
    width: 100%;
  }

  .af-view .af-class-fullscreen-impact-tagline {
    margin-top: 0px;
    font-size: 36px;
    line-height: 42px;
  }

  .af-view .af-class-div-block-372 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-376 {
    width: 100%;
  }

  .af-view .af-class-div-block-377 {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-sticky-quote-content {
    width: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-text-block-110 {
    font-size: 12px;
  }

  .af-view .af-class-sticky-bar-col-value {
    font-size: 18px;
  }

  .af-view .af-class-sticky-bar-detail-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 33%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-sticky-bar-detail-col.af-class-no-border {
    border-right-style: none;
  }

  .af-view .af-class-div-block-381 {
    width: 70%;
    margin-top: 24px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-sticky-quote-summary-bar {
    padding-top: 0px;
  }

  .af-view .af-class-payment-breakdown-wrap {
    position: relative;
    left: auto;
    width: 100%;
    max-width: 720px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-payment-breakdown-wrap.af-class-vertical {
    max-width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-payment-detail-content {
    max-width: 320px;
    margin-right: 0px;
  }

  .af-view .af-class-div-block-383 {
    border-bottom-color: #f6f6f6;
  }

  .af-view .af-class-div-block-383.af-class-no-border {
    border-bottom-color: #f6f6f6;
  }

  .af-view .af-class-div-block-384 {
    width: 50%;
    padding-right: 20px;
  }

  .af-view .af-class-div-block-384.af-class-full-width {
    width: 50%;
    padding-right: 30px;
  }

  .af-view .af-class-monthly-payment-details {
    padding-top: 0px;
    border-top-style: none;
  }

  .af-view .af-class-link--text {
    margin-top: 10px;
  }

  .af-view .af-class-text-block-112 {
    margin-left: 5px;
  }

  .af-view .af-class-date-range-wrap {
    font-size: 18px;
  }

  .af-view .af-class-div-block-388 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-profile-sidebar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-height: 0vh;
    padding-top: 60px;
    padding-bottom: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-quote-details-wrap {
    padding: 30px 30px 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-text-block-113 {
    font-size: 22px;
    line-height: 28px;
  }

  .af-view .af-class-div-block-392 {
    width: 50%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-quote-summary-unti-col {
    width: 100%;
    max-width: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .af-view .af-class-list-label {
    margin-bottom: 0px;
    font-size: 9px;
  }

  .af-view .af-class-list-item-7 {
    width: auto;
    margin-right: 15px;
  }

  .af-view .af-class-quote-details-list {
    width: auto;
    max-width: none;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-quote-summary-details-col {
    max-width: none;
    padding-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-div-block-395 {
    width: 100%;
    max-width: none;
  }

  .af-view .af-class-div-block-396 {
    width: 50%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-397 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-full-screen-menu-background {
    display: none;
  }

  .af-view .af-class-full-screen-menu-link {
    font-size: 26px;
  }

  .af-view .af-class-full-screen-menu-link.af-class-sub-link {
    font-size: 16px;
  }

  .af-view .af-class-unordered-list-10 {
    width: 100%;
    padding-top: 40px;
    padding-left: 0px;
  }

  .af-view .af-class-full-screen-sub-menu-item {
    width: auto;
    margin-right: 30px;
  }

  .af-view .af-class-slider-timeline-content-col {
    width: 90%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-initiative-grid-heading-wrap {
    padding-right: 50px;
    padding-left: 50px;
  }

  .af-view .af-class-initiative-grid-block {
    width: 50%;
  }

  .af-view .af-class-initiative-grid-big-heading {
    width: 100%;
    font-size: 24px;
    line-height: 32px;
  }

  .af-view .af-class-activisim-event-wrap {
    padding-top: 40px;
  }

  .af-view .af-class-activism-event-heading {
    font-size: 12px;
  }

  .af-view .af-class-activism-advocacy {
    padding-top: 0px;
  }

  .af-view .af-class-activism-event-description {
    font-size: 16px;
    line-height: 22px;
  }

  .af-view .af-class-activism-details-col {
    width: 100%;
  }

  .af-view .af-class-sustainable_billboard {
    padding: 0px;
  }

  .af-view .af-class-sustainable-billboard-content-blur {
    width: 75%;
    max-width: 480px;
    background-color: rgba(0, 0, 0, 0.85);
  }

  .af-view .af-class-suatianble-bb-background {
    padding: 40px;
    background-image: url('../images/cdnassets.jpg');
    background-size: cover;
  }

  .af-view .af-class-home-guide-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-412 {
    padding-right: 10px;
  }

  .af-view .af-class-div-block-413 {
    background-position: 50% 50%;
  }

  .af-view .af-class-residents-details-col {
    padding-left: 30px;
  }

  .af-view .af-class-giving-back-green-content {
    width: 50%;
  }

  .af-view .af-class-heading-28 {
    font-size: 16px;
  }

  .af-view .af-class-home-guide-dropdown {
    margin-top: 0px;
  }

  .af-view .af-class-div-block-419 {
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
  }

  .af-view .af-class-property-contact-detail-col-detail {
    font-size: 20px;
  }

  .af-view .af-class-div-block-420 {
    margin-top: 0px;
  }

  .af-view .af-class-property-contact-details-wrap {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-421 {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .af-view .af-class-div-block-422 {
    margin-top: 10px;
  }

  .af-view .af-class-property-contact-address {
    margin-top: 0px;
    color: #fff;
    font-size: 32px;
    line-height: 38px;
  }

  .af-view .af-class-map-icon {
    min-height: 40px;
    min-width: 40px;
    margin-right: 10px;
  }

  .af-view .af-class-div-block-424 {
    padding: 40px;
  }

  .af-view .af-class-div-block-425 {
    padding: 40px;
  }

  .af-view .af-class-text-block-120 {
    font-size: 13px;
  }

  .af-view .af-class-contact--community-block {
    padding-top: 10px;
  }

  .af-view .af-class-text-block-122 {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
  }

  .af-view .af-class-property-modal-mobile {
    width: 370px;
    height: 192px;
    max-width: none;
    margin-top: 0px;
  }

  .af-view .af-class-property-modal-mobile-placard-brick.af-class-prop-cta {
    margin-top: 5px;
    padding-top: 8px;
    padding-bottom: 10px;
  }

  .af-view .af-class-property-modal-mobile-placard-brick.af-class-prop-details {
    position: relative;
    padding-top: 15px;
    padding-bottom: 6px;
    padding-left: 15px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-property-modal-mobile-slide-wrap {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 2 100%;
    -ms-flex: 0 2 100%;
    flex: 0 2 100%;
  }

  .af-view .af-class-image-2-copy {
    bottom: 3px;
    display: none;
    width: 100px;
  }

  .af-view .af-class-image-3-copy {
    display: none;
  }

  .af-view .af-class-image-3-copy-2 {
    display: none;
  }

  .af-view .af-class-image-3-copy-2.af-class-slide-right {
    right: 20px;
    display: none;
  }

  .af-view .af-class-image-56 {
    width: 9px;
  }

  .af-view .af-class-neighborhood-card {
    padding-bottom: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-neighborhood-card-top {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 2 100%;
    -ms-flex: 0 2 100%;
    flex: 0 2 100%;
  }

  .af-view .af-class-neighborhood-card-bottom {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 2 100%;
    -ms-flex: 0 2 100%;
    flex: 0 2 100%;
  }

  .af-view .af-class-neighborhood-card-title {
    font-size: 24px;
    line-height: 36px;
  }

  .af-view .af-class-link-w-icon {
    margin-left: 20px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    font-size: 12px;
  }

  .af-view .af-class-link-icon-svg {
    width: 15px;
    height: 17px;
  }

  .af-view .af-class-link-icon-svg.af-class-margin-right {
    margin-right: 10px;
  }

  .af-view .af-class-bottom-menu-dropdown-toggle {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-bottom-menu-dropdown-toggle.w--open {
    padding-left: 0px;
  }

  .af-view .af-class-icon-2 {
    margin-bottom: 0px;
  }

  .af-view .af-class-dropdown-toggle-3 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-nav-bottom-menu-link-wrap {
    height: 100%;
  }

  .af-view .af-class-top-nav-menu-list {
    margin-top: 10px;
    padding-right: 30px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-bottom-nav-menu-list {
    padding-right: 30px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-property-contact-cta {
    padding: 30px;
  }

  .af-view .af-class-div-block-447 {
    min-width: 100%;
  }

  .af-view .af-class-property-menu-items {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-full-sreen-main-link {
    font-size: 26px;
  }

  .af-view .af-class-seo-title {
    line-height: 24px;
  }

  .af-view .af-class-cards-extending-blocks-large-image {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-div-block-449 {
    position: relative;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-image-59 {
    margin-top: 0%;
  }

  .af-view .af-class-div-block-458 {
    width: 50%;
    padding: 10px;
  }

  .af-view .af-class-div-block-461 {
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-462 {
    width: 100%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .af-view .af-class-div-block-464 {
    margin-bottom: 40px;
  }

  .af-view .af-class-div-block-466 {
    margin-right: 20px;
  }

  .af-view .af-class-div-block-467 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-div-block-471 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-474 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-477 {
    position: absolute;
    right: 0px;
    bottom: 0px;
    display: block;
    width: 50px;
    height: 60px;
    background-image: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 100%, 0)), to(#fff));
    background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff);
    -webkit-transform: translate(0px, 100%);
    -ms-transform: translate(0px, 100%);
    transform: translate(0px, 100%);
  }

  .af-view .af-class-html-embed-30 {
    display: none;
  }

  .af-view .af-class-award-image {
    width: 100%;
    max-width: 250px;
  }

  .af-view .af-class-heading-xxl {
    font-size: 64px;
    line-height: 72px;
  }

  .af-view .af-class-nav-bottom-menu-link-wrap-sustainability {
    height: 100%;
  }

  .af-view .af-class-list-tall-image-mobile {
    display: none;
  }

  .af-view .af-class-list-blocks-blurb-logo {
    width: 100%;
    height: 100%;
    max-width: 250px;
  }
}

@media (max-width: 767px) {
  .af-view .af-class-wrapper.af-class-footer {
    padding-top: 0%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-wrapper.af-class-content_intro_image {
    padding: 30px;
  }

  .af-view .af-class-wrapper.af-class-slider_points_of_interest {
    margin-top: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }

  .af-view .af-class-wrapper.af-class-content_intro_billboard {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-wrapper.af-class-content_collage_point_of_interest {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-container.af-class-home-intro {
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-container.af-class-image-background.af-class-full-width.af-class-min-height {
    height: auto;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .af-view .af-class-container.af-class-footer.af-class-flex {
    width: 90%;
    padding-top: 5%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-home-intro-content {
    position: relative;
    padding-top: 5%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-home-intro-images {
    width: 100%;
    min-height: 385px;
    -webkit-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }

  .af-view .af-class-button.af-class-boss.af-class-yellow.af-class-no-margin {
    display: inline-block;
    width: auto;
  }

  .af-view .af-class-button.af-class-boss.af-class-yellow.af-class-no-margin.af-class-hide-desktop {
    font-size: 13px;
  }

  .af-view .af-class-button.af-class-boss.af-class-yellow.af-class-no-margin.af-class-career-page-header-btn-offset {
    position: absolute;
    left: 50%;
    bottom: -20px;
    -webkit-transform: translate(-50%, 100%);
    -ms-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%);
  }

  .af-view .af-class-home-intro-image.af-class-left-offset {
    max-width: 55%;
    -webkit-transform: translate(-25%, 50%);
    -ms-transform: translate(-25%, 50%);
    transform: translate(-25%, 50%);
  }

  .af-view .af-class-home-intro-image.af-class-right-offset {
    max-width: 55%;
    -webkit-transform: translate(90%, 50%);
    -ms-transform: translate(90%, 50%);
    transform: translate(90%, 50%);
  }

  .af-view .af-class-section-tagline {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 28px;
  }

  .af-view .af-class-section-tagline.af-class-white {
    padding-right: 0px;
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-section-tagline.af-class-large.af-class-loft {
    font-size: 36px;
    line-height: 46px;
  }

  .af-view .af-class-inner-content-container.af-class-home-careers {
    -webkit-transform: translate(0px, 20%);
    -ms-transform: translate(0px, 20%);
    transform: translate(0px, 20%);
  }

  .af-view .af-class-footer-top {
    margin-top: 5%;
    padding-bottom: 10%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-footer-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-footer-text-link {
    margin-bottom: 10px;
    margin-left: 0px;
    text-align: right;
  }

  .af-view .af-class-social-icon {
    margin-right: 30px;
    margin-left: 0px;
  }

  .af-view .af-class-div-block-3 {
    margin-top: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-div-block-4 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-div-block-5 {
    width: 100%;
    margin-top: 30px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-div-block-6 {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-map {
    width: 40px;
    margin-top: 6px;
  }

  .af-view .af-class-map-zoom-controls-wrapper {
    width: auto;
    margin-bottom: 20px;
    -webkit-transform: translate(-20px, 0px);
    -ms-transform: translate(-20px, 0px);
    transform: translate(-20px, 0px);
  }

  .af-view .af-class-content_intro_image_title {
    font-size: 36px;
    line-height: 46px;
    font-weight: 700;
    text-align: center;
    text-shadow: 3px 0 10px rgba(0, 0, 0, 0.5);
  }

  .af-view .af-class-poi_slider_pagination_wrapper {
    right: 50%;
    -webkit-transform: translate(50%, 0px);
    -ms-transform: translate(50%, 0px);
    transform: translate(50%, 0px);
  }

  .af-view .af-class-slider-poi-content-wrapper {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-slider_poi_image_wrapper {
    min-height: 50vh;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-content-image-offset-header {
    width: 100%;
    padding-top: 30px;
    padding-right: 5%;
    padding-left: 5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-content-image-offset-paragraph {
    max-width: none;
  }

  .af-view .af-class-content_image_offset_cards {
    -webkit-transform: translate(0px, -4%);
    -ms-transform: translate(0px, -4%);
    transform: translate(0px, -4%);
  }

  .af-view .af-class-poi_card {
    width: 100%;
    max-width: 400px;
    margin-bottom: 60px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-poi_card_image.af-class-restaurant {
    height: 320px;
    min-height: 320px;
  }

  .af-view .af-class-poi_card_image.af-class-brunch {
    min-height: 320px;
  }

  .af-view .af-class-poi_card_image.af-class-drinks {
    min-height: 320px;
  }

  .af-view .af-class-poi_card_content {
    width: 100%;
    height: auto;
  }

  .af-view .af-class-div-block-11 {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-poi_card_container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-news_card {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-paragraph.af-class-dark {
    color: #3f3f3f;
  }

  .af-view .af-class-paragraph.af-class-small.af-class-fine-print {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-cards-news-header {
    width: 100%;
    margin-bottom: 30px;
  }

  .af-view .af-class-div-block-15 {
    width: 100%;
    margin-top: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-content_intro_billboard_title {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 24px;
    line-height: 30px;
  }

  .af-view .af-class-content_intro_billboard_blur_box {
    left: 0px;
    top: 20px;
    display: inline-block;
    width: auto;
    max-width: none;
    padding: 20px;
  }

  .af-view .af-class-div-block-18 {
    width: 100%;
    height: auto;
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-collage-poi-image-bleed {
    width: 100%;
    height: 300px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-content-collage-intro {
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-collage-color-band {
    display: none;
  }

  .af-view .af-class-content_collage_right_column {
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-content_collage_left_column {
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-collage-poi-framed-image {
    width: 100%;
    height: 300px;
    margin-top: 30px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .af-view .af-class-div-block-25 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-cards_points_of_interest {
    -webkit-transform: translate(0px, 4%);
    -ms-transform: translate(0px, 4%);
    transform: translate(0px, 4%);
  }

  .af-view .af-class-spotlight_billboard_descriptive {
    width: 100%;
    padding: 30px 0px 0px;
  }

  .af-view .af-class-spotlight-bb-descriptive-background {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 3vh 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-spotlight-bb-background-offset {
    bottom: 0%;
    display: none;
    height: 50%;
  }

  .af-view .af-class-spotlight-billboard-descriptive-blur {
    width: 100%;
    padding: 20px 30px 60px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-spotlight-bb-description {
    width: 100%;
  }

  .af-view .af-class-related-area-band-column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-related-area-band-column.af-class-evanston {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-related-area-band-column.af-class-deerfield {
    width: 100%;
    margin-bottom: 0px;
  }

  .af-view .af-class-text-block-4 {
    color: #fff;
    font-size: 32px;
    line-height: 42px;
  }

  .af-view .af-class-related-area-band-label {
    background-color: transparent;
  }

  .af-view .af-class-related-area-column-overlay {
    background-color: rgba(0, 0, 0, 0.65);
  }

  .af-view .af-class-div-block-26 {
    padding-left: 35px;
  }

  .af-view .af-class-div-block-27 {
    width: 100%;
  }

  .af-view .af-class-about-header-blur-wrapper {
    left: 50%;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
  }

  .af-view .af-class-about-header-title {
    font-size: 32px;
    line-height: 42px;
  }

  .af-view .af-class-list_detailed {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-value-list-item {
    max-width: 100%;
  }

  .af-view .af-class-div-block-29 {
    width: 100%;
    max-width: none;
  }

  .af-view .af-class-div-block-30 {
    left: 50%;
    -webkit-transform: translate(-50%, -30%);
    -ms-transform: translate(-50%, -30%);
    transform: translate(-50%, -30%);
  }

  .af-view .af-class-list-detailed-image-column {
    width: 100%;
    margin-bottom: -40px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-list-detailed-image-content {
    max-width: none;
  }

  .af-view .af-class-list-detailed-lists-column {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-list-detailed-list-content {
    padding-left: 0px;
  }

  .af-view .af-class-spotlight-3-points {
    padding-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-spotlight-3-first-column {
    width: 100%;
    padding-right: 30px;
    padding-bottom: 40px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-spotlight-3-point-column {
    width: 100%;
    padding-right: 30px;
    padding-bottom: 40px;
    padding-left: 30px;
  }

  .af-view .af-class-spotlight-3-point-column.af-class-reversed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-spotlight-3-point-full-bleed-img {
    height: 200px;
  }

  .af-view .af-class-spotlight-3-first-column-container {
    width: 100%;
  }

  .af-view .af-class-list-blocks-with-text-blurb {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-list-blocks-blurb-content {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-list-blocks-blurb-logos {
    padding-top: 30px;
  }

  .af-view .af-class-list-blocks-blurb-column.af-class-awards {
    padding-right: 50px;
    padding-left: 50px;
  }

  .af-view .af-class-list-blocks-b-content-container {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-cards-news-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-slider-story-header {
    width: 100%;
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-slider-story-slide.af-class-first-slide.af-class-not-active {
    opacity: 0.5;
  }

  .af-view .af-class-slider-story-slide.af-class-_2nd-slide.af-class-active {
    width: 60vw;
    min-width: 60vw;
  }

  .af-view .af-class-slider-story-slide.af-class-_3rd-slide.af-class-not-active {
    opacity: 0.5;
  }

  .af-view .af-class-slider-story-slide-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-bottom: 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-slider-story-slide-info-container {
    padding-top: 40px;
  }

  .af-view .af-class-slider-story-header-container {
    width: 100%;
  }

  .af-view .af-class-slider-story-controls-wrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transform: translate(0px, 0%);
    -ms-transform: translate(0px, 0%);
    transform: translate(0px, 0%);
  }

  .af-view .af-class-slider-story-pagination {
    position: absolute;
    left: auto;
    right: 0%;
    -webkit-transform: translate(-50%, 100%);
    -ms-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%);
  }

  .af-view .af-class-slider-story-arrow {
    -webkit-transform: translate(0px, -227px);
    -ms-transform: translate(0px, -227px);
    transform: translate(0px, -227px);
  }

  .af-view .af-class-page-header.af-class-career-overview {
    height: 500px;
    max-height: 100%;
    min-height: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-page-header.af-class-pro-dev {
    height: 50vh;
    max-height: none;
    min-height: 270px;
    padding-bottom: 0px;
  }

  .af-view .af-class-page-header-content.af-class-light {
    position: relative;
    bottom: -40px;
    width: 100%;
    padding-bottom: 30px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-page-header-content.af-class-light.af-class-vertical {
    bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-page-header-content.af-class-light.af-class-blur {
    bottom: 0px;
  }

  .af-view .af-class-page-header-content.af-class-dark {
    padding-top: 15px;
  }

  .af-view .af-class-page-header-content.af-class-dark.af-class-blur {
    width: 100%;
  }

  .af-view .af-class-page-header-content.af-class-benefits {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    padding-bottom: 30px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-career-page-heading {
    max-width: 400px;
    text-align: center;
  }

  .af-view .af-class-career-page-heading.af-class-career {
    font-size: 36px;
  }

  .af-view .af-class-career-page-heading.af-class-pro-dev {
    font-size: 24px;
    text-align: left;
  }

  .af-view .af-class-content-intro-descriptive {
    padding-top: 30px;
    padding-bottom: 10%;
  }

  .af-view .af-class-div-block-36 {
    width: 100%;
  }

  .af-view .af-class-spotlight-text-image.af-class-no-cta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-37 {
    width: 100%;
    height: 400px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-spotlight-text-image-content-column.af-class-no-cta {
    padding-right: 30px;
    padding-left: 30px;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-spotlight-text-image-border-heading {
    width: 100%;
  }

  .af-view .af-class-spotlight-text-image-content {
    width: 100%;
    max-width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-list-compact {
    margin-top: 85px;
    margin-bottom: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-list-compact-list-container {
    width: 100%;
    margin-bottom: 20px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-list-compact-list-item {
    width: 100%;
    line-height: 24px;
  }

  .af-view .af-class-div-block-41 {
    width: 100%;
  }

  .af-view .af-class-div-block-42 {
    padding-top: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-list--compact-description-wrap {
    width: 100%;
    padding-top: 20px;
    padding-right: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-list-compact-list-item-icon {
    margin-right: 10px;
  }

  .af-view .af-class-list-compact-paragraph {
    width: 100%;
  }

  .af-view .af-class-spotlight-billlboard-list-container {
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-spotlight-bb-list-description {
    text-align: left;
  }

  .af-view .af-class-spotlight-bb-list-item {
    width: 100%;
    margin-bottom: 5px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-spotlight-bb-list-benefit-list {
    width: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-cards-testimonial-card {
    width: 100%;
  }

  .af-view .af-class-spotlight-career {
    padding-top: 10%;
  }

  .af-view .af-class-spotlight-career-blur-box {
    width: 100%;
    padding: 40px;
    text-align: center;
  }

  .af-view .af-class-about-page-header.af-class-leadership {
    max-height: 400px;
  }

  .af-view .af-class-heading-13 {
    font-size: 48px;
  }

  .af-view .af-class-slider-property-showcase {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-slider_property_content_wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-slider-property-image.af-class-current {
    margin-right: 0px;
    -webkit-box-flex: 5;
    -webkit-flex-grow: 5;
    -ms-flex-positive: 5;
    flex-grow: 5;
  }

  .af-view .af-class-slider-property-image.af-class-next-slide {
    display: none;
  }

  .af-view .af-class-slider-property-image-wrapper {
    min-height: 50vh;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-slider-property-headers {
    margin-top: 0px;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-slider-property-title {
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-slider-property-controls-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    background-color: transparent;
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
    transform: translate(0px, -100%);
  }

  .af-view .af-class-div-block-45 {
    bottom: -20px;
    width: 100%;
    margin-left: 0px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-property_slide_prev_arrow {
    margin-top: 0px;
  }

  .af-view .af-class-property_slider_pagination_wrapper {
    position: absolute;
    right: 50%;
    -webkit-transform: translate(50%, 0px);
    -ms-transform: translate(50%, 0px);
    transform: translate(50%, 0px);
  }

  .af-view .af-class-div-block-46 {
    width: 100%;
    max-width: none;
  }

  .af-view .af-class-content-collage-retail {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-certification-block-cert-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }

  .af-view .af-class-content-collage-split-images {
    margin-top: 15px;
  }

  .af-view .af-class-cert-block-collage-column {
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-content-collage-retail-content-column {
    width: 100%;
    padding: 5% 30px 0%;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-cert-collage-large-image {
    width: 100%;
    padding-top: 150px;
    padding-bottom: 150px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-certification-block-number-label {
    width: 100%;
  }

  .af-view .af-class-certifications-block {
    width: 100%;
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-content-collage-retail-split-image.af-class-left {
    height: 150px;
  }

  .af-view .af-class-retail-collage-number-item {
    padding-left: 50px;
  }

  .af-view .af-class-div-block-54 {
    padding-right: 15px;
    padding-left: 15px;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  .af-view .af-class-list-blocks-header {
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-list-block-column {
    -webkit-flex-basis: 200px;
    -ms-flex-preferred-size: 200px;
    flex-basis: 200px;
  }

  .af-view .af-class-slider-employee-awards-content {
    margin-top: 0px;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-slider-employee-awards-details {
    max-width: none;
    padding-right: 0px;
  }

  .af-view .af-class-slider-employee-award-image.af-class-current {
    margin-right: 0px;
    -webkit-box-flex: 5;
    -webkit-flex-grow: 5;
    -ms-flex-positive: 5;
    flex-grow: 5;
  }

  .af-view .af-class-slider-employee-awards-image.af-class-next-slide {
    display: none;
  }

  .af-view .af-class-slider-employee-controls-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    background-color: transparent;
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
    transform: translate(0px, -100%);
  }

  .af-view .af-class-career-benefit-section-tag {
    width: 100%;
    padding-left: 0px;
  }

  .af-view .af-class-career-benefits-header-icon.af-class-extend-large {
    width: 100px;
  }

  .af-view .af-class-career-benefits-header-icon.af-class-flex {
    -webkit-transform: translate(20%, -30%);
    -ms-transform: translate(20%, -30%);
    transform: translate(20%, -30%);
  }

  .af-view .af-class-cards-career-benefit-blocks {
    height: 100%;
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-cards-career-benefit-blocks.af-class-reversed {
    margin-top: 0px;
  }

  .af-view .af-class-career-benefit-image-column {
    position: relative;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 150px;
    -webkit-flex-basis: 350px;
    -ms-flex-preferred-size: 350px;
    flex-basis: 350px;
  }

  .af-view .af-class-career-benefit-image-column.af-class-reversed {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-career-benefit-cards {
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  .af-view .af-class-career-benefit-cards.af-class-reversed {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-career-benefit-blocks-content-column {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px 30px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #fff;
  }

  .af-view .af-class-career-benefit-blocks-content-column.af-class-reversed {
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-career-benefit-blocks-header {
    display: block;
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-career-benefit-blocks-header.af-class-reversed.af-class-extend-large {
    min-width: 0px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-career-benefit-blocks-header.af-class-reversed.af-class-flex-end {
    padding-left: 30px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-benefit-card-wrapper {
    width: 100%;
    height: auto;
    max-width: 500px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  }

  .af-view .af-class-benefit-card-wrapper.af-class-offset {
    height: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-benefit-card-wrapper.af-class-move-left-med {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-benefit-card-wrapper.af-class-move-left-small {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-benefit-card-title {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 16px;
  }

  .af-view .af-class-benefit-card-content-wrapper {
    text-align: center;
  }

  .af-view .af-class-benefit-card-title-description {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
  }

  .af-view .af-class-career-benefit-cards-offset {
    position: relative;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-career-benefit-cards-offset.af-class-reversed {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-career-benefit-cards-offset.af-class-_401k-reversed {
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-career-benefit-card-column-offset {
    width: 100%;
    margin-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-career-benefit-card-column-offset.af-class-reversed {
    width: 100%;
  }

  .af-view .af-class-career-benefit-card-column-offset.af-class-reversed.af-class-extends-large {
    padding-right: 0px;
  }

  .af-view .af-class-career-benefit-card-column {
    width: 100%;
    margin-top: auto;
    padding: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-career-benefit-card-column.af-class-reversed {
    width: 100%;
    margin-bottom: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-dropdown-list-2 {
    position: absolute;
  }

  .af-view .af-class-dropdown-list-3 {
    position: absolute;
  }

  .af-view .af-class-slider-poi-header {
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-div-block-73 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-74 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    background-color: #fff;
  }

  .af-view .af-class-list-tall {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-image: none;
  }

  .af-view .af-class-list-tall-content-wrapper.af-class-blur {
    min-height: 0px;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-77 {
    width: 100%;
    max-width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .af-view .af-class-list-item {
    margin-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-content-booklet-content {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-content-booklet-content.af-class-reversed {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-list-faq-items {
    width: 90vw;
  }

  .af-view .af-class-section-heading {
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-list-staff-tab-header {
    max-width: 550px;
  }

  .af-view .af-class-leadership-tab {
    padding-top: 10px;
    padding-bottom: 15px;
    font-size: 12px;
    font-weight: 500;
  }

  .af-view .af-class-list-staff {
    padding-right: 10px;
    padding-left: 10px;
  }

  .af-view .af-class-list-staff-leader-column {
    padding-right: 10px;
    padding-left: 10px;
  }

  .af-view .af-class-list-staff-leader-name {
    font-size: 16px;
  }

  .af-view .af-class-list-staff-leader-position {
    font-size: 14px;
  }

  .af-view .af-class-div-block-82 {
    padding-right: 50px;
    padding-left: 50px;
  }

  .af-view .af-class-sustainable-sub-nav-container {
    border-radius: 0px;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  .af-view .af-class-sustainable-sub-nav {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-sustainable-why-we-care {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-nav-bottom-link-label {
    display: inline-block;
    margin-left: 0px;
    padding: 0px;
    text-align: left;
  }

  .af-view .af-class-nav-bottom-link-label.af-class-sustainability {
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .af-view .af-class-text-block-17 {
    padding-right: 10px;
    font-size: 14px;
  }

  .af-view .af-class-div-block-84 {
    left: 50%;
    width: 90%;
    padding: 30px;
    border-bottom: 10px solid #ecd925;
    border-left-style: none;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
  }

  .af-view .af-class-div-block-87 {
    padding-top: 150px;
    padding-bottom: 150px;
    -webkit-box-flex: 3;
    -webkit-flex: 3 0;
    -ms-flex: 3 0;
    flex: 3 0;
  }

  .af-view .af-class-div-block-88 {
    width: 100%;
    padding: 50px;
  }

  .af-view .af-class-div-block-90 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-91 {
    -webkit-flex-basis: 300px;
    -ms-flex-preferred-size: 300px;
    flex-basis: 300px;
  }

  .af-view .af-class-cards-content-offset {
    padding-bottom: 0px;
  }

  .af-view .af-class-cards-content-offset--card.af-class-reversed {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-image-14.af-class-reversed {
    top: 10px;
    bottom: auto;
    -webkit-transform: translate(2px, -99.75%) rotateX(0) rotateY(0deg) rotateZ(0deg);
    transform: translate(2px, -99.75%) rotateX(0) rotateY(0deg) rotateZ(0deg);
  }

  .af-view .af-class-card-content-offset-outer-wrap.af-class-reversed {
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-96 {
    max-width: 90%;
    padding-bottom: 30px;
    padding-left: 0px;
  }

  .af-view .af-class-video-w-blurb-left-bg {
    right: 10%;
    bottom: 30%;
  }

  .af-view .af-class-cards-highlight-activity {
    padding-bottom: 0px;
  }

  .af-view .af-class-card-highlights-community--card {
    max-width: 360px;
    margin-bottom: 50px;
  }

  .af-view .af-class-div-block-104 {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-image-15 {
    height: 100%;
  }

  .af-view .af-class-grid-hover-action {
    margin-top: 0px;
    padding-top: 50px;
  }

  .af-view .af-class-grid-hover-card-container {
    width: 100%;
  }

  .af-view .af-class-video-testimonial-container {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-text-block-19 {
    width: 100%;
  }

  .af-view .af-class-div-block-107 {
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-div-block-108 {
    width: 100%;
    height: 100%;
    margin-top: 40px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .af-view .af-class-content-video-testimonial-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-content-video-testy-column {
    width: 100%;
  }

  .af-view .af-class-content-video-testy-card {
    margin-bottom: 100px;
  }

  .af-view .af-class-content-video-testy-card.af-class-last-child {
    margin-bottom: 0px;
  }

  .af-view .af-class-find-career-short-callout-band {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-text-block-21 {
    padding-right: 0px;
    padding-bottom: 10px;
    font-size: 21px;
  }

  .af-view .af-class-benchmark-leed-level-number {
    font-size: 42px;
  }

  .af-view .af-class-benchmark-row {
    margin-left: 0px;
    padding-left: 40px;
  }

  .af-view .af-class-benchmark-number-column.af-class-first-child {
    padding-right: 20px;
  }

  .af-view .af-class-list-blocks-blurb-column-wrapper {
    padding-top: 30px;
  }

  .af-view .af-class-property-header {
    width: 100%;
    height: 375px;
    margin-top: 60px;
    margin-bottom: 202px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .af-view .af-class-move-in-date-wrapper {
    max-width: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .af-view .af-class-property-info-card-wrapper {
    width: 100%;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  }

  .af-view .af-class-property-phase--property-name {
    font-size: 16px;
    line-height: 24px;
  }

  .af-view .af-class-div-block-130 {
    display: block;
  }

  .af-view .af-class-property-banner-info-wrapper {
    position: relative;
    left: 0px;
    top: 54%;
    bottom: 0px;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .af-view .af-class-button--3d-tour {
    margin-top: 15px;
    margin-left: 0px;
  }

  .af-view .af-class-button--3d-tour.af-class-no-margin {
    margin-right: 20px;
  }

  .af-view .af-class-property-scroll-down-text {
    display: none;
  }

  .af-view .af-class-property-intro-copy {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 65%;
    -ms-flex-preferred-size: 65%;
    flex-basis: 65%;
  }

  .af-view .af-class-div-block-133 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-property-floorplan-card-col {
    padding-right: 10px;
    padding-left: 10px;
  }

  .af-view .af-class-property-floorplan-special-card {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-icon-quote.af-class-closing {
    width: 45px;
  }

  .af-view .af-class-grid-highlight-images {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-grid-highlight-column.af-class-first-col {
    padding-right: 15px;
    padding-left: 0px;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-grid-highlight-column.af-class-_2nd-column {
    padding-right: 0px;
    padding-left: 15px;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-grid-highlight-image {
    margin-bottom: 30px;
  }

  .af-view .af-class-helper-text {
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
  }

  .af-view .af-class-grid-highlight-intro-headings {
    padding-right: 0px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-136 {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .af-view .af-class-text-block-44 {
    margin-bottom: 30px;
    padding-right: 0px;
    font-size: 24px;
    line-height: 32px;
  }

  .af-view .af-class-div-block-137 {
    margin-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-spotlight-special-feature {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-spotlight-special-feat-column.af-class-content {
    margin: 0px 30px;
    padding: 40px;
  }

  .af-view .af-class-spotlight-special-feat-column.af-class-left-col {
    -webkit-box-flex: 300px;
    -webkit-flex: 300px;
    -ms-flex: 300px;
    flex: 300px;
  }

  .af-view .af-class-spotlight-special-feat-column.af-class-right-col {
    -webkit-box-flex: 300px;
    -webkit-flex: 300px;
    -ms-flex: 300px;
    flex: 300px;
  }

  .af-view .af-class-div-block-138 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-140 {
    padding-top: 30px;
    padding-right: 0px;
    padding-bottom: 30px;
  }

  .af-view .af-class-div-block-143 {
    overflow: scroll;
  }

  .af-view .af-class-image-23 {
    margin-right: 20px;
  }

  .af-view .af-class-div-block-147 {
    padding-top: 90px;
    padding-right: 30px;
  }

  .af-view .af-class-div-block-150 {
    padding-right: 0px;
  }

  .af-view .af-class-div-block-151 {
    margin-bottom: 0px;
    padding-top: 30px;
    padding-right: 0px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-div-block-152 {
    width: 50px;
    height: 50px;
  }

  .af-view .af-class-heading-18 {
    font-size: 17px;
  }

  .af-view .af-class-spotlight-tour {
    padding: 40px;
  }

  .af-view .af-class-div-block-155.af-class-blur {
    -webkit-flex-basis: 380px;
    -ms-flex-preferred-size: 380px;
    flex-basis: 380px;
  }

  .af-view .af-class-div-block-155.af-class-blur.af-class-max-width {
    width: 70%;
  }

  .af-view .af-class-property-header-button-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-left: 0px;
    padding: 20px 20px 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 2 auto;
    -ms-flex: 0 2 auto;
    flex: 0 2 auto;
  }

  .af-view .af-class-image-26 {
    display: none;
  }

  .af-view .af-class-image-27 {
    padding-right: 20px;
  }

  .af-view .af-class-div-block-156 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-property-sub-page-header.af-class-amenities {
    max-height: 500px;
  }

  .af-view .af-class-property-sub-page-header.af-class-contact {
    max-height: 500px;
  }

  .af-view .af-class-property-sub-page-header-container {
    width: 100%;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-heading-19 {
    font-size: 42px;
    line-height: 52px;
  }

  .af-view .af-class-property-subpage-placard {
    width: 100%;
    height: 192px;
    max-width: 440px;
    min-width: 0%;
  }

  .af-view .af-class-amenities-slider-list-wrap {
    padding-right: 0px;
  }

  .af-view .af-class-div-block-160 {
    padding-right: 0px;
  }

  .af-view .af-class-list-amenities-resources {
    width: 100%;
    margin-top: 100px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-list-resources-list-container {
    padding-right: 0px;
    padding-left: 50px;
  }

  .af-view .af-class-div-block-164 {
    max-width: none;
    margin-top: 30px;
    padding-right: 0px;
  }

  .af-view .af-class-div-block-166 {
    width: 100%;
  }

  .af-view .af-class-div-block-166.af-class-offset {
    margin-top: 30px;
  }

  .af-view .af-class-div-block-175 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-spotlight-next-steps-label {
    padding-right: 0px;
    border-bottom: 1px solid #fff;
  }

  .af-view .af-class-div-block-176 {
    padding-left: 0px;
    border-left-style: none;
  }

  .af-view .af-class-div-block-177 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-floorplan-link-list {
    margin-bottom: 10px;
  }

  .af-view .af-class-div-block-182 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-div-block-184 {
    margin-left: 0px;
    border-right-style: none;
  }

  .af-view .af-class-div-block-185 {
    width: 65%;
    padding-left: 20px;
  }

  .af-view .af-class-text-block-58 {
    font-size: 14px;
    text-shadow: 1px 1px 6px transparent;
  }

  .af-view .af-class-image-34 {
    height: 75px;
  }

  .af-view .af-class-floorplan-section-header {
    -webkit-transform: translate(20%, -75%);
    -ms-transform: translate(20%, -75%);
    transform: translate(20%, -75%);
    font-size: 36px;
  }

  .af-view .af-class-div-block-190 {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(23%, #fff), color-stop(79%, hsla(0, 0%, 100%, 0)));
    background-image: linear-gradient(90deg, #fff 23%, hsla(0, 0%, 100%, 0) 79%);
  }

  .af-view .af-class-floorplans-card {
    width: 100%;
    max-width: 400px;
  }

  .af-view .af-class-floorplans-card-cta-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-text-block-61 {
    font-size: 18px;
  }

  .af-view .af-class-text-block-63 {
    font-size: 18px;
  }

  .af-view .af-class-floorplan-policies {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-200 {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-policies-tabs {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-tab-header {
    padding-left: 20px;
    font-size: 16px;
  }

  .af-view .af-class-tab-header.af-class-active {
    font-size: 16px;
  }

  .af-view .af-class-tabbed-breakdown-header {
    font-size: 16px;
  }

  .af-view .af-class-div-block-206 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-image-37 {
    width: 100%;
  }

  .af-view .af-class-floorplan-lease-term-container {
    width: auto;
  }

  .af-view .af-class-floorplan-unit-column-container {
    width: 100%;
    padding-left: 0px;
  }

  .af-view .af-class-floorplan-unit-column-container.af-class-ctas {
    width: auto;
  }

  .af-view .af-class-floorplan-units-title {
    font-size: 20px;
    line-height: 24px;
  }

  .af-view .af-class-div-block-227 {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .af-view .af-class-floorplan-unit-details-wrapper {
    width: 100%;
  }

  .af-view .af-class-unit-feat-list-item {
    font-size: 13px;
  }

  .af-view .af-class-div-block-235 {
    width: 50%;
    max-width: none;
  }

  .af-view .af-class-unit-feature-list-wrapper {
    width: 50%;
    padding-right: 30px;
  }

  .af-view .af-class-property-navigation {
    border-bottom: 1px solid #d2d2d2;
  }

  .af-view .af-class-property-nav-link.w--current {
    font-weight: 700;
  }

  .af-view .af-class-property-nav-brand-wrapper {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-property-nav-link-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .af-view .af-class-tabbed-breakdown-price {
    font-size: 16px;
  }

  .af-view .af-class-div-block-242 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-243 {
    width: 100%;
    padding-top: 50px;
  }

  .af-view .af-class-div-block-244 {
    width: 100%;
  }

  .af-view .af-class-div-block-246 {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .af-view .af-class-div-block-251 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-text-block-81 {
    font-size: 14px;
  }

  .af-view .af-class-text-block-82 {
    font-size: 28px;
  }

  .af-view .af-class-unordered-list-7 {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .af-view .af-class-sticky-card-column {
    margin-top: 0px;
    padding-top: 70px;
    -webkit-flex-basis: 500px;
    -ms-flex-preferred-size: 500px;
    flex-basis: 500px;
  }

  .af-view .af-class-div-block-267 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-grid-step-header {
    font-size: 18px;
    line-height: 24px;
  }

  .af-view .af-class-floorplan-card-bold-heading {
    margin-top: 0px;
  }

  .af-view .af-class-div-block-274 {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-275 {
    width: 100%;
    max-width: none;
    margin-bottom: 20px;
  }

  .af-view .af-class-div-block-275.af-class-full-width {
    margin-top: 0px;
  }

  .af-view .af-class-text-block-86 {
    padding-bottom: 15px;
  }

  .af-view .af-class-lease-option.af-class-button-style.af-class-margin-bottom {
    max-width: 500px;
  }

  .af-view .af-class-lease-option.af-class-button-style.af-class-margin-bottom.af-class-selected {
    max-width: none;
  }

  .af-view .af-class-div-block-283 {
    width: 100%;
  }

  .af-view .af-class-short-term-list-wrapper {
    width: 100%;
    padding-top: 450px;
  }

  .af-view .af-class-div-block-285 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-short-term-list-tagline {
    width: auto;
  }

  .af-view .af-class-image-41 {
    top: 0px;
    right: 0%;
    width: 100vw;
  }

  .af-view .af-class-blog-card--featured.af-class-blur {
    bottom: 30px;
    width: 400px;
  }

  .af-view .af-class-blog-card--featured-title {
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-blog--search-bar.af-class-blur {
    padding-top: 15px;
  }

  .af-view .af-class-blog-search-bar-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-div-block-288 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-288.af-class-margin-right {
    margin-right: 0px;
  }

  .af-view .af-class-div-block-289 {
    margin-left: 0px;
  }

  .af-view .af-class-div-block-290 {
    width: 100%;
  }

  .af-view .af-class-blog-search-bar--search-field {
    width: 100%;
    border-style: none none solid;
    border-radius: 0%;
  }

  .af-view .af-class-blog-post-cards-column {
    padding: 0px 30px 30px;
  }

  .af-view .af-class-blog-card--w-image {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-blog-card--content-container {
    width: 100%;
  }

  .af-view .af-class-blog-card-image-container {
    width: 100%;
    height: 200px;
  }

  .af-view .af-class-blog-card--title {
    margin-top: 20px;
    font-size: 18px;
    line-height: 24px;
  }

  .af-view .af-class-link-block-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-301 {
    width: 100%;
    max-width: none;
  }

  .af-view .af-class-blog-article-banner {
    height: auto;
    max-height: none;
    min-height: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-blog-article-header-image {
    width: 100%;
    height: 400px;
  }

  .af-view .af-class-blog-article-banner-content-col {
    width: 100%;
    padding-top: 10px;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .af-view .af-class-div-block-304 {
    margin-top: 30px;
    padding-bottom: 0px;
  }

  .af-view .af-class-signup-wrapper {
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-313 {
    margin-top: 0px;
    padding-top: 20px;
  }

  .af-view .af-class-div-block-314 {
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-form-block {
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-create-profile-home-container {
    max-width: 90%;
    padding: 30px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-floorplan-card-profile {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-floorplan-card-profile.af-class-quote-summary {
    width: 100%;
    max-width: 375px;
  }

  .af-view .af-class-community-placard {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-community-placard.af-class-margin-bottom {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-div-block-318 {
    -webkit-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }

  .af-view .af-class-div-block-319 {
    height: 650px;
  }

  .af-view .af-class-heading-25 {
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-utility-page-content {
    width: 90%;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-image-43 {
    margin-bottom: 30px;
  }

  .af-view .af-class-div-block-323 {
    padding-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-phase-1-banner-content {
    max-height: none;
  }

  .af-view .af-class-phase-1-header-content {
    position: relative;
    left: auto;
    top: 30%;
    bottom: auto;
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-breadcrumb-container.af-class-blur.af-class-light-bg {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translate(0px, -60px);
    -ms-transform: translate(0px, -60px);
    transform: translate(0px, -60px);
  }

  .af-view .af-class-div-block-325 {
    width: 85%;
  }

  .af-view .af-class-phase-1-nav-container {
    overflow: scroll;
    max-width: 100%;
  }

  .af-view .af-class-div-block-331 {
    padding: 15px 30px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }

  .af-view .af-class-div-block-331.af-class-current {
    padding-right: 22px;
    padding-left: 22px;
  }

  .af-view .af-class-div-block-331.af-class-not-available {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .af-view .af-class-property-location-teaser {
    padding-bottom: 30px;
  }

  .af-view .af-class-phase-1-amenity-list {
    padding-left: 20px;
  }

  .af-view .af-class-div-block-334 {
    width: 100%;
    padding-top: 110px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), color-stop(11%, #fff));
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 11%);
  }

  .af-view .af-class-div-block-335 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-336 {
    padding-top: 110px;
    background-position: 50% 50%;
    background-size: contain;
  }

  .af-view .af-class-p1-impact-image-col {
    width: 100%;
    height: 300px;
  }

  .af-view .af-class-div-block-338 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-p1-sustainable-content-col {
    width: 100%;
  }

  .af-view .af-class-div-block-342 {
    max-width: none;
  }

  .af-view .af-class-div-block-343 {
    width: 100vw;
  }

  .af-view .af-class-phase-1-sustainability {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-p1-sustainable-content-container {
    padding-top: 12%;
    padding-bottom: 0px;
  }

  .af-view .af-class-image-44 {
    left: 130px;
    top: -24%;
  }

  .af-view .af-class-p1-sustainable-image-col {
    width: 100%;
    height: 200px;
  }

  .af-view .af-class-logo-holder {
    padding: 20px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-div-block-345 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-div-block-347 {
    width: 100%;
  }

  .af-view .af-class-text-block-102 {
    font-size: 18px;
    line-height: 26px;
  }

  .af-view .af-class-link-block-5 {
    font-size: 18px;
    line-height: 26px;
  }

  .af-view .af-class-div-block-351 {
    width: 100vw;
  }

  .af-view .af-class-section-tagline-large {
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-section-tagline-large.af-class-white {
    padding-right: 0px;
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-div-block-352 {
    margin-top: 200px;
    padding: 30px;
  }

  .af-view .af-class-div-block-356 {
    width: 100%;
  }

  .af-view .af-class-p2-header-content {
    position: absolute;
  }

  .af-view .af-class-slider-gallery {
    height: 600px;
  }

  .af-view .af-class-slider-gallery-prev-arrow {
    width: 45px;
    height: 70px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .af-view .af-class-slider-gallery-next-arrow {
    width: 45px;
    height: 70px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-div-block-361 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-362 {
    width: 100%;
    height: 360px;
  }

  .af-view .af-class-div-block-363 {
    width: 100%;
    padding-right: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-div-block-366 {
    width: 80%;
    max-width: none;
    padding-bottom: 20px;
  }

  .af-view .af-class-text-block-106 {
    font-size: 28px;
  }

  .af-view .af-class-div-block-368 {
    width: 100%;
  }

  .af-view .af-class-div-block-370 {
    padding-bottom: 0px;
  }

  .af-view .af-class-fullscreen-impact-tagline {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 28px;
  }

  .af-view .af-class-impact-factiod-statement {
    font-size: 20px;
    line-height: 28px;
  }

  .af-view .af-class-div-block-372 {
    padding: 30px 39px 40px;
  }

  .af-view .af-class-newsletter {
    padding: 20px;
  }

  .af-view .af-class-div-block-377 {
    padding-right: 0px;
    padding-bottom: 40px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-378 {
    left: 16px;
    top: 16px;
    right: 16px;
    bottom: 16px;
  }

  .af-view .af-class-div-block-381 {
    width: 100%;
    margin-top: 0px;
    padding: 5px 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-top: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
  }

  .af-view .af-class-save-quote {
    padding-top: 4px;
    padding-bottom: 9px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-apply {
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 4px;
    padding-bottom: 10px;
  }

  .af-view .af-class-payment-breakdown-wrap.af-class-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-payment-detail-content {
    width: 100%;
    max-width: none;
    margin-bottom: 50px;
  }

  .af-view .af-class-payment-title {
    padding-bottom: 15px;
  }

  .af-view .af-class-div-block-383 {
    margin-bottom: 30px;
  }

  .af-view .af-class-div-block-384.af-class-full-width {
    width: 100%;
    padding-right: 0px;
  }

  .af-view .af-class-monthly-payment-details {
    margin-bottom: 30px;
  }

  .af-view .af-class-date-range-wrap {
    font-size: 14px;
  }

  .af-view .af-class-div-block-388 {
    padding-bottom: 50px;
  }

  .af-view .af-class-profile-sidebar {
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-text-block-113 {
    width: 100%;
    font-size: 18px;
    line-height: 23px;
  }

  .af-view .af-class-div-block-392 {
    width: 60%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-quote-summary-unti-col {
    margin-bottom: 40px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .af-view .af-class-quote-summary-unti-col.af-class-margin-right {
    margin-left: 0px;
  }

  .af-view .af-class-list-item-7 {
    margin-bottom: 15px;
  }

  .af-view .af-class-quote-details-list {
    margin-bottom: 0px;
  }

  .af-view .af-class-div-block-396 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-397 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-full-screen-menu-background {
    padding-top: 4%;
  }

  .af-view .af-class-full-screen-menu-link {
    line-height: 36px;
    font-weight: 500;
  }

  .af-view .af-class-full-screen-menu-link.af-class-sub-link {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-unordered-list-10 {
    width: 80%;
  }

  .af-view .af-class-full-screen-sub-menu-item {
    width: 100%;
    max-width: 100%;
  }

  .af-view .af-class-unordered-list-11 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .af-view .af-class-initiative-grid-block {
    width: 100%;
    min-height: 230px;
  }

  .af-view .af-class-initiative-grid-image-block.af-class-leed-bg {
    background-size: cover;
  }

  .af-view .af-class-initiative-grid-image-block.af-class-solar-bg {
    background-size: cover;
  }

  .af-view .af-class-initiative-grid-image-block.af-class-car-charging-bg {
    background-size: cover;
  }

  .af-view .af-class-initiative-grid-image-block.af-class-water-faucet-bg {
    background-size: cover;
  }

  .af-view .af-class-initiative-grid-image-block.af-class-humming-bird-bg {
    background-size: cover;
  }

  .af-view .af-class-initiative-grid-image-block.af-class-plants-bg {
    background-size: cover;
  }

  .af-view .af-class-initiative-descriptive-block.af-class-highlight {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  .af-view .af-class-initiative-block-small-heading {
    margin-bottom: 20px;
  }

  .af-view .af-class-div-block-402 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-initiative-factoid-block.af-class-no-image {
    width: 100%;
  }

  .af-view .af-class-activism-event-col {
    margin-top: 20px;
    padding-left: 0px;
  }

  .af-view .af-class-activisim-event-wrap {
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-activism-event-heading {
    margin-bottom: 0px;
  }

  .af-view .af-class-activism-advocacy {
    padding-top: 0px;
  }

  .af-view .af-class-div-block-403 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-activism-details-col {
    width: 100%;
  }

  .af-view .af-class-div-block-404 {
    display: block;
    width: 80%;
    margin-top: -40px;
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-activism-content-block-w-bg {
    padding-top: 75px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-405 {
    padding-right: 40px;
  }

  .af-view .af-class-div-block-406 {
    padding-right: 40px;
  }

  .af-view .af-class-activism-image-offset {
    left: 0px;
    -webkit-transform: translate(0px, 40px);
    -ms-transform: translate(0px, 40px);
    transform: translate(0px, 40px);
  }

  .af-view .af-class-sustainable_billboard {
    padding: 30px 0px 0px;
  }

  .af-view .af-class-sustainable-billboard-content-blur {
    width: 100%;
    padding: 40px 30px 30px;
  }

  .af-view .af-class-suatianble-bb-background {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 40% 0px 0%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-div-block-407 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-413 {
    width: 100%;
    height: 400px;
    background-position: 50% 0px;
  }

  .af-view .af-class-residents-details-col {
    padding-left: 0px;
  }

  .af-view .af-class-div-block-414 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .af-view .af-class-giving-back-green-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-giving-back-green {
    padding-top: 20px;
  }

  .af-view .af-class-giving-back-green-image {
    width: 100%;
  }

  .af-view .af-class-giving-back-green-content {
    width: 100%;
  }

  .af-view .af-class-div-block-415 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-div-block-416 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-417 {
    width: 100%;
    height: 250px;
  }

  .af-view .af-class-div-block-418 {
    width: 100%;
  }

  .af-view .af-class-div-block-419 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-property-contact-details-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-property-contact-address {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 28px;
  }

  .af-view .af-class-div-block-428 {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-contact--community-block {
    width: 50%;
    padding: 30px 30px 30px 40px;
  }

  .af-view .af-class-heading-30 {
    font-size: 28px;
    line-height: 34px;
  }

  .af-view .af-class-div-block-431 {
    width: 100%;
    margin-bottom: 10px;
  }

  .af-view .af-class-div-block-433 {
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-property-modal-mobile {
    width: 100%;
    height: 192px;
    max-width: 440px;
    min-width: 0%;
  }

  .af-view .af-class-div-block-441 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-link-w-icon {
    margin-right: 20px;
    margin-left: 0px;
    padding-right: 14px;
    padding-left: 14px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  }

  .af-view .af-class-nav-top-menulink {
    display: inline-block;
  }

  .af-view .af-class-bottom-menu-dropdown-toggle {
    display: inline-block;
    padding: 0px;
  }

  .af-view .af-class-nav-bottom-menu-link-wrap {
    position: relative;
    display: block;
    width: 100%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    list-style-type: none;
  }

  .af-view .af-class-top-nav-menu-list {
    margin-top: 20px;
  }

  .af-view .af-class-bottom-nav-menu-list {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-height: 60px;
    max-width: 100%;
    margin: 10px auto 0px;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-property-contact-cta {
    padding: 0px;
  }

  .af-view .af-class-div-block-447 {
    width: 100%;
    height: auto;
    min-height: 100%;
    margin-bottom: 100px;
  }

  .af-view .af-class-dropdown-toggle-5 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .af-view .af-class-property-menu-items {
    display: block;
  }

  .af-view .af-class-full-sreen-main-link {
    margin-top: 15px;
  }

  .af-view .af-class-full-sreen-main-link.af-class-last-child {
    padding-bottom: 150px;
  }

  .af-view .af-class-div-block-449 {
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-image-59 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-450 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .af-view .af-class-div-block-451 {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-social-grid-post-description.af-class-large {
    font-size: 14px;
    line-height: 20px;
  }

  .af-view .af-class-div-block-459.af-class-blur.af-class-cover {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .af-view .af-class-social-grid-factoid-title {
    font-size: 20px;
    line-height: 28px;
  }

  .af-view .af-class-social-grid-program-title {
    font-size: 24px;
    line-height: 34px;
  }

  .af-view .af-class-div-block-474 {
    padding-top: 10px;
    padding-right: 0px;
  }

  .af-view .af-class-div-block-475 {
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-image-title-subtitle-cta-content {
    padding: 40px;
  }

  .af-view .af-class-heading-xxl {
    font-size: 48px;
    line-height: 58px;
  }

  .af-view .af-class-dropdown-5 {
    display: inline-block;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .af-view .af-class-dropdown-6 {
    display: inline-block;
  }

  .af-view .af-class-nav-link-chevron {
    position: relative;
    display: inline-block;
    margin-right: 0px;
    -webkit-transform: translate(10px, 0px);
    -ms-transform: translate(10px, 0px);
    transform: translate(10px, 0px);
  }

  .af-view .af-class-nav-bottom-menu-link-wrap-sustainability {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    list-style-type: none;
  }

  .af-view .af-class-list-tall-image-mobile {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 479px) {
  .af-view .af-class-wrapper.af-class-footer {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-wrapper.af-class-content_intro_image {
    padding: 0px;
  }

  .af-view .af-class-wrapper.af-class-slider_points_of_interest {
    margin-top: 30px;
  }

  .af-view .af-class-wrapper.af-class-cards_news {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.7))),
      url('../images/cards_news_background-p-1600.jpeg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../images/cards_news_background-p-1600.jpeg');
    background-position: 0px 0px, 0px 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }

  .af-view .af-class-wrapper.af-class-list_related_areas {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-wrapper.af-class-content_intro_billboard {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-container.af-class-home-intro {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-container.af-class-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-container.af-class-flex.af-class-home--about {
    margin-top: 35%;
  }

  .af-view .af-class-container.af-class-image-background.af-class-full-width {
    padding-right: 20px;
    padding-left: 20px;
    background-position: 0px 0px;
    background-size: 180%;
    background-repeat: no-repeat;
  }

  .af-view .af-class-container.af-class-image-background.af-class-full-width.af-class-min-height {
    height: auto;
    padding: 20% 21px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3))),
      url('../images/Chicago-guide2x.jpg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../images/Chicago-guide2x.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }

  .af-view .af-class-container.af-class-footer.af-class-flex {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-home-intro-content {
    padding-right: 31px;
    padding-bottom: 10%;
    padding-left: 31px;
  }

  .af-view .af-class-home-intro-images {
    -webkit-flex-basis: 385px;
    -ms-flex-preferred-size: 385px;
    flex-basis: 385px;
  }

  .af-view .af-class-list-blocks-b-section-label {
    margin-bottom: 0px;
    padding-bottom: 5px;
  }

  .af-view .af-class-list-blocks-b-section-label.af-class-light {
    font-family: 'Outfit', sans-serif;
    font-weight: 300;
  }

  .af-view .af-class-button.af-class-boss.af-class-yellow.af-class-no-margin.af-class-career-page-header-btn-offset {
    margin-top: 10px;
  }

  .af-view .af-class-boos-button-label {
    font-size: 13px;
    font-weight: 700;
  }

  .af-view .af-class-home-intro-image.af-class-left-offset {
    max-width: 49%;
    -webkit-transform: translate(0%, 105%);
    -ms-transform: translate(0%, 105%);
    transform: translate(0%, 105%);
  }

  .af-view .af-class-home-intro-image.af-class-right-offset {
    left: auto;
    right: 0px;
    max-width: 49%;
    -webkit-transform: translate(0%, 105%);
    -ms-transform: translate(0%, 105%);
    transform: translate(0%, 105%);
  }

  .af-view .af-class-section-tagline {
    font-size: 32px;
    line-height: 38px;
  }

  .af-view .af-class-section-tagline.af-class-white {
    margin-bottom: 0px;
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-section-tagline.af-class-small {
    font-size: 20px;
    line-height: 28px;
  }

  .af-view .af-class-div-block-2 {
    padding: 5% 30px 40px;
  }

  .af-view .af-class-two-thirds-column.af-class-home--about-img-bg {
    padding-top: 100px;
    padding-bottom: 100px;
    background-position: 50% 50%;
  }

  .af-view .af-class-inner-content-container.af-class-home-careers {
    padding-right: 22px;
    padding-bottom: 10%;
    padding-left: 22px;
    -webkit-transform: translate(0px, 4%);
    -ms-transform: translate(0px, 4%);
    transform: translate(0px, 4%);
  }

  .af-view .af-class-heading {
    margin-top: 0px;
  }

  .af-view .af-class-footer-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-footer-bottom {
    margin-top: 10px;
  }

  .af-view .af-class-footer-text-link {
    font-size: 16px;
    text-align: left;
  }

  .af-view .af-class-footer-text-link.af-class-legal {
    font-size: 12px;
  }

  .af-view .af-class-social-icon {
    margin-right: 20px;
    margin-left: 7px;
  }

  .af-view .af-class-social-icon.af-class-last-child {
    margin-right: 0px;
  }

  .af-view .af-class-footer-text {
    font-size: 12px;
  }

  .af-view .af-class-footer-equal-housing-logo {
    max-height: 22px;
  }

  .af-view .af-class-div-block-3 {
    margin-top: 10px;
  }

  .af-view .af-class-map-container {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-map-container.af-class-sub-region {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    min-height: 500px;
    background-image: url('../images/Sub_Region_Map_Google_Default.jpg');
    background-position: 100% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .af-view .af-class-filter-label {
    margin-bottom: 0px;
  }

  .af-view .af-class-property-card {
    position: relative;
    z-index: 11;
  }

  .af-view .af-class-property-card.af-class-sub-region {
    padding-top: 17px;
    padding-bottom: 0px;
    background-color: #fff;
  }

  .af-view .af-class-div-block-5 {
    padding-bottom: 10px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-div-block-6 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .af-view .af-class-image {
    max-width: 70px;
  }

  .af-view .af-class-real-estate-column {
    display: none;
  }

  .af-view .af-class-lozenge--specials {
    top: 5px;
    width: auto;
    border-radius: 20px;
  }

  .af-view .af-class-content_intro_image_title {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 32px;
    line-height: 45px;
  }

  .af-view .af-class-slide_info_wrapper {
    padding: 30px 6%;
  }

  .af-view .af-class-poi_slide_next_arrow {
    left: 10px;
    top: -187px;
  }

  .af-view .af-class-poi_slide_prev_arrow {
    top: -187px;
    right: 10px;
  }

  .af-view .af-class-poi_slider_pagination_wrapper {
    top: -165px;
    height: 20px;
  }

  .af-view .af-class-slider_pagination_dot.af-class-active {
    margin-left: 0px;
  }

  .af-view .af-class-slider-poi-content-wrapper {
    padding-right: 0px;
  }

  .af-view .af-class-slider_poi_image_wrapper {
    height: 40vh;
    min-height: 375px;
  }

  .af-view .af-class-slider_poi_image {
    width: 50px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-slider_poi_image.af-class-current {
    margin-right: 15px;
    -webkit-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }

  .af-view .af-class-slider_poi_headers {
    padding: 15px 30px 0px;
  }

  .af-view .af-class-content-image-offset-header {
    padding-right: 8%;
    padding-bottom: 25%;
    padding-left: 8%;
  }

  .af-view .af-class-content-image-offset-paragraph {
    font-size: 15px;
    line-height: 28px;
  }

  .af-view .af-class-content_image_offset_cards {
    -webkit-transform: translate(0px, -3%);
    -ms-transform: translate(0px, -3%);
    transform: translate(0px, -3%);
  }

  .af-view .af-class-poi_card {
    margin-bottom: 50px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .af-view .af-class-news-card-image {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .af-view .af-class-news_card {
    width: 100%;
    margin-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-paragraph.af-class-centered.af-class-large {
    margin-bottom: 0px;
    font-size: 15px;
    line-height: 27px;
    text-align: left;
  }

  .af-view .af-class-paragraph.af-class-centered.af-class-light {
    font-weight: 300;
    text-align: left;
  }

  .af-view .af-class-paragraph.af-class-small.af-class-fine-print {
    line-height: 18px;
  }

  .af-view .af-class-paragraph.af-class-margin-top {
    margin-top: 20px;
  }

  .af-view .af-class-paragraph.af-class-author-bio {
    font-weight: 400;
  }

  .af-view .af-class-cards-news-header {
    width: 100%;
    margin-bottom: 0px;
  }

  .af-view .af-class-div-block-15 {
    margin-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-related-areas-description {
    font-size: 19px;
    line-height: 28px;
  }

  .af-view .af-class-related-areas-locations-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-related-areas-location-column {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }

  .af-view .af-class-related-areas-location-column.af-class-zipcodes {
    padding-bottom: 0px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-related-areas-location-column.af-class-hoods {
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0px;
    padding-bottom: 30px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
    border-right-style: none;
    border-left-style: none;
  }

  .af-view .af-class-related-areas-location-column.af-class-cities {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
  }

  .af-view .af-class-related-areas-location-title {
    text-align: left;
  }

  .af-view .af-class-city-text-link {
    width: 125px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-hood-text-link {
    width: 125px;
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-zipcode-text-link {
    width: 125px;
    max-width: 125px;
  }

  .af-view .af-class-content_intro_billboard_title {
    display: inline-block;
    font-size: 22px;
    line-height: 32px;
  }

  .af-view .af-class-content_intro_billboard_blur_box {
    display: inline-block;
    width: 100%;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-left: 20px;
    line-height: 20px;
  }

  .af-view .af-class-div-block-18 {
    height: 25vh;
    min-height: 300px;
    padding-bottom: 0%;
    background-position: 50% 50%;
    background-size: cover;
  }

  .af-view .af-class-collage-poi-image-bleed {
    background-position: 50% 50%;
  }

  .af-view .af-class-content-collage-intro {
    padding-top: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-collage-poi-framed-image {
    margin-top: 20px;
    margin-right: 0px;
    margin-left: 0px;
    background-position: 0px 0px;
  }

  .af-view .af-class-cards_points_of_interest {
    margin-bottom: 5%;
    -webkit-transform: translate(0px, 3%);
    -ms-transform: translate(0px, 3%);
    transform: translate(0px, 3%);
  }

  .af-view .af-class-spotlight_billboard_descriptive {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-spotlight-bb-descriptive-background {
    padding: 0px;
  }

  .af-view .af-class-spotlight-bb-background-offset {
    display: none;
  }

  .af-view .af-class-spotlight-billboard-descriptive-blur {
    padding-top: 30px;
  }

  .af-view .af-class-related-areas-band {
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  .af-view .af-class-related-area-band-column {
    height: 250px;
    max-height: 250px;
    min-height: 250px;
  }

  .af-view .af-class-related-area-band-column.af-class-evanston {
    height: auto;
    min-height: 250px;
  }

  .af-view .af-class-related-area-band-column.af-class-deerfield {
    margin-bottom: 0px;
  }

  .af-view .af-class-related-area-band-label {
    padding: 0px;
  }

  .af-view .af-class-div-block-26 {
    padding-right: 30px;
    padding-bottom: 0px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-27 {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 0px;
  }

  .af-view .af-class-about-header {
    height: auto;
    max-height: 400px;
    min-height: 375px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-about-header-blur-wrapper {
    left: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 100%;
    padding-right: 25px;
    padding-left: 25px;
    border-radius: 0px;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
  }

  .af-view .af-class-about-header-title {
    display: block;
    width: 100%;
    font-size: 21px;
    line-height: 37px;
  }

  .af-view .af-class-list_detailed {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-value-list-item {
    max-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-div-block-29 {
    width: 100%;
    padding-right: 30px;
    padding-bottom: 50px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-30 {
    left: 0%;
    width: 100%;
    height: 300px;
    -webkit-transform: translate(0px, 0%);
    -ms-transform: translate(0px, 0%);
    transform: translate(0px, 0%);
  }

  .af-view .af-class-list-detailed-image-column {
    width: 100%;
  }

  .af-view .af-class-list-detailed-image-content {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-value-list-title {
    padding-bottom: 5px;
  }

  .af-view .af-class-div-block-33 {
    margin-left: 0px;
    padding-top: 10px;
  }

  .af-view .af-class-list-detailed-lists-column {
    padding-right: 30px;
    padding-left: 30px;
    -webkit-transform: translate(0px, 50px);
    -ms-transform: translate(0px, 50px);
    transform: translate(0px, 50px);
  }

  .af-view .af-class-heading-7 {
    font-size: 28px;
    line-height: 38px;
  }

  .af-view .af-class-list-detailed-list-content {
    width: 100%;
    max-width: 100%;
    padding-left: 0px;
  }

  .af-view .af-class-spotlight-3-points {
    padding-top: 40px;
    padding-bottom: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-spotlight-3-first-column {
    width: 100%;
    padding-right: 0%;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-spotlight-3-point-column {
    width: 100%;
    padding: 10px 30px 30px;
  }

  .af-view .af-class-spotlight-3-point-column.af-class-reversed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-spotlight-3-point-full-bleed-img {
    margin-top: 0px;
  }

  .af-view .af-class-_3-spotlight-bold-description {
    font-size: 20px;
  }

  .af-view .af-class-spotlight-3-first-column-container {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-spotlight-3-points-image {
    margin-bottom: 0px;
  }

  .af-view .af-class-content_image_blurb_cta {
    padding: 0px 0px 30px;
  }

  .af-view .af-class-list-blocks-blurb-content {
    padding-left: 30px;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-list-blocks-blurb-column {
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .af-view .af-class-list-blocks-blurb-column.af-class-awards {
    padding-right: 10px;
    padding-left: 10px;
    -webkit-flex-basis: 80%;
    -ms-flex-preferred-size: 80%;
    flex-basis: 80%;
  }

  .af-view .af-class-slider-story-slide.af-class-_2nd-slide.af-class-active {
    min-width: 70vw;
  }

  .af-view .af-class-slider-story-slide-info {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-slider-story-slide-info-container {
    width: 100%;
    padding-left: 0px;
  }

  .af-view .af-class-slider-story-arrow {
    -webkit-transform: translate(0px, -200px);
    -ms-transform: translate(0px, -200px);
    transform: translate(0px, -200px);
  }

  .af-view .af-class-page-header.af-class-career-overview {
    height: 500px;
    max-height: 100%;
    min-height: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-page-header.af-class-culture {
    height: 75vh;
    max-height: 375px;
    min-height: auto;
    padding-bottom: 0px;
  }

  .af-view .af-class-page-header.af-class-benefits {
    height: 100%;
    max-height: 600px;
    padding-bottom: 0px;
  }

  .af-view .af-class-page-header.af-class-pro-dev {
    max-height: 375px;
    min-height: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-page-header-content.af-class-light {
    position: relative;
    bottom: 0px;
    width: 100%;
    padding: 10px 20px 25px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsla(0, 0%, 100%, 0.9);
  }

  .af-view .af-class-page-header-content.af-class-light.af-class-vertical {
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-page-header-content.af-class-light.af-class-blur {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-page-header-content.af-class-dark.af-class-blur {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-page-header-content.af-class-benefits {
    width: 100%;
    padding: 10px 20px 25px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsla(0, 0%, 100%, 0.9);
  }

  .af-view .af-class-page-header-content.af-class-benefits.af-class-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-career-page-heading {
    font-size: 24px;
    line-height: 49px;
  }

  .af-view .af-class-career-page-heading.af-class-career {
    font-size: 43px;
    text-align: left;
  }

  .af-view .af-class-career-page-heading.af-class-pro-dev {
    line-height: 28px;
  }

  .af-view .af-class-page-header-title-highlight {
    line-height: 36px;
  }

  .af-view .af-class-content-intro-descriptive {
    padding: 20px 30px 30px 23px;
  }

  .af-view .af-class-content-intro-descriptive.af-class-centered {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-36 {
    width: 100%;
  }

  .af-view .af-class-content-intro-descriptive-title {
    font-size: 28px;
    line-height: 38px;
    text-align: left;
  }

  .af-view .af-class-content-intro-descriptive-title.af-class-centered {
    font-size: 24px;
    line-height: 32px;
  }

  .af-view .af-class-content-intro-descriptive-highlight {
    width: auto;
  }

  .af-view .af-class-spotlight-text-image {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-spotlight-text-image.af-class-no-cta {
    margin-top: 0px;
  }

  .af-view .af-class-div-block-37 {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-spotlight-text-image-content-column {
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .af-view .af-class-spotlight-text-image-content-column.af-class-no-cta {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-spotlight-text-image-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 50px;
    padding-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-list-compact {
    margin-top: 85px;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-list-compact-list-container {
    width: 100%;
    margin-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .af-view .af-class-list-compact-list-item {
    width: 100%;
  }

  .af-view .af-class-div-block-41 {
    width: 100%;
  }

  .af-view .af-class-div-block-42 {
    padding: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-43 {
    top: 0px;
  }

  .af-view .af-class-list--compact-description-wrap {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-list-compact-paragraph {
    font-size: 14px;
    line-height: 26px;
  }

  .af-view .af-class-spotlight-billboard-list {
    padding: 20px 10px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .af-view .af-class-spotlight-billlboard-list-container {
    padding-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-spotlight-bb-list-description {
    font-size: 14px;
    line-height: 26px;
    text-align: left;
  }

  .af-view .af-class-spotlight-bb-list-item {
    margin-bottom: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left;
  }

  .af-view .af-class-cards-testimonial {
    padding-bottom: 20px;
  }

  .af-view .af-class-cards-testimonial.af-class-heading {
    margin-top: 20px;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-bottom: 25px;
    padding-left: 0px;
    font-size: 24px;
  }

  .af-view .af-class-cards-testimonial-position {
    font-size: 14px;
  }

  .af-view .af-class-cards-testimonial-employee-details {
    padding-bottom: 6px;
  }

  .af-view .af-class-spotlight-career {
    padding: 20% 30px 10%;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-image: url('../images/spotlight-careers-bg-mobile2x.jpg');
    background-position: 50% 50%;
    background-size: cover;
    background-attachment: scroll;
  }

  .af-view .af-class-spotlight-career-blur-box {
    width: 100%;
    max-width: 100%;
    padding: 30px 15px;
    border-radius: 3px;
  }

  .af-view .af-class-about-page-header {
    height: 35vh;
  }

  .af-view .af-class-about-page-header.af-class-sustainability {
    height: 50vh;
    min-height: 400px;
  }

  .af-view .af-class-heading-13 {
    font-size: 38px;
    line-height: 60px;
  }

  .af-view .af-class-slider-property-showcase {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-slider_property_content_wrapper {
    padding-right: 0px;
  }

  .af-view .af-class-slider-property-image {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-slider-property-image.af-class-current {
    margin-right: 0px;
    -webkit-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }

  .af-view .af-class-slider-property-image-wrapper {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-slider-property-headers {
    padding: 30px 25px 0px;
  }

  .af-view .af-class-slider-property-list-items {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-slider-property-list-item {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }

  .af-view .af-class-div-block-45 {
    bottom: -20px;
  }

  .af-view .af-class-property_slide_prev_arrow {
    right: 0px;
    bottom: 0px;
  }

  .af-view .af-class-property_slide_next_arrow {
    left: 0px;
    bottom: 0px;
  }

  .af-view .af-class-certification-block-title {
    font-size: 24px;
    line-height: 32px;
  }

  .af-view .af-class-content-collage-retail-content-column {
    padding-top: 10%;
  }

  .af-view .af-class-cert-collage-large-image {
    background-position: 40% 50%;
  }

  .af-view .af-class-certifications-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-retail-collage-number-item {
    margin-top: 30px;
    padding-left: 0px;
  }

  .af-view .af-class-list-blocks {
    padding-top: 0px;
  }

  .af-view .af-class-list-block-details {
    height: 140px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  }

  .af-view .af-class-list-block-column {
    padding-bottom: 30px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-list-block-label {
    padding-left: 20px;
  }

  .af-view .af-class-slider-employee-awards-content {
    padding: 30px 0px;
  }

  .af-view .af-class-slider-employee-awards-details {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-slider-employee-award-image {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-slider-employee-award-image.af-class-current {
    margin-right: 0px;
    -webkit-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }

  .af-view .af-class-slider-employee-awards-image {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-content-intro-billboard-benefits {
    min-height: 675px;
    padding-top: 41px;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-image: url('../images/adult-affection-beach-mobile2x.jpg');
    background-position: 50% 100%;
  }

  .af-view .af-class-div-block-56 {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-career-benefits-section-label {
    position: relative;
    z-index: 1;
    display: inline;
    margin-left: 0px;
    text-align: center;
  }

  .af-view .af-class-career-benefit-section-tag {
    padding-top: 15px;
    padding-left: 0px;
    font-size: 20px;
    line-height: 26px;
  }

  .af-view .af-class-career-benefits-header-icon {
    right: 50px;
    bottom: 80px;
    width: 60px;
  }

  .af-view .af-class-career-benefits-header-icon.af-class-extend-large {
    bottom: auto;
  }

  .af-view .af-class-cards-career-benefit-blocks {
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-cards-career-benefit-blocks.af-class-reversed {
    margin-bottom: 0px;
  }

  .af-view .af-class-career-benefit-image-column {
    padding-top: 100px;
    padding-bottom: 100px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-career-benefit-image-column.af-class-reversed {
    background-image: url('../images/benefits-family-culture2x.jpg');
  }

  .af-view .af-class-career-benefit-cards {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  .af-view .af-class-career-benefit-blocks-content-column {
    width: 100%;
    padding-top: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 100%;
    -ms-flex: 0 100%;
    flex: 0 100%;
  }

  .af-view .af-class-career-benefit-blocks-content-column.af-class-reversed {
    margin-bottom: 50px;
  }

  .af-view .af-class-career-benefit-blocks-header {
    height: 100%;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    background-color: #fff;
  }

  .af-view .af-class-career-benefit-blocks-header.af-class-reversed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
    padding-right: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-career-benefit-blocks-header.af-class-reversed.af-class-flex-end {
    padding-left: 30px;
  }

  .af-view .af-class-benefit-card-wrapper {
    height: auto;
    min-width: none;
    margin-bottom: 35px;
    padding: 25px 15px 40px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-benefit-card-wrapper.af-class-offset {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-benefit-card-title {
    width: 100%;
    font-size: 18px;
  }

  .af-view .af-class-benefit-card-content-wrapper {
    padding-bottom: 40px;
  }

  .af-view .af-class-benefit-card-title-description {
    width: 100%;
    line-height: 22px;
  }

  .af-view .af-class-career-benefit-cards-offset {
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-career-benefit-cards-offset.af-class-reversed {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-career-benefit-cards-offset.af-class-_401k-reversed {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-career-benefit-card-column-offset {
    width: 100%;
    margin-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .af-view .af-class-career-benefit-card-column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-career-benefit-card-column.af-class-reversed {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-career-benefit-card-column.af-class-reversed.af-class-extends-large {
    margin-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-container-2 {
    display: block;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #d2d2d2;
  }

  .af-view .af-class-dropdown-list-2.w--open {
    box-shadow: none;
  }

  .af-view .af-class-dropdown-list-3.w--open {
    box-shadow: none;
  }

  .af-view .af-class-slider-poi-header {
    font-size: 24px;
    line-height: 30px;
  }

  .af-view .af-class-div-block-71 {
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 100%;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
  }

  .af-view .af-class-div-block-74 {
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  .af-view .af-class-list-tall-content-wrapper.af-class-blur {
    padding-right: 30px;
    padding-left: 30px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-div-block-77 {
    width: 100%;
  }

  .af-view .af-class-content-booklet.af-class-reversed {
    padding-top: 15px;
    padding-bottom: 50px;
  }

  .af-view .af-class-content-booklet-content {
    padding: 30px;
  }

  .af-view .af-class-content-booklet-content.af-class-reversed {
    top: 20px;
  }

  .af-view .af-class-div-block-78 {
    padding: 50px 30px;
  }

  .af-view .af-class-text-block-12 {
    font-size: 17px;
    line-height: 24px;
  }

  .af-view .af-class-text-block-13 {
    font-size: 14px;
  }

  .af-view .af-class-list-faq-item-container {
    padding: 15px 20px;
  }

  .af-view .af-class-list-faq-item-container.af-class-selected {
    background-color: transparent;
  }

  .af-view .af-class-list-faq-item-container.af-class-first-child {
    border-top: 1px solid #f6f6f6;
  }

  .af-view .af-class-list-faq-items {
    width: 100%;
    padding-right: 0px;
    padding-left: 20px;
    border-style: none;
    border-radius: 0px;
  }

  .af-view .af-class-list-faq {
    padding-right: 0px;
    padding-bottom: 50px;
    padding-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-list-faq-question-title {
    font-size: 14px;
    line-height: 22px;
  }

  .af-view .af-class-list-faq-question-title.af-class-active {
    line-height: 21px;
  }

  .af-view .af-class-list-faq-question-container {
    padding: 10px 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-list-faq-question-container.af-class-active {
    background-color: #ecd925;
  }

  .af-view .af-class-list-faq-answer-container {
    padding-right: 20px;
    padding-left: 20px;
    background-color: rgba(243, 248, 255, 0.65);
  }

  .af-view .af-class-section-heading.af-class-faq {
    margin-bottom: 0px;
    padding-bottom: 14px;
    padding-left: 20px;
    font-size: 20px;
  }

  .af-view .af-class-list-staff-tab-header {
    display: none;
  }

  .af-view .af-class-list-staff {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-list-staff-leaders-row {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-list-staff-leader-column {
    width: 50%;
  }

  .af-view .af-class-list-staff-leader-name {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .af-view .af-class-list-staff-leader-position {
    font-size: 12px;
    line-height: 17px;
  }

  .af-view .af-class-list-staff-tab-header-dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-staff-dropdown-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 20px 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #ecd925;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
    font-weight: 700;
  }

  .af-view .af-class-text-block-16 {
    display: inline-block;
  }

  .af-view .af-class-div-block-82 {
    padding: 30px 30px 50px;
  }

  .af-view .af-class-heading-15 {
    font-size: 20px;
    line-height: 30px;
  }

  .af-view .af-class-sustainable-sub-nav-container {
    position: relative;
    z-index: 1;
    overflow: scroll;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .af-view .af-class-sustainble-sub-nav-link {
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-flex: 2;
    -webkit-flex: 2 0 auto;
    -ms-flex: 2 0 auto;
    flex: 2 0 auto;
    font-size: 14px;
  }

  .af-view .af-class-sustainable-sub-nav {
    position: relative;
    z-index: 2;
  }

  .af-view .af-class-sustainable-why-we-care {
    padding-top: 0px;
  }

  .af-view .af-class-nav-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: #fff;
    box-shadow: 0 11px 10px 0 rgba(0, 0, 0, 0.25);
  }

  .af-view .af-class-nav-bottom-link-label {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 100%;
    margin-left: 0px;
    padding: 10px 20px 10px 0px;
    -webkit-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }

  .af-view .af-class-sustainable-intro-cta {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-text-block-17 {
    padding-right: 10px;
  }

  .af-view .af-class-div-block-84 {
    width: 85%;
    margin-bottom: 50px;
    padding: 20px;
  }

  .af-view .af-class-div-block-86 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-div-block-88 {
    padding: 30px;
  }

  .af-view .af-class-image-13 {
    width: 80px;
  }

  .af-view .af-class-brand {
    margin-top: 13px;
  }

  .af-view .af-class-div-block-89 {
    padding-bottom: 0px;
  }

  .af-view .af-class-div-block-90 {
    padding: 20px 0px 0px;
  }

  .af-view .af-class-cards-content-offset {
    padding-top: 0px;
  }

  .af-view .af-class-cards-content-offset-title {
    font-size: 24px;
    line-height: 31px;
  }

  .af-view .af-class-content-card-offset-inner-wrap {
    padding-right: 29px;
    padding-left: 29px;
  }

  .af-view .af-class-image-14 {
    left: 6px;
  }

  .af-view .af-class-image-14.af-class-reversed {
    top: 2px;
  }

  .af-view .af-class-video-w-blurb-container {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-96 {
    max-width: 100%;
  }

  .af-view .af-class-video-w-blurb {
    padding-right: 30px;
    padding-bottom: 10%;
    padding-left: 30px;
  }

  .af-view .af-class-video-w-blurb-left-bg {
    right: 0%;
  }

  .af-view .af-class-video-w-blurb-right-bg {
    left: 25%;
    bottom: 0%;
  }

  .af-view .af-class-div-block-98 {
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .af-view .af-class-div-block-100 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-cards-highlight-community--cards {
    top: -60px;
  }

  .af-view .af-class-card-highlights-community--card {
    width: 100%;
    height: 100%;
    max-width: 90%;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
  }

  .af-view .af-class-div-block-104 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-image-15 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-grid-hover-action {
    margin-top: 0px;
    padding-bottom: 30px;
  }

  .af-view .af-class-grid-hover-action-container {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-grid-hover-card-container {
    padding: 15px 0px;
  }

  .af-view .af-class-grid-hover-card-container.af-class-first-child {
    padding-top: 0px;
  }

  .af-view .af-class-grid-hover-action-header {
    -webkit-transform: translate(0px, -110%);
    -ms-transform: translate(0px, -110%);
    transform: translate(0px, -110%);
    font-size: 28px;
    line-height: 33px;
  }

  .af-view .af-class-video-testimonial {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-video-testimonial-container {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-text-block-19 {
    font-size: 18px;
    line-height: 24px;
  }

  .af-view .af-class-div-block-107 {
    padding-bottom: 40px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-div-block-108 {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-content-video-testimonial {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-content-video-testimonial-container {
    width: 100%;
  }

  .af-view .af-class-content-video-testy-column {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-content-video-testy-card {
    margin-bottom: 75px;
    padding-left: 0px;
  }

  .af-view .af-class-content-video-testy-header {
    font-size: 24px;
    line-height: 32px;
  }

  .af-view .af-class-div-block-110 {
    width: 100%;
  }

  .af-view .af-class-find-career-short-callout-band {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-111 {
    width: 100%;
  }

  .af-view .af-class-benchmark-leed-numbers-wrapper {
    padding-left: 0px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }

  .af-view .af-class-text-block-22.af-class-green-highlight-half {
    font-size: 62px;
  }

  .af-view .af-class-benchmark-row {
    padding-left: 0px;
  }

  .af-view .af-class-benchmark-number-column {
    width: 50%;
    padding-left: 30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-right-style: none;
  }

  .af-view .af-class-benchmark-number-column.af-class-first-child {
    width: 50%;
    padding-left: 30px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .af-view .af-class-benchmark-number-column.af-class-last-child {
    border-left: 1px solid #dbdbdb;
  }

  .af-view .af-class-div-block-118 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-119 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-div-block-120 {
    padding: 15px 30px 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-image-16 {
    position: relative;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-sub-region-map-poi-bar-wrapper {
    bottom: 0px;
    width: 100%;
  }

  .af-view .af-class-benchmark-link {
    margin-left: 0px;
  }

  .af-view .af-class-list-blocks-blurb-column-wrapper {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-property-header {
    height: 400px;
    margin-bottom: 200px;
  }

  .af-view .af-class-move-in-date-wrapper {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .af-view .af-class-property-info-card-wrapper {
    width: 100%;
  }

  .af-view .af-class-property-info-card-details {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-property-info-card-location-info {
    border-right-style: none;
  }

  .af-view .af-class-property-info-card-price-info {
    border-left: 1px solid #f6f6f6;
  }

  .af-view .af-class-property-phase--property-name {
    margin-top: 5px;
    line-height: 20px;
  }

  .af-view .af-class-property-phase--property-name.af-class-sub-region-mobile-modal {
    line-height: 19px;
  }

  .af-view .af-class-div-block-130 {
    padding-top: 10px;
  }

  .af-view .af-class-text-block-35 {
    font-size: 14px;
  }

  .af-view .af-class-text-block-38 {
    font-size: 14px;
  }

  .af-view .af-class-text-block-39 {
    font-size: 14px;
    line-height: 17px;
  }

  .af-view .af-class-property-banner-info-wrapper {
    position: relative;
    top: 0%;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transform: translate(0px, 64%);
    -ms-transform: translate(0px, 64%);
    transform: translate(0px, 64%);
  }

  .af-view .af-class-button-view-photos-label {
    font-size: 13px;
  }

  .af-view .af-class-button--3d-tour.af-class-no-margin {
    margin-top: 0px;
  }

  .af-view .af-class-button-tour-label {
    font-size: 13px;
  }

  .af-view .af-class-button-apply {
    margin-left: 0px;
  }

  .af-view .af-class-property-scroll-down-text {
    display: none;
  }

  .af-view .af-class-property-intro-floorplans {
    margin-top: 198px;
  }

  .af-view .af-class-property-intro-copy-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-property-intro-image-column {
    margin-top: 10%;
    -webkit-flex-basis: 200px;
    -ms-flex-preferred-size: 200px;
    flex-basis: 200px;
  }

  .af-view .af-class-property-intro-copy {
    padding-bottom: 30px;
  }

  .af-view .af-class-image-20 {
    position: relative;
    display: none;
    margin-top: 30px;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  .af-view .af-class-empty-column-50vw {
    display: none;
    -webkit-flex-shrink: 2;
    -ms-flex-negative: 2;
    flex-shrink: 2;
    -webkit-flex-basis: 5%;
    -ms-flex-preferred-size: 5%;
    flex-basis: 5%;
  }

  .af-view .af-class-div-block-133 {
    padding-top: 40px;
    padding-bottom: 30px;
    padding-left: 30px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-property-sub-section-tagline.af-class-white {
    margin-top: 0px;
    font-size: 28px;
    line-height: 38px;
  }

  .af-view .af-class-property-floorplans-wrapper {
    padding-bottom: 30px;
  }

  .af-view .af-class-property-floorplan-card-col {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-property-floorplan-card {
    max-width: 310px;
  }

  .af-view .af-class-property-floorplan-special-card {
    max-width: 310px;
  }

  .af-view .af-class-text-block-40 {
    font-size: 14px;
    line-height: 20px;
  }

  .af-view .af-class-spotlight-cta {
    padding: 150px 20px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-image: url('../images/spotlight-cta-lofts-interior-kitchen-mobile2x.jpg');
    background-position: 50% 50%;
    background-size: cover;
  }

  .af-view .af-class-spotlight-cta-heading {
    font-size: 24px;
    line-height: 36px;
  }

  .af-view .af-class-spotlight-cta-button-row {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-slider-container {
    overflow: hidden;
    padding: 60px 0px 40px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.61)), to(transparent)),
      url('../images/slider-reviews-bg2x.jpg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.61), transparent), url('../images/slider-reviews-bg2x.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
  }

  .af-view .af-class-review-container {
    width: 100%;
    padding-top: 0px;
    padding-right: 35px;
    padding-left: 35px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-slider-header {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-slider-review-container {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-star-icon {
    max-width: 35px;
    padding-right: 4px;
    padding-left: 4px;
  }

  .af-view .af-class-icon-quote.af-class-opening {
    left: 17px;
    top: 10%;
    display: none;
  }

  .af-view .af-class-icon-quote.af-class-closing {
    top: 5%;
    right: 27%;
    width: 80px;
    opacity: 0.39;
  }

  .af-view .af-class-spotlight-key-fact {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-image: none;
  }

  .af-view .af-class-spotlight-key-fact-container {
    position: relative;
    top: 21px;
  }

  .af-view .af-class-spotlight-key-fact-big-text {
    font-size: 38px;
    line-height: 48px;
  }

  .af-view .af-class-spotlight-key-fact-label {
    font-size: 16px;
    line-height: 24px;
  }

  .af-view .af-class-grid-highlight-images {
    padding-top: 0px;
  }

  .af-view .af-class-grid-highlight-column.af-class-first-col {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-grid-highlight-column.af-class-_2nd-column {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-grid-highlight-image-caption.af-class-blur {
    width: 100%;
    padding-top: 7px;
    padding-bottom: 11px;
    padding-left: 10px;
    background-color: transparent;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(45%, #000), to(transparent));
    background-image: linear-gradient(90deg, #000 45%, transparent);
    font-size: 14px;
  }

  .af-view .af-class-grid-highlight-image.af-class-helper-text {
    padding-bottom: 0px;
  }

  .af-view .af-class-grid-highlight-intro-headings {
    padding-top: 0px;
    padding-bottom: 30px;
    padding-left: 0px;
  }

  .af-view .af-class-icon-arrow--right {
    width: 70px;
  }

  .af-view .af-class-div-block-136 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-text-block-44 {
    top: 0px;
    margin-bottom: 0px;
    font-size: 24px;
    line-height: 27px;
  }

  .af-view .af-class-div-block-137 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 200px;
    margin-right: 0px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-spotlight-special-feat-column.af-class-content {
    margin: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-content-retail-showcase-condensed {
    padding-bottom: 0px;
  }

  .af-view .af-class-div-block-138 {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-div-block-140 {
    width: 100%;
    padding: 0px 30px 40px;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-retail-image-caption-wrap.af-class-blur {
    width: 100%;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 15px;
    padding-bottom: 30px;
    padding-left: 15px;
    font-weight: 300;
  }

  .af-view .af-class-div-block-141 {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-142 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-143 {
    padding-top: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-144 {
    height: 400px;
  }

  .af-view .af-class-html-embed-5 {
    bottom: -6px;
  }

  .af-view .af-class-div-block-145 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-146 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-div-block-147 {
    position: relative;
    padding-top: 30px;
  }

  .af-view .af-class-sustainability-highlights-expanded {
    margin-top: 30px;
  }

  .af-view .af-class-div-block-149 {
    top: 0px;
    margin-bottom: 20px;
    padding: 30px;
  }

  .af-view .af-class-image-24 {
    top: -50px;
    right: 20px;
    max-width: 130px;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  .af-view .af-class-div-block-150 {
    position: relative;
  }

  .af-view .af-class-div-block-151 {
    position: relative;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-153 {
    left: 10%;
    top: 10%;
  }

  .af-view .af-class-spotlight-tour {
    padding: 0px;
  }

  .af-view .af-class-div-block-155.af-class-blur {
    padding: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-div-block-155.af-class-blur.af-class-max-width {
    width: 100%;
    padding: 30px;
  }

  .af-view .af-class-property-header-button-container {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    padding: 12px 8px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #eaeaea;
  }

  .af-view .af-class-image-26 {
    display: block;
  }

  .af-view .af-class-image-27 {
    display: block;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-div-block-156 {
    width: auto;
    padding-top: 0px;
  }

  .af-view .af-class-property-sub-page-header.af-class-amenities {
    height: 500px;
    max-height: none;
    min-height: 500px;
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
  }

  .af-view .af-class-property-sub-page-header.af-class-contact {
    height: 400px;
    max-height: none;
    min-height: 400px;
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
  }

  .af-view .af-class-property-sub-page-header-container {
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-heading-19 {
    position: absolute;
    left: 0px;
    top: auto;
    right: 0px;
    bottom: 0px;
    margin-bottom: 15px;
    padding-left: 20px;
    -webkit-transform: translate(0px, -185px);
    -ms-transform: translate(0px, -185px);
    transform: translate(0px, -185px);
  }

  .af-view .af-class-property-subpage-placard-brick.af-class-prop-name {
    padding-top: 1px;
    padding-right: 15px;
    padding-left: 10px;
  }

  .af-view .af-class-property-subpage-placard-brick.af-class-prop-address {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
  }

  .af-view .af-class-property-subpage-placard-brick.af-class-prop-cta {
    display: none;
  }

  .af-view .af-class-property-subpage-placard-brick.af-class-prop-details {
    padding-top: 17px;
    padding-right: 6px;
    padding-left: 12px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-property-subpage-placard {
    position: absolute;
    bottom: 0px;
    height: 163px;
    max-width: 100vw;
  }

  .af-view .af-class-icon--star--active {
    width: 16px;
    height: 16px;
    margin-right: 3px;
  }

  .af-view .af-class-icon--star--active.af-class-sub-region {
    width: 16px;
  }

  .af-view .af-class-icon--star--inactive {
    width: 16px;
    height: 16px;
  }

  .af-view .af-class-icon--star--inactive.af-class-sub-region {
    width: 16px;
  }

  .af-view .af-class-text-block-47 {
    margin-bottom: 10px;
    line-height: 16px;
  }

  .af-view .af-class-text-block-48 {
    line-height: 16px;
  }

  .af-view .af-class-text-block-49 {
    font-size: 22px;
  }

  .af-view .af-class-button-2 {
    font-family: 'Outfit', sans-serif;
  }

  .af-view .af-class-button-2.af-class-sub-region-mobile-modal {
    width: auto;
    padding-top: 4px;
  }

  .af-view .af-class-text-block-50.af-class-sub-region {
    font-size: 12px;
  }

  .af-view .af-class-div-block-157 {
    margin-top: 10px;
    margin-bottom: auto;
    padding-top: 2px;
    padding-bottom: 3px;
  }

  .af-view .af-class-div-block-158 {
    padding-bottom: 25px;
  }

  .af-view .af-class-div-block-158.af-class-sub-region {
    margin-top: -5px;
  }

  .af-view .af-class-amenities-slider-list-item {
    width: 100%;
    padding-right: 0px;
  }

  .af-view .af-class-amenities-slider-list-wrap {
    width: 100%;
    padding-top: 50px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-159 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-amenity-slider-heading {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-160 {
    width: 100%;
    padding-right: 0px;
  }

  .af-view .af-class-amenities-image-slider-container {
    height: 400px;
    padding-top: 10px;
  }

  .af-view .af-class-amenity-slider-image {
    width: 100%;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .af-view .af-class-amenity-slider-image--next {
    display: none;
    width: 4%;
  }

  .af-view .af-class-amenities-slider-toolbar {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .af-view .af-class-amenities-slider-toolbar.af-class-reversed {
    left: 50%;
    right: auto;
  }

  .af-view .af-class-list-amenities-resources {
    margin-top: 75px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-list-resources-list-container {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-163 {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .af-view .af-class-div-block-164 {
    padding-top: 30px;
    padding-right: 0px;
  }

  .af-view .af-class-div-block-166 {
    width: 100%;
  }

  .af-view .af-class-div-block-166.af-class-offset {
    margin-top: 0px;
  }

  .af-view .af-class-div-block-167 {
    width: 100%;
  }

  .af-view .af-class-div-block-168 {
    width: 20%;
    margin-right: 0px;
    margin-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
  }

  .af-view .af-class-div-block-169 {
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-list-perks-banner {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.86)), to(rgba(0, 0, 0, 0.22))),
      url('../images/amenities-amenity-perks-banner2x.jpg');
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.22)), url('../images/amenities-amenity-perks-banner2x.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
  }

  .af-view .af-class-list-perks-banner-container {
    height: 380px;
  }

  .af-view .af-class-div-block-172 {
    width: 100%;
  }

  .af-view .af-class-list-perks-title {
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 42px;
  }

  .af-view .af-class-list-resources {
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-list-resource-item-wrap {
    width: 100%;
  }

  .af-view .af-class-list-resource-item {
    max-width: 360px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-right: 0px;
  }

  .af-view .af-class-list-resource-item.af-class-margin-top {
    margin-top: 0px;
  }

  .af-view .af-class-spotlight-next-steps {
    padding: 0px;
  }

  .af-view .af-class-spotlight-next-steps-container {
    height: 30vh;
  }

  .af-view .af-class-spotlight-next-steps-label {
    font-size: 12px;
  }

  .af-view .af-class-text-block-53 {
    font-size: 24px;
    line-height: 32px;
  }

  .af-view .af-class-next-steps-arrow {
    position: relative;
    top: 5px;
    width: 30px;
  }

  .af-view .af-class-div-block-176 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-div-block-177 {
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-floorplan-link-list {
    overflow: scroll;
    width: 100%;
    min-width: 100%;
    padding: 10px 15px 5px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-div-block-179 {
    padding: 5px 10px 0px;
  }

  .af-view .af-class-div-block-180 {
    margin-left: 0px;
    padding-bottom: 2px;
  }

  .af-view .af-class-floorplan-nav-link {
    overflow: visible;
    width: 100%;
    margin-left: 0px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 15px;
  }

  .af-view .af-class-floorplan-nav-link.af-class-active {
    margin-right: 0px;
    margin-left: 10px;
    font-size: 18px;
  }

  .af-view .af-class-div-block-182 {
    padding-right: 0px;
    padding-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-div-block-184 {
    margin-top: -60px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-185 {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-text-block-56 {
    width: 100%;
  }

  .af-view .af-class-text-block-57 {
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .af-view .af-class-button-4 {
    margin-left: 30px;
  }

  .af-view .af-class-image-34 {
    width: 100%;
    height: auto;
  }

  .af-view .af-class-div-block-186 {
    width: 100%;
  }

  .af-view .af-class-div-block-187 {
    width: 100%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(63, 63, 63, 0)), color-stop(90%, #3f3f3f));
    background-image: linear-gradient(180deg, rgba(63, 63, 63, 0), #3f3f3f 90%);
  }

  .af-view .af-class-div-block-192 {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-196 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .af-view .af-class-floorplans-card-column-labels {
    font-weight: 500;
  }

  .af-view .af-class-text-block-60 {
    font-size: 16px;
  }

  .af-view .af-class-floorplans-card-cta-row {
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }

  .af-view .af-class-tab-labels-wrap {
    width: 100%;
  }

  .af-view .af-class-tab-header {
    padding-right: 5px;
    padding-left: 10px;
    font-size: 15px;
  }

  .af-view .af-class-tab-header.af-class-active {
    margin-left: 7px;
    padding-top: 0px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 13px;
  }

  .af-view .af-class-div-block-203 {
    bottom: -20px;
  }

  .af-view .af-class-div-block-206 {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-tab-content-details-row {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-208 {
    width: 100%;
    padding: 10px 10px 10px 15px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid #d2d2d2;
    background-color: #fff;
  }

  .af-view .af-class-floor-plan-size-link {
    width: auto;
    margin-right: 10px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 18px;
  }

  .af-view .af-class-floor-plan-size-link.af-class-last-child {
    padding-right: 20px;
  }

  .af-view .af-class-active-tab-content-wrap {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-210 {
    width: 100%;
    padding-right: 30px;
    padding-left: 20px;
  }

  .af-view .af-class-tabbed-content-wrap {
    padding-right: 0px;
    padding-bottom: 30px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-212 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-floorplann-unit-row {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-floorplann-unit-row.af-class-selected {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-floorplan-name-container.af-class-floorplan-name {
    width: 100%;
    padding-top: 10px;
  }

  .af-view .af-class-floorplan-unit-column {
    padding-right: 10px;
  }

  .af-view .af-class-floorplan-lease-term-container {
    width: auto;
  }

  .af-view .af-class-text-block-65 {
    font-size: 14px;
  }

  .af-view .af-class-floorplan-unit-column-label {
    line-height: 14px;
  }

  .af-view .af-class-div-block-217 {
    width: 100%;
    padding-top: 20px;
  }

  .af-view .af-class-floorplan-unit-column-container {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .af-view .af-class-floorplan-unit-column-container.af-class-ctas {
    width: 100%;
    padding-top: 10px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .af-view .af-class-div-block-219 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-220 {
    margin-bottom: 5px;
    padding-left: 20px;
  }

  .af-view .af-class-floorplan-units-title {
    padding-bottom: 20px;
    padding-left: 20px;
    font-size: 24px;
    line-height: 28px;
  }

  .af-view .af-class-div-block-221 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-div-block-222 {
    margin-left: 15px;
    padding-top: 5px;
    padding-left: 10px;
  }

  .af-view .af-class-div-block-223 {
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-224 {
    padding-top: 15px;
  }

  .af-view .af-class-div-block-225 {
    padding: 25px;
  }

  .af-view .af-class-div-block-227 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .af-view .af-class-div-block-228 {
    margin-bottom: 15px;
  }

  .af-view .af-class-html-embed-19 {
    margin-top: 10px;
    padding-bottom: 5px;
  }

  .af-view .af-class-floorplan-unit-details-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-235 {
    width: 100%;
    padding-top: 20px;
  }

  .af-view .af-class-unit-feature-list-wrapper {
    width: 100%;
  }

  .af-view .af-class-property-navigation {
    border-bottom-style: none;
  }

  .af-view .af-class-property-nav-container {
    position: relative;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-image-39 {
    height: 35px;
  }

  .af-view .af-class-div-block-240 {
    border-left-style: none;
  }

  .af-view .af-class-property-nav-link {
    position: relative;
  }

  .af-view .af-class-property-nav-brand-wrapper {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-right-style: none;
  }

  .af-view .af-class-property-nav-link-container {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    border-top: 1px solid #d2d2d2;
    box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  }

  .af-view .af-class-menu-icon-label {
    display: none;
  }

  .af-view .af-class-div-block-243 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-div-block-244 {
    padding-bottom: 30px;
  }

  .af-view .af-class-location-benefit-list-item {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-text-block-74 {
    margin-left: 0px;
  }

  .af-view .af-class-div-block-247 {
    padding-left: 5px;
  }

  .af-view .af-class-location-distance-label {
    padding-right: 5px;
    padding-left: 0px;
    font-size: 12px;
  }

  .af-view .af-class-text-block-76 {
    padding-right: 10px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 12px;
  }

  .af-view .af-class-text-block-76.af-class-last-child {
    padding-right: 0px;
  }

  .af-view .af-class-property-location-map-map {
    height: 95vh;
  }

  .af-view .af-class-div-block-257 {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-div-block-258 {
    margin-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-sustainable-posts {
    padding-top: 30px;
  }

  .af-view .af-class-div-block-262 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-263 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 14px;
  }

  .af-view .af-class-right-arrow--dark {
    width: 17px;
  }

  .af-view .af-class-text-block-81 {
    margin-bottom: 15px;
    line-height: 18px;
  }

  .af-view .af-class-unordered-list-6 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-list-item-5 {
    padding-top: 20px;
    padding-right: 0px;
    padding-bottom: 20px;
  }

  .af-view .af-class-text-block-82 {
    margin-bottom: 20px;
    font-size: 32px;
    text-align: center;
  }

  .af-view .af-class-location-benefit-icon {
    margin-bottom: 10px;
  }

  .af-view .af-class-arrow-chevron-white-circled {
    width: 40px;
    height: 40px;
  }

  .af-view .af-class-arrow-chevron-white-circled.af-class-left {
    width: 40px;
    height: 40px;
  }

  .af-view .af-class-bold-text {
    line-height: 20px;
  }

  .af-view .af-class-bold-text.af-class-sub-region {
    line-height: 15px;
  }

  .af-view .af-class-html-embed-22 {
    padding-top: 0px;
    -webkit-transform: translate(0px, -4px);
    -ms-transform: translate(0px, -4px);
    transform: translate(0px, -4px);
  }

  .af-view .af-class-floorplans-card-col-sqft-label {
    font-weight: 500;
  }

  .af-view .af-class-sticky-card-column {
    height: auto;
    margin-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .af-view .af-class-grid-step-wrapper.af-class-date-picker {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-grid-step-header {
    padding-top: 20px;
    font-size: 20px;
  }

  .af-view .af-class-floorplan-card-col {
    width: 50%;
    padding-top: 5px;
    padding-bottom: 8px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .af-view .af-class-floorplan-card-col.af-class-last-col.af-class-border-top.af-class-profile.af-class-no-padding {
    padding-top: 5px;
    padding-bottom: 10px;
    -webkit-box-flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    border-bottom-style: none;
  }

  .af-view .af-class-floorplan-card-col.af-class-border-top {
    border-top: 1px solid #d2d2d2;
  }

  .af-view .af-class-floorplan-card-col.af-class-border-top.af-class-profile.af-class-no-padding {
    padding-bottom: 4px;
    border-bottom: 1px solid #d2d2d2;
    border-top-style: none;
    border-right-style: none;
  }

  .af-view .af-class-floorplan-card-col.af-class-border-top.af-class-profile.af-class-no-padding.af-class-no-border.af-class-right-align {
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
  }

  .af-view .af-class-floorplan-card-col.af-class-no-border-right {
    border-right-style: none;
  }

  .af-view .af-class-floorplan-card-col.af-class-profile.af-class-no-padding {
    padding-bottom: 4px;
    border-bottom: 1px solid #d2d2d2;
  }

  .af-view .af-class-floorplan-card-col.af-class-profile.af-class-no-padding.af-class-left-align {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
  }

  .af-view .af-class-card-details-row {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-card-details-row.af-class-padded {
    padding: 0px;
  }

  .af-view .af-class-card-details-row.af-class-padded.af-class-sides-padded {
    margin-bottom: 0px;
    padding: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-card-details-row.af-class-padded--large {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-floorplan-sticky-card {
    max-width: none;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .af-view .af-class-floorplan-card-image-col.af-class-profile {
    padding: 30px 20px;
  }

  .af-view .af-class-floorplan-card-bold-heading {
    font-size: 14px;
  }

  .af-view .af-class-floorplan-sticky-card-back-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-div-block-274 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-div-block-275 {
    margin-bottom: 20px;
    padding-top: 20px;
  }

  .af-view .af-class-div-block-275.af-class-full-width {
    margin-right: 0px;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-div-block-275.af-class-first-child {
    margin-bottom: 20px;
  }

  .af-view .af-class-icon--dog {
    width: 22px;
    margin-top: 2px;
  }

  .af-view .af-class-text-block-86 {
    font-size: 16px;
  }

  .af-view .af-class-short-term-list-wrapper {
    padding-top: 350px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-short-term-list-tagline {
    width: 100%;
    font-size: 24px;
    line-height: 30px;
  }

  .af-view .af-class-short-term-slider-heading {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-short-term-image-slider-container {
    height: 400px;
    padding-top: 10px;
  }

  .af-view .af-class-blog-card--tag.af-class-yellow {
    margin-right: 10px;
    margin-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 10px;
    letter-spacing: 0px;
  }

  .af-view .af-class-blog-card--tag.af-class-blue {
    margin-right: 10px;
    margin-bottom: 10px;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 10px;
    letter-spacing: 0px;
  }

  .af-view .af-class-blog-card--featured.af-class-blur {
    bottom: 10px;
    width: 93%;
    padding: 20px;
  }

  .af-view .af-class-blog-card--publish-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-blog-card--featured-title {
    margin-top: 20px;
    font-size: 18px;
    line-height: 24px;
  }

  .af-view .af-class-text-block-89 {
    border-right-style: none;
  }

  .af-view .af-class-div-block-289 {
    width: 130px;
  }

  .af-view .af-class-blog-posts-container {
    padding-top: 20px;
    padding-bottom: 0px;
  }

  .af-view .af-class-blog-post-cards-column {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-blog-card--w-image {
    margin-bottom: 20px;
  }

  .af-view .af-class-blog-card--content-container {
    padding: 20px;
  }

  .af-view .af-class-blog-card--date {
    margin-top: 7px;
    padding-top: 5px;
    border-top: 1px solid #d2d2d2;
    font-size: 12px;
  }

  .af-view .af-class-div-block-297 {
    width: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-blog-card--small {
    max-width: 250px;
    margin-right: 20px;
    padding: 15px;
  }

  .af-view .af-class-blog-card--small.af-class-related {
    max-width: 250px;
  }

  .af-view .af-class-blog-card--small-title {
    margin-top: 20px;
  }

  .af-view .af-class-social-card {
    width: 250px;
    margin-right: 20px;
  }

  .af-view .af-class-social-card--description {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .af-view .af-class-blog-sidebar-section {
    padding-bottom: 10px;
    padding-left: 20px;
  }

  .af-view .af-class-blog-sidebar-section-heading {
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    line-height: 28px;
  }

  .af-view .af-class-blog-article-title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 28px;
  }

  .af-view .af-class-div-block-302 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-blog-article--social-icon {
    margin-left: 10px;
  }

  .af-view .af-class-blog-article-header-image {
    height: 350px;
  }

  .af-view .af-class-blog-article-banner-content-col {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-blog-content-wrapper {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-blog-content-container {
    width: 100%;
    padding-top: 30px;
  }

  .af-view .af-class-blog-article-content-header {
    font-size: 24px;
  }

  .af-view .af-class-image-42 {
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%, -30%);
    -ms-transform: translate(-50%, -30%);
    transform: translate(-50%, -30%);
  }

  .af-view .af-class-author-bio-block {
    width: 100%;
    padding: 0px 20px 25px;
  }

  .af-view .af-class-signup-wrapper {
    margin-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-submit-button {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-314 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-form-block {
    max-width: none;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-create-profile-home-container {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-floorplan-card-profile {
    max-width: none;
  }

  .af-view .af-class-floorplan-card-profile.af-class-quote-summary {
    margin-bottom: 30px;
  }

  .af-view .af-class-lozenge--expired {
    margin-bottom: 20px;
  }

  .af-view .af-class-community-placard {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-community-placard.af-class-margin-bottom {
    width: 100%;
    padding-right: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-text-block-93 {
    font-size: 12px;
    line-height: 16px;
  }

  .af-view .af-class-link-3 {
    font-size: 12px;
    line-height: 15px;
  }

  .af-view .af-class-link-4 {
    font-size: 12px;
  }

  .af-view .af-class-floorplan-card-profile-col-label {
    font-weight: 700;
  }

  .af-view .af-class-div-block-319 {
    height: 600px;
  }

  .af-view .af-class-div-block-321.af-class-blur {
    padding: 30px 20px 50px;
  }

  .af-view .af-class-heading-25 {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .af-view .af-class-dropdown-toggle {
    padding-left: 15px;
  }

  .af-view .af-class-icon {
    right: -4px;
  }

  .af-view .af-class-div-block-322 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }

  .af-view .af-class-utility-page-content {
    width: 100%;
  }

  .af-view .af-class-heading-26 {
    font-size: 36px;
  }

  .af-view .af-class-text-block-95 {
    font-size: 20px;
    line-height: 34px;
  }

  .af-view .af-class-image-43 {
    width: 90px;
  }

  .af-view .af-class-phase-1-banner-content {
    height: 100%;
  }

  .af-view .af-class-phase-1-banner-container {
    position: relative;
    top: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    padding-top: 250px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-phase-1-header-content {
    position: relative;
    left: 0%;
    top: auto;
    width: 100%;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-phase-1-breadcrumb-link {
    color: #fff;
  }

  .af-view .af-class-breadcrumb-container {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate(0%, -10px);
    -ms-transform: translate(0%, -10px);
    transform: translate(0%, -10px);
  }

  .af-view .af-class-breadcrumb-container.af-class-blur {
    position: relative;
    padding-left: 30px;
    background-image: -webkit-gradient(linear, left top, right top, from(#000), to(hsla(0, 0%, 100%, 0)));
    background-image: linear-gradient(90deg, #000, hsla(0, 0%, 100%, 0));
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .af-view .af-class-breadcrumb-container.af-class-blur.af-class-light-bg {
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: hsla(0, 0%, 100%, 0.75);
    background-image: none;
  }

  .af-view .af-class-div-block-325 {
    width: 95%;
  }

  .af-view .af-class-phase-1-dev-phase {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .af-view .af-class-dev-phase-label {
    font-size: 12px;
  }

  .af-view .af-class-dev-phase-date {
    font-size: 12px;
  }

  .af-view .af-class-div-block-333 {
    width: 100%;
    padding: 20px;
  }

  .af-view .af-class-property-location-teaser {
    padding: 140px 19px 20px 20px;
  }

  .af-view .af-class-phase-1-amenity-list-item {
    width: 100%;
    max-width: 100%;
  }

  .af-view .af-class-div-block-334 {
    padding-top: 43px;
  }

  .af-view .af-class-div-block-336 {
    background-repeat: no-repeatundefined;
  }

  .af-view .af-class-div-block-340 {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-343 {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-phase-1-sustainability {
    margin-top: 50px;
    padding-top: 0px;
  }

  .af-view .af-class-phase-1-sustainable-list-item {
    width: 100%;
  }

  .af-view .af-class-p1-sustainable-content-container {
    padding-top: 20%;
    padding-left: 30px;
  }

  .af-view .af-class-image-44 {
    left: 120px;
    top: -13%;
  }

  .af-view .af-class-p1-impact-content-col {
    width: 100%;
  }

  .af-view .af-class-text-block-101 {
    line-height: 34px;
  }

  .af-view .af-class-retail-showcase-cta-row {
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-retail-showcase-cta-row.af-class-loft {
    padding-top: 5px;
    padding-right: 30px;
    padding-bottom: 40px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-link-block-5 {
    margin-top: 10px;
  }

  .af-view .af-class-section-tagline-large.af-class-white {
    margin-bottom: 0px;
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-div-block-352.af-class-loft {
    padding-bottom: 0px;
  }

  .af-view .af-class-p1-retail-item {
    width: 100%;
    padding: 30px 0px 0px;
  }

  .af-view .af-class-p1-retail-item.af-class-no-top-padding {
    padding-top: 30px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-356 {
    padding-bottom: 30px;
  }

  .af-view .af-class-image-45 {
    width: 13px;
    height: 21px;
  }

  .af-view .af-class-phase-1-header {
    height: 100vh;
    min-height: 500px;
    margin-bottom: 125px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .af-view .af-class-phase-2-dev-phase {
    position: relative;
    width: 100%;
    -webkit-transform: translate(0px, 100%);
    -ms-transform: translate(0px, 100%);
    transform: translate(0px, 100%);
  }

  .af-view .af-class-p2-header-content {
    position: relative;
    left: 0%;
    top: auto;
    width: 100%;
    -webkit-transform: translate(0px, 125px);
    -ms-transform: translate(0px, 125px);
    transform: translate(0px, 125px);
  }

  .af-view .af-class-phase-2-breadcrumb-link {
    color: #3f3f3f;
    font-size: 11px;
  }

  .af-view .af-class-slider-gallery {
    margin-top: 135px;
  }

  .af-view .af-class-slider-gallery-image-preview.af-class-prev {
    width: 5%;
  }

  .af-view .af-class-slider-gallery-image-preview.af-class-next {
    width: 5%;
  }

  .af-view .af-class-div-block-358 {
    width: 80%;
  }

  .af-view .af-class-slider-gallery-image-caption.af-class-blur {
    width: 100%;
    padding-top: 7px;
    padding-bottom: 11px;
    padding-left: 10px;
    background-color: transparent;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(45%, #000), to(transparent));
    background-image: linear-gradient(90deg, #000 45%, transparent);
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
    font-size: 14px;
  }

  .af-view .af-class-div-block-361 {
    padding-bottom: 30px;
  }

  .af-view .af-class-div-block-362 {
    height: 250px;
  }

  .af-view .af-class-list-item-6 {
    padding-bottom: 30px;
  }

  .af-view .af-class-div-block-366 {
    width: 90%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-unordered-list-9 {
    margin-top: 40px;
  }

  .af-view .af-class-text-block-106 {
    line-height: 32px;
  }

  .af-view .af-class-div-block-367 {
    width: 100%;
    min-height: 150px;
    padding-top: 10px;
  }

  .af-view .af-class-fullscreen-impact-tagline {
    font-size: 19px;
  }

  .af-view .af-class-div-block-372 {
    padding-top: 10px;
  }

  .af-view .af-class-newsletter {
    padding: 0px;
  }

  .af-view .af-class-div-block-377 {
    padding-bottom: 27px;
  }

  .af-view .af-class-div-block-378 {
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
  }

  .af-view .af-class-heading-27 {
    font-size: 20px;
    line-height: 28px;
  }

  .af-view .af-class-sticky-bar-col-value {
    font-size: 16px;
    line-height: 23px;
  }

  .af-view .af-class-sticky-bar-detail-col {
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
  }

  .af-view .af-class-sticky-bar-detail-col.af-class-no-border {
    padding-top: 10px;
  }

  .af-view .af-class-div-block-381 {
    padding: 16px 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-save-quote {
    padding-top: 2px;
    padding-bottom: 7px;
    font-size: 13px;
  }

  .af-view .af-class-apply {
    max-width: 100%;
    margin-top: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 50%;
    -ms-flex: 0 50%;
    flex: 0 50%;
    text-align: center;
  }

  .af-view .af-class-div-block-383 {
    margin-bottom: 15px;
    padding-top: 0px;
    padding-bottom: 20px;
  }

  .af-view .af-class-div-block-384 {
    width: 100%;
    padding-top: 0px;
  }

  .af-view .af-class-monthly-payment-details {
    margin-bottom: 0px;
  }

  .af-view .af-class-lease-step-wrap.af-class-last-child {
    padding-top: 30px;
    padding-bottom: 0px;
  }

  .af-view .af-class-date-range-wrap {
    width: 100%;
    max-width: 210px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-div-block-387 {
    width: 100%;
    margin-top: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-div-block-388 {
    padding-bottom: 0px;
  }

  .af-view .af-class-profile-sidebar {
    padding-top: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-quote-details-wrap {
    margin-top: 0px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
  }

  .af-view .af-class-div-block-392 {
    display: none;
    width: 100%;
  }

  .af-view .af-class-quote-summary-unti-col {
    width: 100%;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .af-view .af-class-list-label {
    letter-spacing: 0px;
  }

  .af-view .af-class-list-item-7 {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-quote-details-list {
    margin-bottom: 20px;
    padding-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .af-view .af-class-quote-summary-apply-btn {
    position: absolute;
    bottom: 0px;
    -webkit-transform: translate(0px, 100%);
    -ms-transform: translate(0px, 100%);
    transform: translate(0px, 100%);
  }

  .af-view .af-class-div-block-396 {
    width: 100%;
    padding-bottom: 0px;
  }

  .af-view .af-class-div-block-397 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .af-view .af-class-full-screen-menu-background {
    top: 65px;
    z-index: 100;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 75px;
  }

  .af-view .af-class-full-screen-menu-item {
    position: relative;
    padding-left: 0px;
  }

  .af-view .af-class-unordered-list-10 {
    width: 100%;
  }

  .af-view .af-class-slider-timeline-content-col {
    width: 100%;
  }

  .af-view .af-class-slider-timeline-image-col {
    display: none;
  }

  .af-view .af-class-timeline-track-card-w-image {
    margin-left: 30px;
  }

  .af-view .af-class-timeline-navigation {
    left: 20px;
    top: auto;
    bottom: -110px;
    width: 100%;
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
    transform: translate(0px, -100%);
  }

  .af-view .af-class-initiative-grid-heading-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-initiative-grid-block {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-initiative-grid-wrap {
    padding-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-activisim-event-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-405 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-div-block-406 {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-sustainable_billboard {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-sustainable-billboard-content-blur {
    padding-top: 30px;
  }

  .af-view .af-class-suatianble-bb-background {
    padding: 0px;
  }

  .af-view .af-class-div-block-411 {
    padding-top: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .af-view .af-class-div-block-412 {
    width: 100%;
    padding-right: 0px;
    padding-bottom: 20px;
  }

  .af-view .af-class-residents-details-col {
    width: 100%;
  }

  .af-view .af-class-div-block-414 {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-industry-influence-dropdown {
    padding-left: 15px;
  }

  .af-view .af-class-property-contact-details-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-property-contact-details-content {
    width: 100%;
  }

  .af-view .af-class-div-block-421 {
    padding-top: 10px;
  }

  .af-view .af-class-property-contact-address {
    font-size: 19px;
  }

  .af-view .af-class-map-icon.af-class-perks {
    top: 20%;
  }

  .af-view .af-class-locations-map-retail-modal {
    right: auto;
    padding-top: 20px;
    -webkit-transform: translate(0px, 56px);
    -ms-transform: translate(0px, 56px);
    transform: translate(0px, 56px);
  }

  .af-view .af-class-locations-map-retail-modal-top-section {
    padding-top: 20px;
  }

  .af-view .af-class-div-block-424 {
    padding: 30px;
  }

  .af-view .af-class-div-block-425 {
    padding: 30px;
  }

  .af-view .af-class-div-block-426 {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .af-view .af-class-contact--community-block {
    width: 100%;
    padding-bottom: 15px;
    padding-left: 30px;
  }

  .af-view .af-class-heading-30 {
    margin-bottom: 15px;
  }

  .af-view .af-class-image-52 {
    position: static;
    width: 50px;
  }

  .af-view .af-class-link-block-6 {
    position: absolute;
    left: 45%;
    top: 21%;
  }

  .af-view .af-class-div-block-434 {
    position: absolute;
    display: block;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-property-modal-mobile {
    position: relative;
    height: 152px;
    max-width: 100vw;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .af-view .af-class-property-modal-mobile-placard-brick.af-class-prop-name {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
  }

  .af-view .af-class-property-modal-mobile-placard-brick.af-class-prop-address {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .af-view .af-class-property-modal-mobile-placard-brick.af-class-prop-cta {
    display: block;
    margin-top: 3px;
    padding-top: 5px;
    padding-bottom: 6px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-property-modal-mobile-placard-brick.af-class-prop-details {
    padding-right: 10px;
    padding-bottom: 5px;
  }

  .af-view .af-class-property-modal-mobile-slide-wrap {
    height: 250px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-image-2-copy {
    display: block;
  }

  .af-view .af-class-image-3-copy {
    display: block;
  }

  .af-view .af-class-image-3-copy-2.af-class-slide-right {
    display: block;
  }

  .af-view .af-class-div-block-435 {
    position: absolute;
    left: 50%;
    top: 5px;
    width: 40px;
    height: 5px;
    border-radius: 10px;
    background-color: #ddd;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
  }

  .af-view .af-class-image-54 {
    width: 20px;
  }

  .af-view .af-class-image-55 {
    width: 15px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .af-view .af-class-text-block-124 {
    padding-left: 0px;
  }

  .af-view .af-class-neighborhood-card {
    position: relative;
    z-index: 11;
  }

  .af-view .af-class-link-w-icon {
    margin-right: 0px;
    margin-left: 0px;
    padding: 10px;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.15);
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  .af-view .af-class-link-icon-svg {
    display: none;
  }

  .af-view .af-class-nav-top-menulink {
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  }

  .af-view .af-class-nav-top-menu-link-wrap {
    display: block;
    width: 100%;
    text-align: left;
  }

  .af-view .af-class-bottom-menu-dropdown-toggle {
    padding-left: 0px;
  }

  .af-view .af-class-icon-2 {
    right: 10px;
  }

  .af-view .af-class-dropdown-toggle-3 {
    padding: 5px 40px 5px 0px;
  }

  .af-view .af-class-nav-bottom-menu-link-wrap {
    display: block;
    width: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-top-nav-menu-list {
    margin-top: 0px;
    padding: 15px 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-bottom: 1px solid #d2d2d2;
  }

  .af-view .af-class-bottom-nav-menu-list {
    max-height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-div-block-449 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-div-block-450 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .af-view .af-class-div-block-456 {
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
  }

  .af-view .af-class-div-block-457 {
    padding-bottom: 0px;
  }

  .af-view .af-class-div-block-458 {
    width: 100%;
  }

  .af-view .af-class-social-grid-program-title {
    font-size: 20px;
  }

  .af-view .af-class-div-block-462 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .af-view .af-class-div-block-463 {
    width: 100%;
  }

  .af-view .af-class-div-block-465 {
    width: 100%;
  }

  .af-view .af-class-div-block-466 {
    margin-right: 10px;
  }

  .af-view .af-class-div-block-468 {
    width: 18px;
    height: 18px;
  }

  .af-view .af-class-div-block-471 {
    width: 100%;
    padding: 15% 30px 10%;
  }

  .af-view .af-class-form-block-2 {
    width: 100%;
  }

  .af-view .af-class-div-block-474 {
    width: 100%;
    max-width: 100%;
  }

  .af-view .af-class-div-block-474.af-class-padding-right {
    width: 100%;
    max-width: none;
    padding-right: 0px;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }

  .af-view .af-class-div-block-476 {
    position: absolute;
    left: auto;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: 101;
    width: 30px;
    height: 52px;
    background-image: -webkit-gradient(linear, right top, left top, from(#fafafa), to(hsla(0, 0%, 100%, 0)));
    background-image: linear-gradient(270deg, #fafafa, hsla(0, 0%, 100%, 0));
    -webkit-transform: translate(0px, 100%);
    -ms-transform: translate(0px, 100%);
    transform: translate(0px, 100%);
  }

  .af-view .af-class-card-details-cta-row {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .af-view .af-class-card-details-cta-row.af-class-padded {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-card-details-cta-row.af-class-padded.af-class-sides-padded {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-card-details-cta-row.af-class-padded--large {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-image-title-subtitle-cta-content {
    width: 100%;
    padding: 30px 15px;
    border-radius: 3px;
  }

  .af-view .af-class-heading-xxl {
    text-shadow: 1px 1px 13px #000;
  }

  .af-view .af-class-nav-link-chevron {
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  .af-view .af-class-nav-link-chevron.af-class-translation-chveron {
    -webkit-transform: translate(50px, 0px);
    -ms-transform: translate(50px, 0px);
    transform: translate(50px, 0px);
  }

  .af-view .af-class-nav-bottom-menu-link-wrap-sustainability {
    display: block;
    width: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-list-tall-image-mobile {
    width: 100%;
    height: 100%;
  }
}

.af-view #w-node-24297faa08b6-b00fa005 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

.af-view #w-node-14a336dcbcd3-b00fa005 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

.af-view #w-node-fbc54c333def-b00fa005 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

.af-view #w-node-eb5e826539aa-b00fa005 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

.af-view #w-node-24297faa08b6-557497b7 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

.af-view #w-node-14a336dcbcd3-557497b7 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

.af-view #w-node-fbc54c333def-557497b7 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

.af-view #w-node-eb5e826539aa-557497b7 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

@font-face {
  font-family: 'Outfit';
  src: url('../fonts/Outfit-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('../fonts/Outfit-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('../fonts/Outfit-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('../fonts/Outfit-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('../fonts/Outfit-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('../fonts/Outfit-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('../fonts/Outfit-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('../fonts/Outfit-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Outfit';
  src: url('../fonts/Outfit-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@media (min-width: 280px) and (max-width: 280px) {
  .af-class-amenity-slider-heading {
    margin-top: 70px !important;
  }
}

@media (min-width: 320px) and (max-width: 320px) {
  .af-class-amenity-slider-heading {
    margin-top: 55px !important;
  }
}

@media (min-width: 360px) and (max-width: 360px) {
  .af-class-amenity-slider-heading {
    margin-top: 60px !important;
  }
}

@media (min-width: 375px) and (max-width: 375px) {
  .af-class-amenity-slider-heading {
    margin-top: 80px !important;
  }
}

@media (min-width: 384px) and (max-width: 384px) {
  .af-class-amenity-slider-heading {
    margin-top: 50px !important;
  }
}

@media (min-width: 411px) and (max-width: 411px) {
  .af-class-amenity-slider-heading {
    margin-top: 77px !important;
  }
}

@media (min-width: 414px) and (max-width: 414px) {
  .af-class-amenity-slider-heading {
    margin-top: 80px !important;
  }
}
